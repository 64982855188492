import React, {useState, useRef} from 'react';
import {Input, Row, Col, Form, FormGroup, Button, FormFeedback} from 'reactstrap';

const CodeInput = ({error, handler}) => {
    const [code, setCode] = useState(["", "", "", ""]);

    // References for each input to control focus
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    // Handle input change
    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === "") {  // Only allow numbers
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            handler(newCode.join(''));

            // Move to the next input if value is entered
            if (value && index < 3) {
                inputRefs[index + 1].current.focus();
            }
        }
    };

    // Handle key down for backspace
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && index > 0 && !code[index]) {
            inputRefs[index - 1].current.focus();
        }
    };

    return (
        <FormGroup>
            <div className={"d-flex gap-3 gap-sm-4 justify-content-center"}>
                {code.map((digit, index) => (
                    <div key={index}>
                        <Input
                            type="text"
                            maxLength="1"               // Ensure only 1 character
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            innerRef={inputRefs[index]}    // Reference for focusing
                            className={error ? "rounded-4 ff-secondary  border-danger" : "rounded-4 text-primary"}
                            style={{
                                textAlign: 'center',
                                // width: "4rem",
                                height: "4rem",
                                fontSize: "1.5rem"
                            }}
                        />
                    </div>
                ))}
            </div>
            {error ? (
                <FormFeedback type="invalid">{error}</FormFeedback>
            ) : null}
        </FormGroup>
    );
};

export default CodeInput;
