import {Button, Col, Collapse, Input, Label, ListGroup, ListGroupItem, Row} from "reactstrap";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {AndvancedSelect} from "../../../Components/Selects";
import {EducationEnum} from "../../../Components/constants/jobs";
import {readFilesAsBase64} from "../../../helpers/myFunctions";
import {resumeSchema} from "../../../common/validationSchemas";

const Education = ({t, toggleTab, activeTab, handleValues}) => {
    const currYear = new Date().getFullYear();

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            isFinishedUniversity: false,
            education: [{
                universityName: "",
                speciality: "",
                startYear: "",
                endYear: "",
                level: "",
                files: []
            }]
        },
        validationSchema: resumeSchema.education(Yup, t),
        onSubmit: (values) => {
            toggleTab(activeTab + 1, 75);
            handleValues({
                ...values,
                education: values.education.map(item => ({
                    ...item,
                    level: item.level?.value?.split('-')?.pop() || "",
                    startYear: item.startYear?.value || "",
                    endYear: item.endYear?.value || ""
                }))
            });
        }
    });

    const deleteFields = (index) => {
        const {education} = validation.values;
        education.splice(index, 1); // Remove the item at the specified index
        validation.setFieldValue('education', education);
    };


    const addFields = () => {
        validation.setFieldValue('education', [...validation.values.education, {
            universityName: "",
            speciality: "",
            startYear: "",
            endYear: "",
            level: "",
            files: []
        }]);
    }

    const handleFilesChanges = async (e, fieldName) => {
        const filesArr = await readFilesAsBase64(e);

        if (filesArr.length)
            await validation.setFieldValue(fieldName, filesArr);
    }

    return (
        <form onSubmit={validation.handleSubmit}>
            <Row className={"g-2 g-sm-3"}>
                <Col lg={12}>
                    <h5 className={"mb-2 mb-sm-3"}>#{t('education-info')}</h5>
                    <div>
                        <Label htmlFor={"isFinishedUniversity_1"} className={"form-label"}>
                            {t('finished-uni')}
                        </Label>
                        <div className='d-flex gap-5'>
                            <div className="form-check form-radio-primary mb-0">
                                <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="isFinishedUniversity"
                                    id="isFinishedUniversity_1"
                                    checked={validation.values.isFinishedUniversity}
                                    onChange={e => validation.setFieldValue("isFinishedUniversity", e.target.checked)}/>
                                <Label className="form-check-label" for="isFinishedUniversity_1">
                                    {t('yes')}
                                </Label>
                            </div>
                            <div className="form-check form-radio-primary">
                                <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="isFinishedUniversity"
                                    id="isFinishedUniversity_0"
                                    checked={!validation.values.isFinishedUniversity}
                                    onChange={e => validation.setFieldValue("isFinishedUniversity", !e.target.checked)}
                                />
                                <Label className="form-check-label" for="isFinishedUniversity_0">
                                    {t('no')}
                                </Label>
                            </div>
                        </div>
                    </div>
                    <Collapse isOpen={validation.values.isFinishedUniversity}>
                        <div className={"text-end mb-3"}>
                            <button
                                type='button'
                                className="btn btn-info"
                                onClick={addFields}>
                                <i className="ri-add-line align-bottom"></i> {" "}
                                {t('add')}
                            </button>
                        </div>
                        <ListGroup>
                            {validation.values.education.map((item, index) =>
                                <ListGroupItem key={index}>
                                    <Row className={"g-2 g-sm-3"}>
                                        <Col lg={6}>
                                            <Label htmlFor={`education.${index}.universityName`}
                                                   className="form-label">
                                                {t('university-name')} {""}
                                                <span className={"text-danger"}>*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id={`education.${index}.universityName`}
                                                name={`education.${index}.universityName`}
                                                placeholder={t('enter-university-name')}
                                                value={validation.values.education?.[index]?.universityName}
                                                invalid={Boolean(validation.errors.education?.[index]?.universityName && validation.touched.education?.[index]?.universityName)}
                                                onChange={e => validation.setFieldValue(`education.${index}.universityName`, e.target.value)}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Label htmlFor={`education.${index}.speciality`}
                                                   className="form-label">
                                                {t('speciality')} {""}
                                                <span className={"text-danger"}>*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id={`education.${index}.speciality`}
                                                name={`education.${index}.speciality`}
                                                placeholder={t('enter-speciality')}
                                                value={validation.values.education?.[index]?.speciality}
                                                invalid={Boolean(validation.errors.education?.[index]?.speciality && validation.touched.education?.[index]?.speciality)}
                                                onChange={e => validation.setFieldValue(`education.${index}.speciality`, e.target.value)}
                                            />
                                        </Col>

                                        <Col lg={4}>
                                            <Label htmlFor={"level"}>
                                                <span>{t('edu-level')}</span>{" "}
                                                <span className={"text-danger"}>*</span>
                                            </Label>
                                            <AndvancedSelect
                                                id={`education.${index}.level`}
                                                name={`education.${index}.level`}
                                                value={validation.values.education[index]?.level}
                                                handleChange={val => validation.setFieldValue(`education.${index}.level`, val)}
                                                placeholder={t('select-edu-level')}
                                                options={Object.values(EducationEnum).slice(3).map(v => ({
                                                    value: v, label: t(v),
                                                }))}
                                                isInvalid={Boolean(validation.errors.education?.[index]?.level && validation.touched.education?.[index]?.level)}
                                                error={validation.errors.education?.[index]?.level}
                                            />
                                        </Col>

                                        <Col lg={2}>
                                            <label htmlFor={`education.${index}.startYear`}
                                                   className="form-label">
                                                {t('edu-start-year')}
                                                <span className={"text-danger"}>{" "}*</span>
                                            </label>
                                            <AndvancedSelect
                                                id={`education.${index}.startYear`}
                                                name={`education.${index}.startYear`}
                                                value={validation.values.education?.[index]?.startYear}
                                                options={Array(50).fill(0).map((v, i) => ({
                                                    value: currYear - i, label: currYear - i
                                                }))}
                                                placeholder={t("select-year")}
                                                handleChange={val => validation.setFieldValue(`education.${index}.startYear`, val)}
                                                isInvalid={Boolean(validation.errors.education?.[index]?.startYear && validation.touched.education?.[index]?.startYear)}
                                                error={validation.errors.education?.[index]?.startYear}
                                            />
                                        </Col>

                                        <Col lg={2}>
                                            <label htmlFor={`education.${index}.endYear`}
                                                   className="form-label">
                                                {t('edu-end-year')}
                                                <span className={"text-danger"}>{" "}*</span>
                                            </label>
                                            <AndvancedSelect
                                                id={`education.${index}.endYear`}
                                                name={`education.${index}.endYear`}
                                                value={validation.values.education?.[index]?.endYear}
                                                options={Array(56).fill(0).map((v, i) => ({
                                                    value: currYear + 10 - i, label: currYear + 10 - i
                                                }))}
                                                placeholder={t("select-year")}
                                                handleChange={val => validation.setFieldValue(`education.${index}.endYear`, val)}
                                                isInvalid={Boolean(validation.errors.education?.[index]?.endYear && validation.touched.education?.[index]?.endYear)}
                                                error={validation.errors.education?.[index]?.endYear}
                                            />
                                        </Col>

                                        <Col lg={4}>
                                            <Label htmlFor={`education.${index}.files`} className="form-label">
                                                {t('documents')}
                                            </Label>
                                            <div className={"d-flex align-items-center gap-2 gap-sm-3"}>
                                                <div className={"flex-grow-1"}>
                                                    <Input
                                                        id={`education.${index}.files`}
                                                        className="form-control"
                                                        type="file"
                                                        accept="image/*, doc,.docx,application/msword,.pdf"
                                                        multiple
                                                        onChange={e => {
                                                            handleFilesChanges(e, `education.${index}.files`);
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <Button
                                                        className={"btn btn-icon btn-sm btn-danger"}
                                                        onClick={e => {
                                                            deleteFields(index);
                                                        }}>
                                                        <i className={"la la-trash-alt fs-19"}></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </ListGroupItem>)}
                        </ListGroup>
                    </Collapse>
                </Col>
            </Row>
            <div className="d-flex align-items-start gap-3 mt-4">
                <button
                    type="button"
                    className="btn btn-link text-decoration-none btn-label previestab"
                    onClick={() => {
                        toggleTab(activeTab - 1, 25);
                    }}
                >
                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                    {t('back')}
                </button>
                <button
                    type="submit"
                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                    {t('continue')}
                </button>
            </div>
        </form>
    );
}

export default Education;