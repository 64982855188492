import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {changeIsLoaded} from "../../../store/layouts/action";
import {origin} from "../../../config";
import {getCookie} from "../../../helpers/myFunctions";
import {toast} from "react-toastify";
import {t} from "i18next";
import ResumeCard from "./ResumeCard";
import {Card, Col, Container, Row} from "reactstrap";
import {serverPaths} from "../../../constants/appPaths";
import {handleDeleteResume} from "../../../helpers/resume";

const Index = () => {
    const dispatch = useDispatch();

    const [resumes, setResumes] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        fetch(origin.concat(serverPaths.GET.JOB_USER_RESUMES), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "userId": getCookie("userId"),
            }
        })
            .then(resp => resp.json())
            .then(jsonData => {
                console.log(jsonData);
                setResumes(jsonData);
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            })
            .finally(() => {
                dispatch(changeIsLoaded(true));
            });
    }, []);

    const handleDelete = async id => {
        const status = await handleDeleteResume(id, setIsFetching);

        if (status !== 'err' && status) {
            setResumes(state => [...state].filter(item => item.id !== id));
        }
    }

    return (
        <div className={"page-content"}>
            <Container fluid>
                <Row className={"g-3"}>
                    {
                        resumes.length > 0 ?
                            resumes.map((resume, index) =>
                                <Col md={12} key={index}>
                                    <ResumeCard item={resume} key={index} handleDelete={handleDelete}/>
                                </Col>
                            ) :
                            <Card></Card>
                    }
                </Row>
            </Container>
        </div>
    );
}

export default Index;