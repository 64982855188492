import {Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {_navigations} from "../constants/common";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeSidebarVisibility} from "../store/layouts/action";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import useWindowSize from "../hooks/windowSize";
import {appPaths} from "../constants/appPaths";

export const HeaderNavigations = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const location = useLocation();
    const windowSize = useWindowSize();

    const {sidebarVisibilitytype, layoutType} = useSelector(state => ({
        sidebarVisibilitytype: state.Layout.sidebarVisibilitytype,
        layoutType: state.Layout.layoutType
    }));

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        dispatch(changeSidebarVisibility("show"));
        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');
        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }
        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }
        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };

    return (
        <React.Fragment>
            <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 px-md-0 fs-16 header-item vertical-menu-btn"
                style={{
                    visibility: windowSize.width > 767 ? "hidden" : "visible"
                }}
                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
            </button>
            {
                windowSize.width > 767 &&
                <React.Fragment>
                    <ButtonGroup className={"header-item"}>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="button"
                                            className="btn btn-ghost-secondary text-white fs-15 rounded-5 fw-medium">
                                {t(_navigations.find(item => item.url === location.pathname)?.labelKey || _navigations[0].labelKey)}
                                <i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdownmenu-secondary">
                                {
                                    _navigations.map((item, index) =>
                                        item.url === appPaths.INTERVIEWS ?
                                            <DropdownItem key={index} href={item.url}
                                                          active={location.pathname === item.url}
                                                          disabled={true}
                                            >
                                                <i className={item.icon + ' align-middle'}></i> {t(item.labelKey)}
                                                <span className={"badge badge-soft-success ms-2 opacity-75"}>Soon</span>
                                            </DropdownItem> :
                                            <DropdownItem key={index} href={item.url}
                                                          active={location.pathname === item.url}>
                                                <i className={item.icon + ' align-middle'}></i> {t(item.labelKey)}
                                            </DropdownItem>
                                    )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>
                    <div className={"header-item ms-3"}>
                        <Button className={'btn-ghost-secondary text-white fw-medium rounded-5'}
                                href={appPaths.CREATE_RESUME}>
                            {t('create-resume')}
                        </Button>
                    </div>
                </React.Fragment>
            }

        </React.Fragment>
    );
}