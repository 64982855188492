import React from "react";

const FinalSuccess = ({t}) => {
    return (
        <div className="text-center pt-4 pb-2">
            <div className="mb-4">
                <lord-icon
                    src="https://cdn.lordicon.com/lupuorrc.json"
                    trigger="loop"
                    colors="primary:#0ab39c,secondary:#405189"
                    style={{width: "120px", height: "120px"}}
                ></lord-icon>
            </div>
            <h5>{t('resume-created')}</h5>
            <p className="text-muted">
                {t('resume-created-info')}
            </p>
        </div>
    );
}

export default FinalSuccess;