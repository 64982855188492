import {Card, CardBody, CardText} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export const NoResultsFound = () => {

    const {t} = useTranslation();

    return (
        <Card className={"mb-3"}>
            <CardBody className={"text-center"}>
                <lord-icon
                    src="https://cdn.lordicon.com/pagmnkiz.json"
                    trigger="loop" colors="primary:#405189,secondary:#0ab39c"
                    style={{width: "90px", height: "90px"}}
                ></lord-icon>

                <h4 className={"fs-17 mt-2"}>{t('no-result-found')}</h4>
                <CardText >{t('try-with-another-filter')}</CardText>
            </CardBody>
        </Card>
    );
}