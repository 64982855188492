import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap";
import {origin} from "../../config";
import {getCookie} from "../../helpers/myFunctions";
import {appPaths, serverPaths} from "../../constants/appPaths";
import defaultOrganization from "../../assets/images/companies/default-company.png";
import {CurrenciesEnum} from "../../Components/constants/jobs";
import {useTranslation} from "react-i18next";

const FindJob = () => {
    const {t} = useTranslation();

    const [vacants, setVacants] = useState([]);

    useEffect(() => {
        fetch(origin + serverPaths.GET.JOBS_BEST_MATCHES, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                userId: +getCookie("userId"),
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                // console.log('resp', resp);
                setVacants(resp);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    return (
        <React.Fragment>
            <section className="section py-4 py-lg-5" id="findJob">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="col-lg-7">
                            <div className="text-center mb-3 mb-md-5">
                                <h1 className="mb-3 ff-secondary fw-semibold text-capitalize lh-base">
                                    {t("sections-header")}
                                </h1>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {
                            vacants.slice(0, 6).map((item, key) => (
                                <Col className="col-lg-6" key={key}>
                                    <Card className="shadow-lg">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div>
                                                    <div className="avatar-sm">
                                                        <div className="avatar-title bg-soft-warning rounded">
                                                            <img
                                                                src={item.isis_logo_visible && item.image_path ? origin + '/' + item.image_path : defaultOrganization}
                                                                alt=""
                                                                className="avatar-xs"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-3 flex-grow-1">
                                                    <Link to={appPaths.JOBS_DETAILS(item.id) }>
                                                        <h5 className={"mb-1"}>{item.title}</h5>
                                                    </Link>
                                                    <ul className="list-inline text-muted mb-0">
                                                        <li className="list-inline-item">
                                                            <i className="ri-building-line align-bottom me-1"></i>{" "}
                                                            {item.company_name}
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <i className="ri-map-pin-2-line align-bottom me-1"></i>{" "}
                                                            {item.address}
                                                        </li>
                                                        {item.salary_start ? <li className="list-inline-item">
                                                            <i className="ri-money-dollar-circle-line align-bottom me-1"></i>{" "}
                                                            {Intl.NumberFormat('en-US').format(item.salary_start)} {" "}
                                                            {t(item.cash_type === 0 ? CurrenciesEnum.SOUM : item.cash_type === 1 ? CurrenciesEnum.DOLLAR : CurrenciesEnum.EURO).toLowerCase()}
                                                        </li> : ""}
                                                    </ul>
                                                    <div className="hstack gap-2 flex-wrap">
                                                        {
                                                            item.tags && JSON.parse(item.tags).map((val, index) =>
                                                                <span key={index} className="badge badge-soft-primary">
                                                                      {val}
                                                                    </span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}

                        {
                            vacants.length > 6 &&
                            <div className="col-lg-12">
                                <div className="text-center mt-md-4">
                                    <Link to={appPaths.ALL_JOBS} className="btn btn-ghost-info">
                                        {t('view-more-jobs')}{" "}
                                        <i className="ri-arrow-right-line align-bottom"></i>
                                    </Link>
                                </div>
                            </div>
                        }
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default FindJob;
