import {Col, FormFeedback, Input, Label, Row, Spinner} from "reactstrap";
import {editResumeSections} from "../../../../constants/common";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {origin} from "../../../../config";
import {serverPaths} from "../../../../constants/appPaths";
import {getCookie} from "../../../../helpers/myFunctions";
import {toast} from "react-toastify";

const inisitializeValues = (resumeName, additionalInfo) => ({
    resumeName: resumeName,
    additionalInfo: additionalInfo
});

const FormAdditionals = ({resumeId, resumeName, additionalInfo, toggle, changeResume}) => {
    const {t} = useTranslation();

    const [isSaving, setIsSaving] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: inisitializeValues(resumeName, additionalInfo),
        validationSchema: Yup.object().shape({
            resumeName: Yup.string().required(t('field-required')),
            additionalInfo: Yup.string()
        }),
        onSubmit: (values) => {
            setIsSaving(true);
            fetch(origin.concat(serverPaths.PUT.USER_RESUME(resumeId, editResumeSections.ADDITIONALS)), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    userId: +getCookie("userId")
                },
                body: JSON.stringify(values)
            })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp.status) {
                        toast.success(t('updated'));
                        changeResume(resp.data);
                        toggle();

                    } else {
                        toast.error(t('server-error'));
                    }
                })
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => setIsSaving(false));
        }
    });

    const handleReset = () => {
        validation.handleReset();
        inisitializeValues(resumeName, additionalInfo);
    }

    return (
        <form onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
        }}>
            <Row className={"g-3"}>
                <Col lg={12}>
                    <Label htmlFor="additionalInfo" className="form-label">{t('additioanl-info')}</Label>
                    <textarea
                        className="form-control"
                        id="additionalInfo"
                        name="additionalInfo"
                        value={validation.values.additionalInfo}
                        onChange={validation.handleChange}
                        rows="3"
                        placeholder={t('example') + ': ' + t('resume-additional-info')}></textarea>
                </Col>
                <Col xs={12}>
                    <Label htmlFor="resumeName" className="form-label">
                        <span>{t('resume-name')}</span>
                        <span className='text-danger'>{" "}*</span>
                    </Label>
                    <Input
                        type="text"
                        className="form-control"
                        id="resumeName"
                        name="resumeName"
                        placeholder={t('enter-resume-name')}
                        value={validation.values.resumeName}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.resumeName && validation.touched.resumeName)}
                    />
                    {validation.errors.resumeName && validation.touched.resumeName ? (
                        <FormFeedback type="invalid">{validation.errors.resumeName}</FormFeedback>
                    ) : null}
                </Col>
                <Col xs={12}>
                    <button type={"submit"}
                            className={"btn btn-success d-inline-flex align-items-center gap-2 me-2"}
                            disabled={isSaving}>
                        {isSaving && <Spinner type={"grow"} size={"sm"}/>} {t('save')}
                    </button>
                    <button type={"button"}
                            className={"btn btn-ghost-primary"}
                            onClick={handleReset}
                    >
                        {t('update')}
                    </button>
                </Col>
            </Row>
        </form>
    );
}

export default FormAdditionals;