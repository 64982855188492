import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Button, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter} from "reactstrap";

import {format2ISO, getCookie} from "../../helpers/myFunctions";
import {origin, url_path} from "../../config";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {appPaths, serverPaths} from "../../constants/appPaths";

const ModalLeftResume = ({isModalOpen, toggleModal, vacant_id}) => {
    const {t} = useTranslation();

    const layoutModeType = useSelector(state => state.Layout.layoutModeType);

    const [resumes, setResumes] = useState([]);
    const [validation, setValidation] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValidation(false);
    }, [isModalOpen]);

    useEffect(() => {
        fetch(origin.concat(serverPaths.GET.JOB_USER_RESUMES), {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                userId: +getCookie("userId"),
            }
        })
            .then(resp => resp.json())
            .then(data => {
                setResumes(data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const sendResume = async () => {
        const resumeSelected = document.querySelector('input[name="resume"]:checked');
        if (!resumeSelected) {
            setValidation(true);

        } else {
            setValidation(false);
            setLoading(true);
            const response = await fetch(origin.concat(serverPaths.POST.CREATE_USER_RESUME_LEFT), {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    userId: +getCookie("userId"),
                },
                body: JSON.stringify({
                    vacantId: vacant_id,
                    resumeId: +resumeSelected.id.split('_')[1],
                })
            })
                .then(resp => resp.json())
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(setLoading(false));

            if (response['status']) {
                toast.success(t('saved-success'));
                toggleModal();

            } else {
                toast.error(t('server-error'));
            }
        }
    }

    return (
        <Modal isOpen={isModalOpen} toggle={toggleModal} className="modal-md modal-dialog-centered">
            <div className="modal-header py-3 bg-light">
                <h4 className="modal-title">
                    {t('leaving-resume')}
                </h4>
                <button
                    type="button"
                    className="btn-close"
                    onClick={toggleModal}
                    aria-label="Close"
                ></button>
            </div>
            <ModalBody style={{maxHeight: "70vh"}}>
                <h5 className="mb-3">
                   <i className={"ri-information-line align-bottom fw-medium"}></i> {t('select-one-resume')}
                </h5>
                <ListGroup>
                    {resumes.map((resume, index) =>
                        <ListGroupItem className={'px-0 border-start-0 border-end-0'} key={index}>
                            <div className="form-check form-radio-primary d-flex gap-2 align-items-center">
                                <Input className="form-check-input mb-1" type="radio" name="resume"
                                       id={"resume_" + resume['id']}/>
                                <Label className="form-check-label w-100" for={"resume_" + resume['id']}>
                                    <div className="d-flex align-items-center gap-2">
                                        <div className="flex-shrink-0">
                                            <img src={origin + '/' + resume['image_path']} alt=""
                                                 className="avatar-sm rounded-circle border"
                                                 style={{objectFit: "cover"}}/>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className={'mb-1 fs-17'}>{resume.name}</h6>
                                            <p className={"mb-0 fs-14"}>{resume['full_name']}</p>
                                        </div>
                                        <div className={"text-end"}>
                                            <span
                                                className={"ri-timer-2-line align-bottom"}></span> {moment(resume['created_time']).format('DD.MM.YYYY, HH:mm')}
                                        </div>
                                    </div>
                                </Label>
                            </div>
                        </ListGroupItem>)}
                </ListGroup>
                {
                    validation &&
                    <p className={"text-danger mb-0 mt-2"}>
                        <i className={"ri-error-warning-line align-bottom"}></i>
                        {t('select-resume-error')}
                    </p>
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    color={"secondary"}
                    onClick={toggleModal}
                >
                    {t('cancel')}
                </Button>
                <Button type="button" color="success" onClick={sendResume} disabled={loading}>
                    {t('send')}
                    <i className="ri-send-plane-2-line ms-2 align-bottom"></i>
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalLeftResume