import * as Yup from 'yup';
import {useFormik} from "formik";
import {origin} from "../../../../config";
import {useTranslation} from "react-i18next";
import {Col, FormFeedback, Input, Label, Row, Spinner} from "reactstrap";
import {AndvancedSelect} from "../../../../Components/Selects";
import {FieldDateLikeDDMMYYYY} from "../../../../Components/Fields";
import {_allCountriesNationalities, editResumeSections} from "../../../../constants/common";
import PhoneNumberField from "../../../../Components/PhoneNumberField";
import React, {useState} from "react";
import {resumeSchema} from "../../../../common/validationSchemas";
import {serverPaths} from "../../../../constants/appPaths";
import {getCookie} from "../../../../helpers/myFunctions";
import {toast} from "react-toastify";

const getInitialValues = (resume, t) => ({
    fullName: resume.full_name || "",
    gender: resume.gender ? {value: resume.gender, label: t(resume.gender)} : '',
    birthday: resume.birthday || '',
    maritalStatus: resume.martial_status ? {value: resume.martial_status, label: t(resume.martial_status)} : '',
    citizenship: resume.citizenship ? {value: resume.citizenship, label: t(resume.citizenship)} : '',
    nationality: resume.nationality ? {value: resume.nationality, label: t(resume.nationality)} : '',
    email: resume.email || '',
    phone: resume.phone || '',
    countryCode: resume.phone_code || "",
    address: resume.address || "",
});

const FormPersonalInfo = ({resume, changeResume, toggle}) => {
    const {t} = useTranslation();

    const [isSaving, setIsSaving] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: getInitialValues(resume, t),
        validationSchema: resumeSchema.personalInfo(Yup, t),
        onSubmit: (values) => {
            setIsSaving(true);

            fetch(origin.concat(serverPaths.PUT.USER_RESUME(resume.id, editResumeSections.PERSONAL)), {
                method: 'PUT',
                headers: {
                    "Content-type": "application/json",
                    userId: getCookie("userId")
                },
                body: JSON.stringify({
                    ...values,
                    gender: values.gender?.value || '',
                    citizenship: values.citizenship?.value || '',
                    nationality: values.nationality?.value || '',
                    maritalStatus: values.maritalStatus?.value || ''
                })
            })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp.status) {
                        toast.success(t('updated'));
                        changeResume({...resume, ...resp.data});
                        toggle();

                    } else {
                        toast.error(t('server-error'));
                    }
                })
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => setIsSaving(false));
        }
    });

    const updateValues = () => {
        validation.resetForm();
        validation.initialValues = getInitialValues(resume, t);
    }

    return (
        <form onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Row className={"g-2 g-xl-3"}>
                <Col xs={12}>
                    <Label htmlFor="fullName" className="form-label">
                        <span>{t('full-name')}</span>{" "}
                        <span className='text-danger'>*</span>
                    </Label>
                    <Input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name={"fullName"}
                        placeholder={t("enter-full-name")}
                        value={validation.values.fullName}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.fullName && validation.touched.fullName)}
                    />
                    {validation.errors.fullName && validation.touched.fullName ? (
                        <FormFeedback
                            type="invalid">{validation.errors.fullName}</FormFeedback>
                    ) : null}
                </Col>
                <Col xs={12}>
                    <Label htmlFor="gender" className="form-label">
                        <span>{t('gender')}</span>{" "}
                        <span className='text-danger'>*</span></Label>
                    <AndvancedSelect
                        id={"gender"}
                        name={"gender"}
                        placeholder={t('select-gender')}
                        value={validation.values.gender}
                        handleChange={value => validation.setFieldValue("gender", value)}
                        error={validation.errors.gender}
                        isInvalid={Boolean(validation.errors.gender && validation.touched.gender)}
                        options={[
                            {value: "male", label: t('male')},
                            {value: "female", label: t('female')},
                            {value: "another", label: t('another')}
                        ]}/>
                </Col>
                <Col xs={12}>
                    <Label className="form-label" htmlFor="birthday">
                        {t('birthday')}
                    </Label>
                    <FieldDateLikeDDMMYYYY
                        id={"birthday"}
                        name={"birthday"}
                        placeholder={"DD/MM/YYYY"}
                        value={validation.values.birthday}
                        handleChange={validation.handleChange}
                        isInvalid={Boolean(validation.errors.birthday && validation.touched.birthday)}
                        error={validation.errors.birthday}
                    />
                </Col>

                <Col xs={12}>
                    <Label htmlFor="maritalStatus" className="form-label">
                        <span>{t('marital-status')}</span>{" "}
                        <span className={"text-danger"}>*</span>
                    </Label>
                    <AndvancedSelect
                        id={"maritalStatus"}
                        name={"maritalStatus"}
                        placeholder={t('select-marital-status')}
                        value={validation.values.maritalStatus}
                        handleChange={value => validation.setFieldValue("maritalStatus", value)}
                        error={validation.errors.maritalStatus}
                        isInvalid={Boolean(validation.errors.maritalStatus && validation.touched.maritalStatus)}
                        options={[
                            {value: "married", label: t('married')},
                            {value: "unmarried", label: t('unmarried')},
                            {value: "widow", label: t('widow')},
                            {value: "divorced", label: t('divorced')}
                        ]}
                    />
                </Col>

                <Col xs={12}>
                    <Label htmlFor="citizenship"
                           className="form-label">
                        {t('citizenship')}{" "}
                        <span className='text-danger'>*</span>
                    </Label>
                    <AndvancedSelect
                        id={"citizenship"}
                        name={"citizenship"}
                        placeholder={t('select-citizenship')}
                        value={validation.values.citizenship}
                        error={validation.errors.citizenship}
                        handleChange={value => validation.setFieldValue("citizenship", value)}
                        isInvalid={Boolean(validation.errors.citizenship && validation.touched.citizenship)}
                        options={_allCountriesNationalities.map(item => ({
                            value: item.en_short_name,
                            label: item.en_short_name,
                        }))}/>
                </Col>

                <Col xs={12}>
                    <Label htmlFor="nationality"
                           className="form-label">
                        {t('nationality')}{" "}
                        <span className='text-danger'>*</span>
                    </Label>
                    <AndvancedSelect
                        id={"nationality"}
                        name={"nationality"}
                        placeholder={t('select-nationality')}
                        value={validation.values.nationality}
                        handleChange={value => validation.setFieldValue("nationality", value)}
                        error={validation.errors.nationality}
                        isInvalid={Boolean(validation.errors.nationality && validation.touched.nationality)}
                        options={_allCountriesNationalities.map(item => ({
                            value: item.nationality,
                            label: item.nationality,
                        }))}/>
                </Col>

                <Col xs={12}>
                    <PhoneNumberField
                        name={"phone"}
                        placeholder={t("enter-phone")}
                        error={validation.errors.phone && validation.touched.phone ? validation.errors.phone : ''}
                        value={validation.values.phone}
                        label={t('phone')}
                        handler={validation.handleChange}
                        handlerCountryCode={val => {
                            validation.setFieldValue('countryCode', val);
                        }}
                    />
                </Col>

                <Col xs={12}>
                    <Label htmlFor="email" className="form-label">
                        {t('email')}
                    </Label>
                    <Input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder={t('enter-email')}
                        value={validation.values.email}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.email && validation.touched.email)}
                    />
                    {validation.errors.email && validation.touched.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                </Col>

                <Col xs={12}>
                    <Label htmlFor="address" className="form-label">
                        {t('address')}
                    </Label>
                    <Input
                        type="text"
                        id="address"
                        name={"address"}
                        placeholder={t('enter-living-address')}
                        className="form-control"
                        value={validation.values.address}
                        onChange={validation.handleChange}
                    />
                </Col>
            </Row>
            <div className={"mt-3"}>
                <button type={"submit"}
                        className={"btn btn-success d-inline-flex align-items-center gap-2 me-2"}
                        disabled={isSaving}>
                    {isSaving && <Spinner type={"grow"} size={"sm"}/>} {t('save')}
                </button>
                <button type={"button"} className={"btn btn-ghost-primary"}
                        onClick={updateValues}>{t('update')}</button>
            </div>
        </form>
    );
}

export default FormPersonalInfo;