import {useTranslation} from "react-i18next";
import React from "react";

const Hobbies = ({items,handleEdit}) => {
    const {t} = useTranslation();

    return (
        <section className={"mt-2"}>
            <div className={"d-flex align-items-center justify-content-between gap-2 mb-1"}>
                <h4 className={"text-secondary mb-1"}>
                    <i className={" ri-gamepad-line align-bottom"}></i> {t('hobbies')}
                </h4>
                <button className={"btn px-0 link-primary"} onClick={handleEdit}>
                    <i className={"ri-pencil-line align-bottom"}></i> {t('edit')}
                </button>
            </div>

            <div className={"hstack gap-2"}>
                {
                    items.map((item, i) => <span key={i}>{item}{items.length - 1 === i ? '' : ','}</span>)
                }
            </div>
        </section>
    );
}

export default Hobbies;