import React, {useEffect} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {_momentLanguages} from "../../../constants/common";

import "moment/locale/ru";
import "moment/locale/uz";
import "moment/locale/uz-latn";
import {fileSizeFormatter} from "../../../helpers/myFunctions";
import {origin} from "../../../config";

const WorkHistory = ({resume, handleEdit}) => {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        moment.locale(_momentLanguages[i18n.language]);

        const handleLanguageChange = lang => {
            moment.locale(_momentLanguages[lang]);
        }

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        }
    }, []);

    return (
        <section className={"mt-2"}>
            <div className={"d-flex align-items-center justify-content-between gap-2 mb-1"}>
                <h4 className={"text-secondary mb-1"}>
                    <i className={"ri-suitcase-line align-bottom"}></i> {t('experience')}
                </h4>
                <button className={"btn px-0 link-primary"} onClick={handleEdit}>
                    <i className={"ri-pencil-line align-bottom"}></i> {t('edit')}
                </button>
            </div>

            {
                resume?.experience > 0 ?
                    <div>
                        <p className={"mb-2"}>
                            <span className={"fw-bold me-1"}>{t('total-experience')}:</span>
                            {resume.experience + ' ' + t(resume.experience_type).toLowerCase()}
                        </p>
                        {
                            resume.work_history.map((item, i) =>
                                <div key={i} className={"d-flex flex-wrap gap-2 gap-md-4"}>
                                    <p className={"mb-0"}>
                                        {moment(item.date_enter, 'MM/YYYY').format('MMMM, YYYY')} {" - "}
                                        {moment(item.date_leave, 'MM/YYYY').format('MMMM, YYYY')}
                                    </p>
                                    <div className={"flex-grow-1"}>
                                        <ul className={"mb-0 vstack gap-1 ps-3 ps-sm-4"}>
                                            <li>
                                                <span className={"fw-semibold me-1"}>{t('position')}:</span>
                                                {item.position}
                                            </li>
                                            <li>
                                                <span className={"fw-semibold me-1"}>{t('org-name')}:</span>
                                                {item.org_name}
                                            </li>
                                            <li>
                                                <span className={"fw-semibold me-1"}>{t('reason-to-quit')}:</span>
                                                {item.reason_to_leave || '-'}
                                            </li>
                                            <li>
                                                <span className={"fw-semibold me-1"}>{t('documents')}:</span>
                                                {
                                                    JSON.parse(item.files)?.length > 0 ?
                                                        <div className="mt-1">
                                                            {
                                                                JSON.parse(item.files).map((file, fileIdx) =>
                                                                    <div className={"d-flex gap-2"} key={fileIdx}>
                                                                        <div>
                                                                            <i className={"ri-file-2-line fs-2 text-muted"}></i>
                                                                        </div>
                                                                        <div className={"flex-grow-1"}>
                                                                            <p className={"mb-0"}>{file.name}</p>
                                                                            <p className={"mb-0 text-muted fs-12"}>{fileSizeFormatter(file.size)}</p>
                                                                        </div>
                                                                        <div className={"text-nowrap"}>
                                                                            <a className={"btn btn-icon btn-sm btn-ghost-info me-2"}
                                                                               href={origin.concat('/', file.path)}
                                                                               download={file.path.split('/').pop()}>
                                                                                <i className={"ri-download-line fs-15"}></i>
                                                                            </a>
                                                                            <button
                                                                                className={"btn btn-icon btn-soft-danger btn-sm"}>
                                                                                <i className={"las la-trash fs-15"}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div> : '-'
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                </div>)
                        }
                    </div> :
                    <span className={"badge badge-soft-secondary fs-13"}>{t('no-user-exp')}</span>
            }
        </section>
    )
};

export default WorkHistory;