import {PaginationItem, PaginationLink} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export const SeparatedPagination = ({
                                        contentAlign = "end",
                                        type = "iconText",
                                        itemsPerPage = 10,
                                        itemsNumber = 100,
                                        activePage = 1,
                                        pageNeighbours = 2,
                                        onPageChange // Function to handle page change
                                    }) => {

    const {t} = useTranslation();
    const totalPages = Math.ceil(itemsNumber / itemsPerPage);

    const getVisiblePages = () => {
        const pages = [];

        const startPage = Math.max(2, activePage - pageNeighbours);
        const endPage = Math.min(totalPages - 1, activePage + pageNeighbours);

        // First page always visible
        pages.push(1);

        // Show ellipsis if startPage is greater than 2
        if (startPage > 2) {
            pages.push('...');
        }

        // Add pages between startPage and endPage
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // Show ellipsis if endPage is less than totalPages - 1
        if (endPage < totalPages - 1) {
            pages.push('...');
        }

        // Last page always visible
        if (totalPages > 1) {
            pages.push(totalPages);
        }

        return pages;
    };

    const visiblePages = getVisiblePages();

    return (
        <div
            className={`pagination-block pagination pagination-separated justify-content-center justify-content-sm-${contentAlign} mb-sm-0`}>
            {/* Previous Button */}
            <PaginationItem disabled={activePage === 1}>
                <PaginationLink
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        if (activePage > 1) onPageChange(activePage - 1);
                    }}
                >
                    {type === "iconText" ? <span>← &nbsp; {t("previous")}</span> : type === 'icon' ? <span>←</span> :
                        <span>{t('previous')}</span>}
                </PaginationLink>
            </PaginationItem>

            {/* Page Numbers */}
            {visiblePages.map((page, index) => (
                page === '...' ? (
                    <PaginationItem key={index} disabled>
                        <PaginationLink href="#">...</PaginationLink>
                    </PaginationItem>
                ) : (
                    <PaginationItem key={index} active={activePage === page}>
                        <PaginationLink
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                onPageChange(page);
                            }}
                        >
                            {page}
                        </PaginationLink>
                    </PaginationItem>
                )
            ))}

            {/* Next Button */}
            <PaginationItem disabled={activePage === totalPages}>
                <PaginationLink
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        if (activePage < totalPages) onPageChange(activePage + 1);
                    }}
                >
                    {type === "iconText" ? <span>{t("next")}&nbsp; →</span> : type === 'icon' ? <span>→</span> :
                        <span>{t('next')}</span>}
                </PaginationLink>
            </PaginationItem>
        </div>
    );
};
