import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    Container,
    Nav,
    NavItem,
    NavLink,
    Progress,
    TabContent,
    TabPane
} from 'reactstrap';

//import images
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import PersonalInfo from "./PersonalInfo";
import WorkingHistory from "./WorkingHistory";
import Education from "./Education";
import Additionals from "./Additionals";
import FinalSuccess from "./FinalSuccess";
import {getCookie} from "../../../helpers/myFunctions";
import {origin} from "../../../config";
import {useDispatch} from "react-redux";
import {changeIsLoaded} from "../../../store/layouts/action";
import {serverPaths} from "../../../constants/appPaths";

const Index = () => {
    document.title = "Hrplus | Create Resume";

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [allValues, setAllValues] = useState({});
    const [activeTab, setactiveTab] = useState(1);
    const [progressbarvalue, setprogressbarvalue] = useState(0);
    const [passedSteps, setPassedSteps] = useState([1]);

    function toggleTab(tab, value) {
        if (activeTab !== tab) {
            const modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 5) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
        setprogressbarvalue(value);
    }

    const handleValues = values => {
        setAllValues(state => ({
            ...state,
            ...values
        }));
    }

    const createNewResume = async (vals) => {
        handleValues(vals);
        return await fetch(origin.concat(serverPaths.POST.CREATE_USER_RESUME), {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                userId: getCookie("userId")
            },
            body: JSON.stringify(allValues)
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp['status']) {
                    toast.success(t('created-success'));

                } else {
                    toast.error(t('server-error'),);
                }
                return resp.status;
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
                return false;
            });
    }

    useEffect(() => {
        dispatch(changeIsLoaded(true));
    }, []);

    return (
        <div className="page-content">
            <Container fluid>
                <Card className={"mb-0"}>
                    <CardBody className="p-3">
                        <h4 className='fw-semibold mb-4 text-center'>
                            {t('create-resume')}
                        </h4>
                        <div className="progress-nav mb-4">
                            <Progress
                                value={progressbarvalue}
                                style={{height: "1px"}}
                            />

                            <Nav
                                className="nav-pills progress-bar-tab custom-nav"
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        id="personal-info"
                                        className={classnames(
                                            {
                                                active: activeTab === 1,
                                                done: activeTab <= 5 && activeTab >= 0,
                                            },
                                            "rounded-pill"
                                        )}
                                        tag="button"
                                        type={"button"}
                                    >
                                        1
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        id="working-history-step"
                                        className={classnames(
                                            {
                                                active: activeTab === 2,
                                                done: activeTab <= 5 && activeTab > 1,
                                            },
                                            "rounded-pill"
                                        )}
                                        tag="button"
                                        type={"button"}
                                    >
                                        2
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        id="education-step"
                                        className={classnames(
                                            {
                                                active: activeTab === 3,
                                                done: activeTab <= 5 && activeTab > 2,
                                            },
                                            "rounded-pill"
                                        )}
                                        tag="button"
                                        type={"button"}
                                    >
                                        3
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        to="#"
                                        id="additionals-step"
                                        className={classnames(
                                            {
                                                active: activeTab === 4,
                                                done: activeTab <= 5 && activeTab > 3,
                                            },
                                            "rounded-pill"
                                        )}
                                        tag="button"
                                        type={"button"}
                                    >
                                        4
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        to="#"
                                        id="final-step"
                                        className={classnames(
                                            {
                                                active: activeTab === 5,
                                                done: activeTab <= 5 && activeTab > 4,
                                            },
                                            "rounded-pill"
                                        )}
                                        tag="button"
                                        type={"button"}
                                    >
                                        5
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                                <PersonalInfo t={t}
                                              activeTab={activeTab}
                                              toggleTab={toggleTab}
                                              handleValues={handleValues}
                                />
                            </TabPane>

                            <TabPane tabId={2}>
                                <WorkingHistory t={t}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                                handleValues={handleValues}/>
                            </TabPane>

                            <TabPane tabId={3}>
                                <Education t={t}
                                           activeTab={activeTab}
                                           toggleTab={toggleTab}
                                           handleValues={handleValues}/>
                            </TabPane>

                            <TabPane tabId={4}>
                                <Additionals t={t}
                                             activeTab={activeTab}
                                             toggleTab={toggleTab}
                                             handleCreateNewResume={createNewResume}
                                />
                            </TabPane>

                            <TabPane tabId={5}>
                                <FinalSuccess t={t}/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default Index;