import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Container, Row, Tooltip} from 'reactstrap';

// Import Images
import logolight from "../assets/images/hrplus.png";
import {useTranslation} from "react-i18next";
import YandexMaps from "../Components/YandexMaps";
import {organization} from "../config";
import {copyToClipboard} from "../helpers/myFunctions";
import useWindowSize from "../hooks/windowSize";

const Footer = () => {
    const {t} = useTranslation();
    const windowSize = useWindowSize();

    const [isPhoneCopied, setIsPhoneCopied] = React.useState(false);

    const copyPhoneHandler = () => {
        copyToClipboard(organization.contact_phone)
            .then(() => {
                setIsPhoneCopied(true);
                setTimeout(() => setIsPhoneCopied(false), 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className={'no-print-section'}>
            <footer className="custom-footer py-2 py-md-4 position-relative" style={{backgroundColor: "#212529"}}>
                <Container fluid className={"fs-15 app-container mx-auto"}>
                    <Row className='g-0 g-sm-3'>
                        <Col lg={8} className={"position-relative"}>
                            <Row className={"g-2"}>
                                <Col md={4}>
                                    <div>
                                        <img src={logolight} className={"mt-sm-n1"} alt="logo light" height="50"/>
                                    </div>
                                    <div className='d-flex mt-2 mt-md-3 gap-1'>
                                        <Link to={organization.contact_youtube_link} className='text-white'><i
                                            className="ri-youtube-fill"
                                            style={{fontSize: "1.6rem"}}></i></Link>
                                        <Link to={organization.contact_instagram_link} className='text-white'><i
                                            className="ri-instagram-fill"
                                            style={{fontSize: "1.6rem"}}></i></Link>
                                        <Link to={organization.contact_telegram_link} className='text-white'><i
                                            className="ri-telegram-fill" style={{fontSize: "1.6rem"}}></i></Link>
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <h5 className="text-white mb-0">{t('company')}</h5>
                                    <div className="text-muted mt-0 mt-md-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li><Link to="#">{t('about-us')}</Link></li>
                                        </ul>
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <h5 className="text-white mb-0">{t('contact')}</h5>
                                    <div className="text-muted mt-0 mt-md-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li>
                                                <Link id={'contactPhone'} to="#" className='d-flex align-items-center'
                                                      onClick={copyPhoneHandler}>
                                                    <i className="ri-phone-fill fs-18 me-2"></i>
                                                    {organization.contact_phone}
                                                </Link>
                                                <Tooltip
                                                    target={'contactPhone'}
                                                    placement="left-end"
                                                    isOpen={isPhoneCopied}
                                                >
                                                    {t('copied')}
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Link to={'mailto:' + organization.contact_email}
                                                      className='d-flex align-items-center'>
                                                    <i className="bx bxl-gmail fs-18 me-2 align-bottom"></i>
                                                    {organization.contact_email}
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to={organization.contact_telegram_link}
                                                      className='d-flex align-items-center'>
                                                    <i className="ri-telegram-fill fs-18 me-2"></i>
                                                    {/*{t('telegram')}*/}
                                                    jack_20010912
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                            {
                                windowSize.width > 1000 && <div className={"position-absolute bottom-0"}>
                                    <p className="copy-rights mb-0 fs-14">
                                        2023 © Hrplus - {t('rights-reserved')}
                                    </p>
                                </div>
                            }
                        </Col>

                        <Col lg={4}>
                            <h5 className="text-white">{t('address')}</h5>
                            <p className={"ff-secondary"}>{organization.address}</p>
                            <YandexMaps coords={organization.location_coords} zoom={15} height={170}/>
                        </Col>

                        {
                            windowSize.width <= 1000 &&
                            <div className={"mt-2 mt-lg-0"}>
                                <p className="copy-rights mb-0">
                                    2023 © Hrplus - {t('rights-reserved')}
                                </p>
                            </div>
                        }
                    </Row>
                </Container>
            </footer>
        </div>
    );
};

export default Footer;