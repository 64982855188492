export const appPaths = {
    AUTH_SIGNIN: '/auth-signin',
    AUTH_SIGNUP: '/auth-signup',
    AUTH_PASS_RESET: '/auth-reset-pass',
    AUTH_CREATE_NEW_PASS: '/create-new-password',
    AUTH_PASS_RESET_SUCCESS: '/auth-reset-success',

    DASHBOARD: '/dashboard',
    INDEX: '/dashboard',
    ABOUT_US: '/about-us',

    ALL_JOBS: '/all-jobs',
    ALL_JOBS_DETAILS: jobId => '/all-jobs/job-details/' + jobId,
    JOBS_DETAILS: jobId => '/job-details/' + jobId,
    SAVED_JOBS: '/saved-jobs',
    INTERVIEWS: '/interviews',
    PROPOSALS_OFFERS: "/proposals-offers",
    APPOINTMENTS: '/appointments',
    NOTIFICATIONS: '/notifications',
    CREATE_RESUME: '/create-resume',
    ALL_RESUMES: '/all-resumes',
    ALL_RESUMES_EDIT: resumeId => '/all-resumes/edit/' + resumeId,
    ALL_RELATED_JOBS: "#"
};

export const serverPaths = {
    GET: {
        JOBS_BEST_MATCHES: '/job/get-vacancies-best-matches',
        JOB_USER_DATA: "/job/job-user-data",
        ALL_ORGANIZATIONS: '/job/get-all-organizations',
        JOBS_FOR_USER: "/job/get-vacancies-for-user",
        RECENT_SEARCHES_LIKE: "/job/get-recent-searches-like",
        JOB_USER_RESUMES: '/job/get-job-resumes',
        SAVED_JOBS: '/job/get-saved-vacancies',
        ZONES_DATA: (type, soato) => '/get-zones-data?soato=' + soato + '&type=' + type,
        CURRENCY_RATES: '/job/get-currencies-rates',
        JOB_DETAILS: jobId => '/job/get-vacancy-details/' + jobId,
        ORGANIZATION_DATA: orgId => '/job/get-organization-data/' + orgId,
        PROPOSALS_OFFERS: '/job/get-proposals-offers',
        MOST_SEARCHED_WORDS: "/job/get-most-searched-words",
        SEND_SMS_CODE_AGAIN: userId => "/job/send-sms-code-again?id=" + userId,
    },
    POST: {
        CREATE_USER_RESUME_LEFT: "/job/create-user-left-resume",
        CREATE_USER_RESUME: "/job/create-job-resume",
        USER_INTERACTIONS: "/job/create-user-interactions",
        LOGIN_USER_PROFILE: "/job/login-job-user-profile",
        CREATE_NEW_PASS: "/job/create-new-password",
        SEND_RESET_LINK: "/job/send-reset-password-link",
        CHECK_LOGIN_EXISTS: "/job/check-login-exist",
        SEND_SMS_CODE: "/job/send-sms-code",
        CONFIRM_SMS_CODE: "/job/confirm-sms-code"
    },
    DELETE: {
        JOB_USER_RESUME: resumeId => "/job/delete-job-resume/" + resumeId,
        USER_INTERACTIONS: '/job/delete-user-interactions',
        SMS_CODE: userId => "/job/delete-sms-code?id=" + userId,
    },
    PUT: {
        USER_RESUME: (id, section) => '/job/update-user-resume/' + id + '/' + section,
    }
};

