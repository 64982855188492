import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Card, CardBody, Col, Container, Input, Label, Row, Button, FormFeedback, Spinner} from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";

//import images
// import logoDark from "../../../assets/images/logo1.png";
import logoDark from "../../../assets/images/logos/hrplus.png";
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import {origin} from "../../../config";
import {useTranslation} from "react-i18next";
import {setDataToCookie} from "../../../helpers/myFunctions";
import {appPaths, serverPaths} from "../../../constants/appPaths";

const BasicSignIn = () => {
    document.title = "Hrplus | Login";

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [viewPassword, setViewPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleViewPassword = () => {
        setViewPassword(!viewPassword);
    }
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            login: "",
            password: ""
        },
        validationSchema: Yup.object({
            login: Yup
                .string()
                .min(6, t('at-least-n-symbol', {n: 6}))
                .required('Login kiritilishi kerak'),
            password: Yup
                .string()
                .min(6, t('at-least-n-symbol', {n: 6}))
                .required(t("field-required"))
        }),
        onSubmit: async (values) => {
            setLoading(true);
            const response = await fetch(origin.concat(serverPaths.POST.LOGIN_USER_PROFILE), {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-type": "application/json",
                    "login": "HRplus+",
                    "password": "Ctc0oTYayc77z"
                },
                body: JSON.stringify({
                    login: values['login'],
                    password: values['password']
                })
            })
                .then(resp => resp.json())
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => {
                    setLoading(false);
                });

            // console.log("response", response);
            if (response['status'] === false) {
                toast.error(t('server-error'),);

            } else if (!response['is_login_correct']) {
                toast.warning(t('login-incorrect'));

            } else if (!response['is_pass_correct']) {
                toast.warning(t('pass-incorrect'));

            } else {
                setDataToCookie('userId', response['id'], 30);

                window.history.replaceState(null, null, appPaths.ALL_JOBS);
                navigate(appPaths.ALL_JOBS);
            }
        }
    });

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="d-flex flex-column align-items-center justify-content-center auth-page-content">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="px-2 px-sm-3 py-3">
                                        <div className="text-center mb-1 text-white-50">
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoDark} alt="" height="55"/>
                                            </Link>
                                        </div>
                                        <div className="text-center my-2">
                                            <h5 className="text-primary">Pulatformamizga xush kelibsiz!</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <form onSubmit={validation.handleSubmit} className="needs-validation"
                                                  noValidate>
                                                <div className="mb-3">
                                                    <Label htmlFor="login" className="form-label">Login</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="login"
                                                        name="login"
                                                        placeholder="Loginni kiriting"
                                                        autoComplete='true'
                                                        value={validation.values.login}
                                                        onChange={validation.handleChange}
                                                        invalid={Boolean(validation.errors.login && validation.touched.login)}
                                                    />
                                                    {validation.errors.login && validation.touched.login ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.login}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link
                                                            to="/auth-reset-pass"
                                                            className="text-muted"
                                                        >{t('forgot-password')}</Link>
                                                    </div>
                                                    <Label className="form-label"
                                                           htmlFor="password">{t('password')}</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            type={viewPassword ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Parolni kiriting"
                                                            id="password"
                                                            name="password"
                                                            autoComplete='true'
                                                            value={validation.values.password}
                                                            onChange={validation.handleChange}
                                                            invalid={Boolean(validation.errors.password && validation.touched.password)}
                                                        />
                                                        <button
                                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                            type="button" id="password-addon"
                                                            onClick={toggleViewPassword}>
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </button>

                                                        {validation.errors.password && validation.touched.password ? (
                                                            <FormFeedback
                                                                type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <Button
                                                        color="success"
                                                        className="btn btn-success w-100 d-inline-flex align-items-center justify-content-center gap-2"
                                                        type="submit"
                                                        disabled={loading}>
                                                        {loading &&
                                                            <Spinner style={{
                                                                height: 18,
                                                                width: 18
                                                            }}/>} {t('sign-in')}</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">{t('no-acccount')}
                                        <Link
                                            to={appPaths.AUTH_SIGNUP}
                                            className="fw-semibold text-primary text-decoration-underline ms-2"
                                        >
                                            {t('register')}
                                        </Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default BasicSignIn;