import {
    USER_FULL_NAME,
    NAVBAR_ACTIVE, USER,
    ALL_JOBS_FILTERS, CURRENCIES_RATES, NUMBER_OF_JOBS_PER_FILTER
} from "./actionType";

export const setUserFullName = (data) => ({
    type: USER_FULL_NAME,
    payload: data,
});

export const setNavbarActive = (data) => ({
    type: NAVBAR_ACTIVE,
    payload: data,
});

export const setUser = (data) => ({
    type: USER,
    payload: data
});

export const changeAllJobsFilters = (data) => ({
    type: ALL_JOBS_FILTERS,
    payload: data
});

export const changeCurrenciesRates = (data) => ({
    type: CURRENCIES_RATES,
    payload: data
});

export const changeNumOfJobsPerFilters = (data) => ({
    type: NUMBER_OF_JOBS_PER_FILTER,
    payload: data
});
