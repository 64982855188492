import React from 'react';
import {Col, FormFeedback, Input, Label, Row} from "reactstrap";
import {AndvancedSelect} from "../../../Components/Selects";
import {_allCountriesNationalities} from "../../../constants/common";
import PhoneNumberField from "../../../Components/PhoneNumberField";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {useFormik} from "formik";
import defaultPhoto from "../../../assets/images/users/user-dummy-img.jpg";
import {useSelector} from "react-redux";
import {origin} from "../../../config";
import {FieldDateLikeDDMMYYYY} from "../../../Components/Fields";
import {resumeSchema} from "../../../common/validationSchemas";

const PersonalInfo = ({t, toggleTab, activeTab, handleValues}) => {
    const {user} = useSelector((state) => state.Jobs);

    const changeUserPhoto = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = function (loadEvent) {
                    validation.setFieldValue('image', {
                        base64: loadEvent.target.result,
                        type: file.type.split('/')[1],
                        name: file.name
                    });
                };

                reader.readAsDataURL(file);

            } else {
                toast.warning(t('file-format-incorrect'));
            }
        }
    }

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            image: {
                base64: user.image_path ? origin + '/' + user.image_path : "",
                type: ""
            },
            fullName: user.full_name || "",
            gender: '',
            birthday: "",
            maritalStatus: '',
            citizenship: '',
            nationality: '',
            email: user.email || "",
            phone: user.phone || "",
            countryCode: user.phone_code || "",
            address: "",
        },
        validationSchema: resumeSchema.personalInfo(Yup, t),
        onSubmit: (values) => {
            toggleTab(activeTab + 1, 25);
            handleValues({
                ...values,
                birthday: values.birthday,
                gender: values.gender?.value || '',
                citizenship: values.citizenship?.value || '',
                nationality: values.nationality?.value || '',
                maritalStatus: values.maritalStatus?.value || ''
            });
        }
    });

    return (
        <form onSubmit={validation.handleSubmit}>
            <h5 className={"mb-3"}>#{t('personal-info')}</h5>
            <Row className={"g-3"}>
                <Col lg={12} className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto">
                        <img src={validation.values.image.base64 || defaultPhoto}
                             className="rounded-circle img-thumbnail user-profile-image avatar-xl"
                             alt="user-profile"
                        />
                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <Input
                                id="profile-img-file-input"
                                type="file"
                                accept="image/*"
                                className="profile-img-file-input"
                                onChange={changeUserPhoto}
                            />
                            <Label htmlFor="profile-img-file-input"
                                   className="profile-photo-edit avatar-xs mb-0">
                                                        <span
                                                            className="avatar-title rounded-circle bg-light text-body">
                                                            <i className="ri-camera-fill text-primary"></i>
                                                        </span>
                            </Label>
                        </div>
                    </div>
                </Col>
                <Col md={6} lg={4}>
                    <Label htmlFor="fullName" className="form-label">
                        <span>{t('full-name')}</span>
                        <span className='text-danger'>*</span>
                    </Label>
                    <Input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name={"fullName"}
                        placeholder={t("enter-full-name")}
                        value={validation.values.fullName}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.fullName && validation.touched.fullName)}
                    />
                    {validation.errors.fullName && validation.touched.fullName ? (
                        <FormFeedback
                            type="invalid">{validation.errors.fullName}</FormFeedback>
                    ) : null}
                </Col>
                <Col md={6} lg={4}>
                    <Label htmlFor="gender" className="form-label">
                        <span>{t('gender')}</span>
                        <span className='text-danger'>*</span></Label>
                    <AndvancedSelect
                        id={"gender"}
                        name={"gender"}
                        placeholder={t('select-gender')}
                        value={validation.values.gender}
                        handleChange={value => validation.setFieldValue("gender", value)}
                        error={validation.errors.gender}
                        isInvalid={Boolean(validation.errors.gender && validation.touched.gender)}
                        options={[
                            {value: "male", label: t('male')},
                            {value: "female", label: t('female')},
                            {value: "another", label: t('another')}
                        ]}/>
                </Col>
                <Col md={6} lg={4}>
                    <Label className="form-label" htmlFor="birthday">
                        {t('birthday')}
                    </Label>
                    <FieldDateLikeDDMMYYYY
                        id={"birthday"}
                        name={"birthday"}
                        placeholder={"DD/MM/YYYY"}
                        value={validation.values.birthday}
                        handleChange={validation.handleChange}
                        isInvalid={Boolean(validation.errors.birthday && validation.touched.birthday)}
                        error={validation.errors.birthday}
                    />
                </Col>

                <Col md={6} lg={4}>
                    <Label htmlFor="maritalStatus" className="form-label">
                        <span>{t('marital-status')}</span>{" "}
                        <span className={"text-danger"}>*</span>
                    </Label>
                    <AndvancedSelect
                        id={"maritalStatus"}
                        name={"maritalStatus"}
                        placeholder={t('select-marital-status')}
                        value={validation.values.maritalStatus}
                        handleChange={value => validation.setFieldValue("maritalStatus", value)}
                        error={validation.errors.maritalStatus}
                        isInvalid={Boolean(validation.errors.maritalStatus && validation.touched.maritalStatus)}
                        options={[
                            {value: "married", label: t('married')},
                            {value: "unmarried", label: t('unmarried')},
                            {value: "widow", label: t('widow')},
                            {value: "divorced", label: t('divorced')}
                        ]}
                    />
                </Col>

                <Col md={6} lg={4}>
                    <Label htmlFor="citizenship"
                           className="form-label">
                        {t('citizenship')}
                        <span className='text-danger'>*</span></Label>
                    <AndvancedSelect
                        id={"citizenship"}
                        name={"citizenship"}
                        placeholder={t('select-citizenship')}
                        value={validation.values.citizenship}
                        error={validation.errors.citizenship}
                        handleChange={value => validation.setFieldValue("citizenship", value)}
                        isInvalid={Boolean(validation.errors.citizenship && validation.touched.citizenship)}
                        options={_allCountriesNationalities.map(item => ({
                            value: item.en_short_name,
                            label: item.en_short_name,
                        }))}/>
                </Col>

                <Col md={6} lg={4}>
                    <Label htmlFor="nationality"
                           className="form-label">
                        {t('nationality')}
                        <span className='text-danger'>*</span></Label>
                    <AndvancedSelect
                        id={"nationality"}
                        name={"nationality"}
                        placeholder={t('select-nationality')}
                        value={validation.values.nationality}
                        handleChange={value => validation.setFieldValue("nationality", value)}
                        error={validation.errors.nationality}
                        isInvalid={Boolean(validation.errors.nationality && validation.touched.nationality)}
                        options={_allCountriesNationalities.map(item => ({
                            value: item.nationality,
                            label: item.nationality,
                        }))}/>
                </Col>

                <Col md={6} lg={4}>
                    <PhoneNumberField
                        name={"phone"}
                        placeholder={t("enter-phone")}
                        error={validation.errors.phone && validation.touched.phone ? validation.errors.phone : ''}
                        value={validation.values.phone}
                        label={t('phone')}
                        handler={validation.handleChange}
                        handlerCountryCode={val => {
                            validation.setFieldValue('countryCode', val);
                        }}
                    />
                </Col>

                <Col md={6} lg={4}>
                    <Label htmlFor="email" className="form-label">
                        {t('email')}
                    </Label>
                    <Input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder={t('enter-email')}
                        value={validation.values.email}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.email && validation.touched.email)}
                    />
                    {validation.errors.email && validation.touched.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                </Col>

                <Col md={6} lg={4}>
                    <Label htmlFor="address" className="form-label">
                        {t('address')}
                    </Label>
                    <Input
                        type="text"
                        id="address"
                        name={"address"}
                        placeholder={t('enter-living-address')}
                        className="form-control"
                        value={validation.values.address}
                        onChange={validation.handleChange}
                    />
                </Col>
            </Row>
            <div className="d-flex align-items-start gap-3 mt-4">
                <button
                    type="submit"
                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                    {t('continue')}
                </button>
            </div>
        </form>
    );
}

export default PersonalInfo;