import React from 'react';
import {Card, CardBody, CardFooter} from "reactstrap";
import {origin} from "../../../config";
import defaultUser from "../../../assets/images/users/user-dummy-img.jpg";
import {_languages, _workTyimeTypeKeys} from "../../../constants/common";

import {Link} from "react-router-dom";
import {appPaths} from "../../../constants/appPaths";
import {MomentLongMonthTime} from "../../../Components/Moments";
import {useTranslation} from "react-i18next";

const ResumeCard = ({item, handleDelete, isFetching}) => {

    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Card className={"mb-0"}>
                <CardBody>
                    <div className={"d-flex align-items-start justify-content-between gap-2 flex-wrap"}>
                        <div className={"d-flex align-items-start gap-2 gap-md-3"}>
                            <div>
                                <img className={"avatar-md rounded object-cover border"}
                                     src={item.image_path ? origin.concat('/', item.image_path) : defaultUser}
                                     alt={""}/>
                            </div>
                            <div>
                                <Link to={appPaths.ALL_RESUMES_EDIT(item.id)}>
                                    <h5 className={"mb-1 link-primary"}>{item.name}</h5>
                                </Link>
                                <p className={"mb-1"}>{item.profession}</p>
                                {
                                    JSON.parse(item.languages).length > 0 ?
                                        JSON.parse(item.languages).map((l, i) =>
                                            <span key={i}
                                                  className={"me-1 badge badge-soft-secondary fs-11"}>{_languages[l]}</span>) :
                                        <></>
                                }
                            </div>
                        </div>

                        <div>
                            <div className={"mb-1"}>
                                <i className={"ri-suitcase-line align-bottom me-1"}></i>
                                {item.experience ? t('total-experience').concat(': ', item.experience.toString(), ' ', t(item.experience_type).toLowerCase()) : t('no-user-exp')}
                            </div>

                            <div className={"badge badge-soft-secondary fs-11"}>
                                {t(_workTyimeTypeKeys[item.work_type.toString()])}
                            </div>
                        </div>

                        <div className={'ff-secondary'}>
                            <div className={"mb-1"}>
                                <i className={"ri-map-pin-2-line align-bottom me-1"}></i>
                                {item.address || '-'}
                            </div>
                            <div className={"mb-1"}>
                                <i className={"ri-phone-line align-bottom me-1"}> </i>
                                {item.phone || '-'}
                            </div>
                            <div>
                                <i className={"bx bxl-gmail align-middle me-1"}></i>
                                {item.email || '-'}
                            </div>
                        </div>

                    </div>
                </CardBody>
                <CardFooter className={"border-top-dashed"}>
                    <div className={"d-flex align-items-center justify-content-between flex-wrap gap-2"}>
                        <div className={""}>
                            <i className={"ri-heart-line align-bottom me-1"}></i> {t('n-likes', {n: 2})}
                        </div>
                        <div>
                            <i className={"ri-eye-line align-bottom me-1"}></i> {t('n-views', {n: 2})}
                        </div>
                        <div>
                            <i className={"ri-mail-check-line align-bottom me-1"}></i> {t('n-invitations', {n: 2})}
                        </div>
                        <div>
                            <i className={"ri-time-line align-bottom me-1"}></i>
                            <MomentLongMonthTime date={item.created_time}/>
                        </div>
                        <div className={"flex-grow-1 flex-sm-grow-0 text-end"}>
                            <button className={"btn-sm btn btn-ghost-danger w-sm me-2"} color={"danger"}
                                    onClick={e => handleDelete(item.id)}
                                    disabled={isFetching}
                            >
                                <i className={"ri-delete-bin-fill align-bottom"}></i> {t('delete')}
                            </button>
                            <a className={"btn-sm btn btn-success w-sm"} color={"success"}
                               href={appPaths.ALL_RESUMES_EDIT(item.id)}>
                                <i className={"ri-pencil-fill align-bottom"}></i> {t('edit')}
                            </a>
                        </div>
                    </div>
                </CardFooter>
            </Card>
        </React.Fragment>
    );
}

export default ResumeCard;