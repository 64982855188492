import React from "react";
import {Card, CardBody, Row} from "reactstrap";

import defaultCompany from "../../assets/images/companies/default-company.png";
import {origin} from "../../config";
import {MomentLongMonthTime} from "../../Components/Moments";
import {_workTyimeTypeKeys} from "../../constants/common";

const Header = ({data, t}) => {

    return (
        <Card className="mb-0 bg-soft-warning mx-md-n4">
            <CardBody>
                <Row className="mb-3">
                    <div className="col-md">
                        <Row className="align-items-center g-3">
                            <div className="col-md-auto">
                                <div className="avatar-md bg-white rounded-circle p-2">
                                    <img
                                        src={data.is_logo_visible && data.image_path ? origin.concat('/' + data.image_path) : defaultCompany}
                                        alt=""
                                        className="w-100 h-100 rounded-circle border"/>
                                </div>
                            </div>
                            <div className="col-md">
                                <div>
                                    <h4 className="fw-bold">{data['name']}</h4>
                                    <div className="hstack gap-3 flex-wrap">
                                        <div>
                                            <i className="ri-building-line align-bottom me-1"></i>{" "}
                                            {data['company_name']}
                                        </div>
                                        <div className="vr"></div>
                                        <div>
                                            <i className="ri-map-pin-2-line align-bottom me-1"></i>{" "}
                                            {data['address']}
                                        </div>
                                        <div className="vr"></div>
                                        <div className="badge rounded-pill bg-success fs-12">
                                            {t(_workTyimeTypeKeys[data.work_type])}
                                        </div>
                                        <div className="vr"></div>
                                        <div>
                                            <i className={"ri-time-line align-bottom me-1"}></i>{t('created-time')}:{" "}
                                            <MomentLongMonthTime date={data['created_time']}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>

                    <div className="col-md-auto">
                        <div className="hstack gap-1 flex-wrap mt-4 mt-md-0">
                            <button
                                type="button"
                                className="btn btn-icon btn-sm btn-ghost-warning fs-16"
                            >
                                <i className="ri-star-fill"></i>
                            </button>
                            <button
                                type="button"
                                className="btn btn-icon btn-sm btn-ghost-primary fs-16"
                            >
                                <i className="ri-share-line"></i>
                            </button>
                        </div>
                    </div>
                </Row>
            </CardBody>
        </Card>
    );
};

export default Header;
