import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../Components/Common/TableContainer";
import { Spinner } from "reactstrap";
import { format2ISO } from "../../helpers/myFunctions";

const headers = {
    "Content-type": "application/json",
    "login": "HRplus+",
    "password": "Ctc0oTYayc77z"
};

const LoadingStateTable = () => {
    const [display, setDisplay] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [appointments2, setAppointments2] = useState([]);

    useEffect(() => {
        setDisplay(false);
        fetch('https://online.hrplus.uz/get-job-user-appointment', {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                't1.user_id': +localStorage.getItem('USER_ID'),
                't1.status': 1,
                with_vacant: true
            })
        })
            .then(resp => resp.json())
            .then(resp => {
                console.log(resp);
                resp = resp.map(val => {
                    val.created_time = format2ISO(new Date(val.created_time)) + ' ' +
                        new Date(val.created_time).toLocaleTimeString('uz', { minute: "2-digit", hour: "2-digit", hour12: false })
                    return val;
                })
                setAppointments(resp);
                setAppointments2(resp);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setDisplay(true);
            });

    }, []);

    const filterDate = (date) => {
        if (date.length) {
            let startDate = format2ISO(date[0]);
            let endDate = format2ISO(date[date.length - 1]);

            let data = appointments2.filter(val => {
                let date = val.created_time.split(' ')[0];
                return date >= startDate && date <= endDate;
            });
            setAppointments(state => data);

        } else {
            setAppointments(appointments2);
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: () => <div style={{ whiteSpace: 'nowrap' }}>Vakansiya nomi</div>,
                accessor: "vacant_name",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: () => <div style={{ whiteSpace: 'nowrap' }}>Uchrashuv sanasi</div>,
                accessor: "date",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: () => <div style={{ whiteSpace: 'nowrap' }}>Uchrashuv vaqti</div>,
                accessor: "time",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: () => <div style={{ whiteSpace: 'nowrap' }}>Uchrashuv joyi</div>,
                accessor: "place",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: () => <div style={{ whiteSpace: 'nowrap' }}>Qo'shimcha</div>,
                accessor: "information",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: () => <div style={{ whiteSpace: 'nowrap' }}>Yaratilan vaqti</div>,
                accessor: "created_time",
                disableFilters: true,
                filterable: true,
            }
        ],
        []
    );

    return (
        <React.Fragment >
            {display ? <TableContainer
                columns={(columns || [])}
                data={(appointments || [])}
                isPagination={true}
                isGlobalFilter={true}
                isCustomerFilter={true}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={7}
                className="custom-header-css table align-middle table-nowrap"
                tableClassName="table-centered align-middle table-nowrap mb-0"
                theadClassName="text-muted table-light"
                SearchPlaceholder="Ixtiyoriy ustun bo'yicha qidiring..."
                filterDate={filterDate}
                divClass="table-responsive"
            /> : <div className="text-center"><Spinner animation="border" variant="primary" /></div>}
        </React.Fragment >
    );
};

export default LoadingStateTable;