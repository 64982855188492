import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Row} from "reactstrap";
import ModalLeftResume from "../JobList/ModalLeftResume";
import {formatAge, formatExperience, formatSalary} from "../../helpers/jobs";

const JobDescription = ({data, t}) => {
    console.log('data', data);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(state => !state);
    }

    const handleLeftResume = (event) => {
        event.preventDefault();
        setIsModalOpen(true);
    }

    return (
        <React.Fragment>
            <Card className={"mb-0"}>
                <CardBody>
                    <Row className={"g-2 g-md-2"}>
                        <Col xs={12}>
                            <h5 className="mb-2">{t('description')}</h5>
                            <p className="text-muted mb-0">
                                {data['description']}
                            </p>
                        </Col>
                        <Col xs={12}>
                            <h5 className="mb-2">{t("age")}</h5>
                            <p className="text-muted mb-0">
                                {formatAge(data.age_start, data.age_end, t)}
                            </p>
                        </Col>
                        <Col xs={12}>
                            <h5 className="mb-2">{t('gender')}</h5>
                            <p className="text-muted mb-0">
                                {!data['gender'] ? t('doesnt-matter') : data['gender'] === 1 ? t('male') : t('female')}
                            </p>
                        </Col>
                        <Col xs={12}>
                            <h5 className="mb-2">{t('edu-level')}</h5>
                            <p className="text-muted mb-0">
                                {data.edu_level ? t('edu-' + data.edu_level) : t('doesnt-matter')}
                            </p>
                        </Col>
                        <Col xs={12}>
                            <h5 className="mb-2">{t('experience')}</h5>
                            <p className="text-muted mb-0">
                                {formatExperience(data.experience, t)}
                                {t(data.experience_type)}
                            </p>
                        </Col>
                        <Col xs={12}>
                            <h5 className="mb-2">{t('salary')}</h5>
                            <p className="text-muted mb-0">
                                {formatSalary(data.salary_start, data.salary_end, data.cash_type, t)}
                            </p>
                        </Col>
                        <Col xs={12}>
                            <h5 className="mb-2">{t('requirements')}</h5>
                            <p className="text-muted mb-2">
                                {t('title-requirements', {title: data.title})}
                            </p>
                            <ul className="text-muted vstack gap-2 mb-0">
                                {
                                    !(data['requirements'] && JSON.parse(data['requirements']).length) ?
                                        <li>{t('no-requirements', {name: data['requirements']?.toLowerCase()})}</li> :
                                        JSON.parse(data['requirements']).map((val, index) => <li key={index}>{val}</li>)
                                }
                            </ul>
                        </Col>

                        <Col xs={12}>
                            <h5 className="mb-2">{t('privilages')}</h5>
                            <ul className="text-muted vstack gap-2 mb-0">
                                {data['privileges'] && JSON.parse(data['privileges']).length ?
                                    JSON.parse(data['privileges']).map((val, index) => <li key={index}>{val}</li>) :
                                    <li>{t('no-requirements', {name: t('privilages')?.toLowerCase()})}</li>
                                }
                            </ul>
                        </Col>

                        <Col xs={12}>
                            <h5 className="mb-2">{t('contact')}</h5>
                            <div className={"d-flex flex-column gap-1 text-muted"}>
                                {!data.email && !data.phone && !data.tg_username && <p className={"mb-0"}>-</p>}
                                {data.phone && <p className="mb-0"><i className={"ri-phone-line align-bottom"}></i> {data['phone']}</p>}
                                {data.email && <p className="mb-0"><i className={"mdi mdi-gmail align-bottom"}></i> {data['email']}</p>}
                                {data.tg_username && <p className="mb-0"><i className={"ri-telegram-line align-bottom"}></i> @{data['tg_username']}</p>}
                            </div>
                     </Col>

                        <Col xs={12}>
                            <h5 className="mb-2">{t('address')}</h5>
                            <p className="text-muted mb-0">
                                {data['address'] || '-'}
                            </p>
                        </Col>

                        <Col xs={12}>
                            <div className="d-flex justify-content-between flex-wrap gap-2">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                        <h5 className="mb-0">{t('share-job')}:</h5>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#!" className="btn btn-icon btn-soft-info">
                                            <i className="ri-facebook-line"></i>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#!" className="btn btn-icon btn-soft-success">
                                            <i className="ri-whatsapp-line"></i>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#!" className="btn btn-icon btn-soft-secondary">
                                            <i className="ri-twitter-line"></i>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#!" className="btn btn-icon btn-soft-danger">
                                            <i className="ri-mail-line"></i>
                                        </Link>
                                    </li>
                                </ul>
                                <button className="btn btn-primary" onClick={handleLeftResume}>
                                    {t('leaving-resume')}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <ModalLeftResume isModalOpen={isModalOpen} toggleModal={toggleModal} org_id={data['org_id']}
                             vacant_id={data['id']}/>
        </React.Fragment>
    );
};

export default JobDescription;
