import {Col, Row, Spinner} from "reactstrap";
import {AndvancedSelect, SelectCreatableValues} from "../../../../Components/Selects";
import {_languages, editResumeSections} from "../../../../constants/common";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {origin} from "../../../../config";
import {serverPaths} from "../../../../constants/appPaths";
import {getCookie} from "../../../../helpers/myFunctions";
import {toast} from "react-toastify";

const inisitializeValues = (hobbies) => ({
    hobbies: hobbies ? JSON.parse(hobbies).map(item => ({
        value: item,
        label: item,
    })) : []
});

const FormHobbies = ({resumeId, hobbies, toggle, changeResume}) => {
    const {t} = useTranslation();

    const [isSaving, setIsSaving] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: inisitializeValues(hobbies),
        // validationSchema: Yup.object().shape({
        //     language: Yup.array().min(1, t('field-required'))
        // }),
        onSubmit: (values) => {
            setIsSaving(true);
            fetch(origin.concat(serverPaths.PUT.USER_RESUME(resumeId, editResumeSections.HOBBIES)), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    userId: +getCookie("userId")
                },
                body: JSON.stringify({
                    ...values,
                    hobbies: values.hobbies.map(item => item.value)
                })
            })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp.status) {
                        toast.success(t('updated'));
                        changeResume(resp.data);
                        toggle();

                    } else {
                        toast.error(t('server-error'));
                    }
                })
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => setIsSaving(false));
        }
    });

    const handleReset = () => {
        validation.handleReset();
        inisitializeValues(hobbies);
    }

    return (
        <form onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
        }}>
            <Row className={"g-4"}>
                <Col xs={12}>
                    <SelectCreatableValues
                        id={"hobbies"}
                        name={"hobbies"}
                        value={validation.values.hobbies}
                        handleChange={val => validation.setFieldValue("hobbies", val)}
                        placeholder={t('type-and-press-enter')}
                        isInvalid={Boolean(validation.errors.hobbies && validation.touched.hobbies)}
                        error={validation.errors.hobbies}
                    />
                </Col>
                <Col xs={12}>
                    <button type={"submit"}
                            className={"btn btn-success d-inline-flex align-items-center gap-2 me-2"}
                            disabled={isSaving}>
                        {isSaving && <Spinner type={"grow"} size={"sm"}/>} {t('save')}
                    </button>
                    <button type={"button"}
                            className={"btn btn-ghost-primary"}
                            onClick={handleReset}
                    >
                        {t('update')}
                    </button>
                </Col>
            </Row>
        </form>
    );
}

export default FormHobbies;