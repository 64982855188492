import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import LoadingStateTable from './Table';

const ReactTable = () => {
  document.title = "Hrplus | Appointments";

  return (
    <React.Fragment>
      <div className="page-content bg-light px-lg-5">
        <Row>
          <Col lg={12}>
            <Card>
              {/* <CardHeader>
                <h5 className="card-title mb-0">Loading State</h5>
              </CardHeader> */}
              <CardBody className="pt-0">
                <LoadingStateTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ReactTable;