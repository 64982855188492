export const ByStateEnum = {
    DATE: "by-date",
    RELEVANCE: "by-relevance",
    SALARY: "by-salary",
    EXPERIENCE: "by-experience",
};

export const ForDurationEnum = {
    FOR_ALL_TIME: 'for-all-time',
    FOR_DAY: "for-day",
    FOR_LAST_THREE_DAYS: "for-last-three-days",
    FOR_WEEK: "for-week",
    FOR_MONTH: "for-month",
};

export const CurrenciesEnum = {
    SOUM: "soum",
    DOLLAR: "dollar",
    EURO: "euro",
}

export const EducationEnum = {
    DOEST_MATTER: "doesnt-matter",
    NOT_REQUIRED: "not-required",
    MIDDLE: "edu-middle",
    BACHELOR: "edu-bachelor",
    MASTER: "edu-master",
    PHD: "edu-phd",
}
export const ProposalsRespEnum = {
    NOT_VIEWED: "not_viewed",
    VIEWED: "viewed",
    ACCEPTED: "accepted",
    REJECTED: "rejected",
}