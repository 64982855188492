import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback, Spinner} from 'reactstrap';
import ParticlesAuth from '../ParticlesAuth';

//formik
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import logoDark from "../../../assets/images/logos/hrplus.png";
import {toast} from "react-toastify";
import {appPaths, serverPaths} from "../../../constants/appPaths";
import {origin} from "../../../config";
import i18n from "../../../i18n";

const CreateNewPassword = () => {
    document.title = "Create New Password | HrPlus Job";

    const {t} = useTranslation();
    const [searchParams] = useSearchParams();

    const [passwordShow, setPasswordShow] = useState(false);
    const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        i18n.changeLanguage(searchParams.get('lang'))
    }, []);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            password: "",
            confrimPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup
                .string()
                .min(6, t('at-least-n-symbol', {n: 6}))
                .matches(RegExp('(.*[0-9].*)'), t('at-least-number'))
                .required(t('field-required')),
            confrimPassword: Yup.string()
                .when("password", {
                    is: (val) => (val && val.length > 0),
                    then: schema => schema.oneOf(
                        [Yup.ref("password")],
                        t("passwords-must-be-same")
                    ),
                })
                .required(t("field-required")),
        }),
        onSubmit: async (values) => {
            setIsFetching(true);
            const response = await fetch(origin.concat(serverPaths.POST.CREATE_NEW_PASS), {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "login": "HRplus+",
                    "password": "Ctc0oTYayc77z"
                },
                body: JSON.stringify({
                    password: values.password,
                    token: searchParams.get('token')
                })
            })
                .then(resp => resp.json())
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => {
                    setIsFetching(false);
                });

            if (!response.status) {
                toast.error(t('server-error'));

            } else if (!response.user_exist) {
                toast.warning(t('unknown-error'));

            } else {
                toast.success(t('saved-success'));
            }
        }
    });
    return (
        <ParticlesAuth>
            <div className="auth-page-content d-flex flex-column align-items-center justify-content-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                <CardBody className="p-3">
                                    <div className="text-center mb-1 text-white-50">
                                        <Link to="/dashboard" className="d-inline-block auth-logo">
                                            <img src={logoDark} alt="" height="55"/>
                                        </Link>
                                    </div>
                                    <div className="text-center mt-3">
                                        <h5 className="text-primary">{t('create-new-pass')}</h5>
                                    </div>

                                    <div className="p-2">
                                        <Form onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password-input">
                                                    {t('password')}
                                                </Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type={passwordShow ? "text" : "password"}
                                                        className="form-control pe-5 password-input"
                                                        id="password-input"
                                                        name="password"
                                                        placeholder={t('enter-password')}
                                                        value={validation.values.password}
                                                        onChange={validation.handleChange}
                                                        invalid={!!(validation.errors.password && validation.touched.password)}
                                                    />
                                                    {validation.errors.password && validation.touched.password ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setPasswordShow(!passwordShow)}
                                                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                            type="button"
                                                            id="password-addon"><i
                                                        className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label"
                                                       htmlFor="confirm-password-input">{t('confirm-password')}</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        type={confrimPasswordShow ? "text" : "password"}
                                                        className="form-control pe-5 password-input"
                                                        id="confirm-password-input"
                                                        name="confrimPassword"
                                                        placeholder={t('enter-confirm-password')}
                                                        value={validation.values.confrimPassword}
                                                        onChange={validation.handleChange}
                                                        invalid={!!(validation.errors.confrimPassword && validation.touched.confrimPassword)}
                                                    />
                                                    {validation.errors.confrimPassword && validation.touched.confrimPassword ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.confrimPassword}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link"
                                                            onClick={() => setConfrimPasswordShow(!confrimPasswordShow)}
                                                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                            type="button">
                                                        <i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <button type={"submit"}
                                                        className={"btn btn-success w-100 d-inline-flex align-items-center gap-2"}
                                                        disabled={isFetching}>
                                                    {isFetching && <Spinner type={"grow"} size={"sm"}/>} {t('save')}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p className="mb-0">{t('remember-password')}...{" "}
                                    <Link to={appPaths.AUTH_SIGNIN}
                                          className="fw-bold text-primary text-decoration-underline">
                                        {t('sign-in')}
                                    </Link>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default CreateNewPassword;