import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

import Jobs from "./job/reducer";
import Common from "./common/reducer";

//API Key
const rootReducer = combineReducers({
    Layout,
    Jobs,
    Common
});

export default rootReducer;