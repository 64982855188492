import {origin} from "../config";
import {getCookie} from "./myFunctions";
import {serverPaths} from "../constants/appPaths";

export function fetchZones(type, soato = '17') {
    return fetch(origin.concat(serverPaths.GET.ZONES_DATA(type, soato)), {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            'login': 'HRplus+',
            'password': 'Ctc0oTYayc77z'
        }
    })
        .then(resp => resp.json())
        .catch(err => {
            console.log(err);
            return {};
        });
}

export function fetchCurrenciesRates() {
    return fetch(origin.concat(serverPaths.GET.CURRENCY_RATES), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            userId: getCookie("userId")
        }
    })
        .then(resp => resp.json())
        .catch(err => {
            console.log(err)
            return {};
        });
}

export function fetchMostSearchedKeys(count) {
    return fetch(origin.concat(serverPaths.GET.MOST_SEARCHED_WORDS).concat('?count=', count), {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            'login': 'HRplus+',
            'password': 'Ctc0oTYayc77z'
        }
    }).then(resp => resp.json());
}