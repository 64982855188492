import {Col, FormFeedback, Input, Label, Row, Spinner} from "reactstrap";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {SelectCreatableValues} from "../../../Components/Selects";

const Additionals = ({t, toggleTab, activeTab, handleCreateNewResume}) => {

    const [isFetching, setIsFetching] = React.useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            hobbies: [],
            resumeName: ""
        },
        validationSchema: Yup.object().shape({
            hobbies: Yup.array(),
            resumeName: Yup.string().required(t('field-required')),
        }),
        onSubmit: (values) => {
            setIsFetching(true);
            handleCreateNewResume({
                ...values,
                hobbies: values.hobbies.map(item => item.value)
            })
                .then((result) => {
                    if (result)
                        toggleTab(activeTab + 1, 100);
                })
                .finally(() => {
                    setIsFetching(false);
                });
        }
    });

    return (
        <form onSubmit={validation.handleSubmit}>
            <Row className="mt-3">
                <Col xs={12}>
                    <Label htmlFor="hobbies" className="form-label">
                        {t('hobbies')}
                    </Label>
                    <SelectCreatableValues
                        id={"hobbies"}
                        name={"hobbies"}
                        value={validation.values.hobbies}
                        handleChange={val => validation.setFieldValue("hobbies", val)}
                        placeholder={t('type-and-press-enter')}
                        isInvalid={Boolean(validation.errors.hobbies && validation.touched.hobbies)}
                        error={validation.errors.hobbies}
                    />
                </Col>
                <Col lg={6} className="mt-3">
                    <Label htmlFor="resumeName" className="form-label">
                        <span>{t('resume-name')}</span>
                        <span className='text-danger'>{" "}*</span>
                    </Label>
                    <Input
                        type="text"
                        className="form-control"
                        id="resumeName"
                        name="resumeName"
                        placeholder={t('enter-resume-name')}
                        value={validation.values.resumeName}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.resumeName && validation.touched.resumeName)}
                    />
                    {validation.errors.resumeName && validation.touched.resumeName ? (
                        <FormFeedback type="invalid">{validation.errors.resumeName}</FormFeedback>
                    ) : null}
                </Col>
            </Row>
            <div className="d-flex align-items-start gap-3 mt-4">
                <button
                    type="button"
                    className="btn btn-link text-decoration-none btn-label previestab"
                    onClick={() => {
                        toggleTab(activeTab - 1, 75);
                    }}
                >
                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                    {t('back')}
                </button>
                <button
                    type="submit"
                    className="btn btn-success ms-auto"
                    disabled={isFetching}
                >
                    {isFetching && <Spinner size={"sm"} className={"me-1"} style={{marginBottom: "-2px"}}/>}
                    {t('create-resume')}
                </button>
            </div>
        </form>
    );
}

export default Additionals;