import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ByStateEnum, CurrenciesEnum, ForDurationEnum} from "./constants/jobs";
import {changeAllJobsFilters} from "../store/job/action";
import SimpleBar from "simplebar-react";
import _ from "lodash";
import cyrillicToLatin from "cyrillic-to-latin";
import {ButtonText} from "./Buttons";
import {getCookie} from "../helpers/myFunctions";
import {toast} from "react-toastify";
import {origin} from "../config";
import {appPaths, serverPaths} from "../constants/appPaths";

export const SearchForDuration = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {allJobsFilters} = useSelector(state => state.Jobs);

    const setSearchForDuration = (value) => {
        dispatch(changeAllJobsFilters({
            forDuration: value
        }));
    }

    return (
        <UncontrolledDropdown>
            <DropdownToggle tag="button"
                            className="btn btn-outline-secondary">
                {t(allJobsFilters.forDuration)} <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdownmenu-secondary">
                {Object.entries(ForDurationEnum).map(([k, v], index) =>
                    <DropdownItem
                        key={index}
                        onClick={e => {
                            setSearchForDuration(v);
                        }}
                        active={v === allJobsFilters.forDuration}
                    >{t(v)}</DropdownItem>)}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}
export const SearchByState = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {allJobsFilters} = useSelector(state => state.Jobs);

    const setByState = (value) => {
        dispatch(changeAllJobsFilters({
            byState: value
        }));
    }

    return (
        <UncontrolledDropdown>
            <DropdownToggle tag="button"
                            className="btn btn-outline-secondary">
                {t(allJobsFilters.byState)} <i className="mdi mdi-sort align-bottom"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdownmenu-secondary">
                {Object.values(ByStateEnum).map((v, index) =>
                    <DropdownItem
                        key={index}
                        onClick={e => {
                            setByState(v);
                        }}
                        active={v === allJobsFilters.byState}
                    >{t(v)}</DropdownItem>)}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export const CurrencySelect = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {allJobsFilters} = useSelector(state => state.Jobs);

    const changeCurrency = (value) => {
        dispatch(changeAllJobsFilters({
            salaryCurrency: value
        }));
    }

    return (
        <UncontrolledDropdown>
            <DropdownToggle tag="button"
                            className="btn p-0 text-dark">
                {t(allJobsFilters.salaryCurrency)} <i className="mdi mdi-chevron-down align-bottom"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdownmenu-secondary" end={true}>
                {Object.values(CurrenciesEnum).map((v, index) =>
                    <DropdownItem
                        key={index}
                        onClick={e => {
                            changeCurrency(v);
                        }}
                        active={v === allJobsFilters.salaryCurrency}
                    >
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <span className={"d-block"}>
                                {t(v)}
                            </span>
                            <span className={"d-block"}>
                                 {
                                     index === 2 ? <i className={"bx bx-euro"}></i> :
                                         index === 1 ? <i className={"bx bx-dollar"}></i> : ''
                                 }
                            </span>
                        </div>
                    </DropdownItem>)}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}


export const CheckboxesListWithGrouping = ({
                                               defaultExpended = false,
                                               labelKey = 'name',
                                               dataArr,
                                               maxHeight = 250,
                                               minimizedNumber = 5,
                                               storeKey = 'organizations'
                                           }) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {allJobsFilters} = useSelector(state => state.Jobs);

    const [isExpended, setIsExpended] = useState(defaultExpended);
    const [search, setSearch] = useState('');

    const toggle = () => {
        setIsExpended(state => !state);
    }

    const handleChange = (e) => {
        const id = +e.target.value;

        const dataToUpdate = {};
        if (e.target.checked) {
            dataToUpdate[storeKey] = [...allJobsFilters[storeKey], id];

        } else {
            dataToUpdate[storeKey] = [...allJobsFilters[storeKey]].filter(item => item !== id);
        }

        dispatch(changeAllJobsFilters(dataToUpdate));
    }

    return (
        <React.Fragment>
            {
                isExpended ?
                    <React.Fragment>
                        <Input className={"mb-2"}
                               placeholder={t('search-organizations')}
                               value={search}
                               onChange={e => setSearch(e.target.value)}
                        />
                        <SimpleBar style={{maxHeight: maxHeight}} autoHide={false}>
                            {
                                Object.entries(_.groupBy(
                                    dataArr.filter(item =>
                                        cyrillicToLatin(item[labelKey].toLowerCase()).includes(search.toLowerCase())),
                                    item => item[labelKey][0])
                                ).map(([k, v], index) =>
                                    <React.Fragment key={index}>
                                        <h5 className={"text-muted"}>{k}</h5>
                                        {v.map((item, idx) =>
                                            <div key={idx}
                                                 className="form-check form-check-primary mb-2">
                                                <Input className="form-check-input"
                                                       type="checkbox"
                                                       value={item.id}
                                                       id={storeKey + item.id}
                                                       checked={allJobsFilters[storeKey].includes(item.id)}
                                                       onChange={handleChange}
                                                />
                                                <Label className="form-check-label text-capitalize"
                                                       for={storeKey + item.id}>
                                                    {item[labelKey].toLowerCase()}
                                                </Label>
                                            </div>)}
                                    </React.Fragment>)}
                        </SimpleBar>
                    </React.Fragment> :
                    dataArr
                        // .sort(item => allJobsFilters.organizations.includes(item.id) ? -1 : 1)
                        .slice(0, minimizedNumber)
                        .map((item, index) => (
                                <div key={index}
                                     className={"form-check form-check-primary " + (index !== (minimizedNumber - 1) ? 'mb-2' : 'mb-0')}>
                                    <Input className="form-check-input"
                                           type="checkbox"
                                           value={item.id}
                                           id={storeKey + item.id}
                                           checked={allJobsFilters[storeKey].includes(item.id)}
                                           onChange={handleChange}
                                    />
                                    <Label className="form-check-label text-capitalize"
                                           for={storeKey + item.id}>
                                        {item[labelKey].toLowerCase()}
                                    </Label>
                                </div>
                            )
                        )
            }
            {
                dataArr.length > minimizedNumber &&
                <ButtonText text={t(isExpended ? 'minimise' : 'show-all')}
                            classes={"mt-2 fs-15"}
                            handler={toggle}
                />
            }
        </React.Fragment>
    );
}

export const HideViewComponent = ({id, t, orgId, withTooltip = true}) => {
    const [isHidden, setIsHidden] = useState(false);

    const hideJob = (isOneJob = true) => {
        fetch(origin.concat(serverPaths.POST.USER_INTERACTIONS), {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                userId: getCookie('userId')
            },
            body: JSON.stringify({
                id,
                isOneJob,
                orgId,
                type: "hidden"
            })
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp.status) {
                    setIsHidden(true);

                } else {
                    toast.error(t('server-error'));
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            });
    }

    const handleRestore = () => {
        fetch(origin.concat(serverPaths.DELETE.USER_INTERACTIONS), {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                userId: getCookie('userId')
            },
            body: JSON.stringify({
                id,
                isOneJob: true,
                orgId,
                type: "hidden"
            })
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp.status) {
                    setIsHidden(false);

                } else {
                    toast.error(t('server-error'));
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            });
    }

    return (
        isHidden ?
            <React.Fragment>
                <button className={"btn btn-ghost-primary btn-icon btn-sm"}
                        id={"hide_vacant_" + id}
                        onClick={handleRestore}>
                    <i className={`las la-eye fs-20`}></i>
                </button>
                <UncontrolledTooltip placement="top"
                                     target={"hide_vacant_" + id}>{t('restore-job')}</UncontrolledTooltip>
            </React.Fragment> :
            <UncontrolledDropdown>
                <DropdownToggle
                    tag={"button"}
                    className={"btn btn-ghost-primary btn-icon btn-sm"}
                    id={"hide_vacant_" + id}
                >
                    <i className={`las la-eye-slash fs-20`}></i>
                </DropdownToggle>
                {withTooltip &&
                    <UncontrolledTooltip placement="top"
                                         target={"hide_vacant_" + id}>{t('hide')}</UncontrolledTooltip>}
                <DropdownMenu color={"primary"} end={true}>
                    <DropdownItem onClick={e => {
                        hideJob();
                    }}>
                        {t('hide-this-job')}
                    </DropdownItem>
                    <DropdownItem onClick={e => {
                        hideJob(false);
                    }}>
                        {t('hide-all-jobs-comp')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
    );
}

export const SaveJob = ({id, t, saved, withToolTip = true}) => {
    const [isSaved, setIsSaved] = useState(saved);

    const SaveJob = () => {
        fetch(origin.concat(serverPaths.POST.USER_INTERACTIONS), {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                userId: getCookie('userId')
            },
            body: JSON.stringify({
                id,
                isOneJob: true,
                type: "saved"
            })
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp.status) {
                    setIsSaved(true);

                } else {
                    toast.error(t('server-error'));
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            });
    }

    const handleRestore = () => {
        fetch(origin.concat(serverPaths.DELETE.USER_INTERACTIONS), {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                userId: getCookie('userId')
            },
            body: JSON.stringify({
                id,
                isOneJob: true,
                type: "saved"
            })
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp.status) {
                    setIsSaved(false);

                } else {
                    toast.error(t('server-error'));
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            });
    }

    return (
        <React.Fragment>
            <button
                type="button"
                className="btn btn-icon btn-ghost-danger btn-icon btn-sm"
                data-bs-toggle="button"
                aria-pressed="true"
                onClick={e => {
                    isSaved ? handleRestore() : SaveJob();
                }}
            >
                <i className={`mdi mdi-cards-heart${isSaved ? '' : '-outline'} fs-17`}
                   style={{marginBottom: "-2px"}}></i>
            </button>
        </React.Fragment>
    );
}