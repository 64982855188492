import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Features from "./Features";
import {useTranslation} from "react-i18next";
import {appName} from "../../config";

const Process = () => {

  const {t} = useTranslation();

  return (
    <React.Fragment>
      <section className="section py-3 py-md-5" id="process">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-3 mb-md-5">
                <h1 className="mb-2 ff-secondary fw-bold lh-base">
                  {t('process-header')}
                </h1>
                <p className="text-muted">
                  {t('process-info')}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6}>
              <Card className="card shadow-lg">
                <CardBody className="p-4">
                  <h1 className="fw-bold display-5 ff-secondary mb-4 text-success position-relative">
                    <div className="job-icon-effect"></div>
                    <span>1</span>
                  </h1>
                  <h6 className="fs-18 mb-2">{t('process-card-header1')}</h6>
                  <p className="text-muted mb-0 fs-17">{t('process-card-info1')}</p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6}>
              <Card className="card shadow-lg card-border-effect-none border-0">
                <CardBody className="p-4">
                  <h1 className="fw-bold display-5 ff-secondary mb-4 text-success position-relative">
                    <div className="job-icon-effect"></div>
                    <span>2</span>
                  </h1>
                  <h6 className="fs-18 mb-2">{t('process-card-header2')}</h6>
                  <p className="text-muted mb-0 fs-17">{t('process-card-info2')}</p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6}>
              <Card className="card shadow-lg card-border-effect-none border-0">
                <CardBody className="p-4">
                  <h1 className="fw-bold display-5 ff-secondary mb-4 text-success position-relative">
                    <div className="job-icon-effect"></div>
                    <span>3</span>
                  </h1>
                  <h6 className="fs-18 mb-2">{t('process-card-header3')}</h6>
                  <p className="text-muted mb-0 fs-17">{t('process-card-info3',{appName})}</p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6}>
              <Card className="card shadow-lg card-border-effect-none border-0">
                <CardBody className="p-4">
                  <h1 className="fw-bold display-5 ff-secondary mb-4 text-success position-relative">
                    <div className="job-icon-effect"></div>
                    <span>4</span>
                  </h1>
                  <h6 className="fs-18 mb-2">{t('process-card-header4')}</h6>
                  <p className="text-muted mb-0 fs-17">{t('process-card-info4')}</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/*<Features />*/}
      </section>
    </React.Fragment>
  );
};

export default Process;
