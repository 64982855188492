import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
    Alert,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Form,
    Label,
    Input,
    FormFeedback,
    FormGroup,
    Spinner
} from 'reactstrap';
import ParticlesAuth from '../ParticlesAuth';

//formik
import {useFormik} from 'formik';
import * as Yup from 'yup';
import logoDark from "../../../assets/images/logos/hrplus.png";
import {useTranslation} from "react-i18next";
import PhoneNumberField from "../../../Components/PhoneNumberField";
import {origin} from "../../../config";
import {toast} from "react-toastify";
import {appPaths, serverPaths} from "../../../constants/appPaths";

const PasswordReset = () => {
    document.title = "Reset Password | Hrplus Job";

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isFetching, setIsFetching] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: "",
            phone: "",
            whereCode: "phone",
            countryCode: ""
        },
        validationSchema: Yup.object({
            phone: Yup
                .string()
                .when('whereCode', {
                    is: 'phone',
                    then: schema => schema.required(t('field-required')),
                    otherwise: schema => schema.notRequired()
                }),
            email: Yup
                .string()
                .when('whereCode', {
                    is: 'email',
                    then: schema => schema.email(t('value-invalid')).required(t('field-required')),
                    otherwise: schema => schema.email().notRequired()
                })
        }),
        onSubmit: async (values) => {
            setIsFetching(true);
            const response = await fetch(origin.concat(serverPaths.POST.SEND_RESET_LINK), {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "login": "HRplus+",
                    "password": "Ctc0oTYayc77z"
                },
                body: JSON.stringify(values)
            })
                .then(resp => resp.json())
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => {
                    setIsFetching(false);
                });

            if (!response.user_exist) {
                toast.warning(t('no-user-found', {name: t(values.whereCode).toLowerCase()}));

            } else if (response.status) {
                // toast.success(t('reset-link-sent'));
                window.history.replaceState(null, null, appPaths.AUTH_PASS_RESET_SUCCESS);
                navigate(appPaths.AUTH_PASS_RESET_SUCCESS);

            } else {
                toast.error(t('server-error'));
            }
        }
    });

    return (
        <ParticlesAuth>
            <div className="d-flex flex-column align-items-center justify-content-center auth-page-content">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                <CardBody className="p-3">
                                    <div className="text-center mb-1 text-white-50">
                                        <Link to="/dashboard" className="d-inline-block auth-logo">
                                            <img src={logoDark} alt="" height="55"/>
                                        </Link>
                                    </div>
                                    <div className="text-center mt-3">
                                        <h5 className="text-primary mb-3">{t('forgot-password')}</h5>

                                        <lord-icon
                                            src="https://cdn.lordicon.com/rhvddzym.json"
                                            trigger="loop"
                                            colors="primary:#0ab39c"
                                            className="avatar-xl"
                                            style={{width: "120px", height: "120px"}}>
                                        </lord-icon>
                                    </div>

                                    <Alert className="alert-borderless alert-warning text-center mb-3 mx-2"
                                           role="alert">
                                        {t('reset-pass-info')}
                                    </Alert>
                                    <div className="p-2">
                                        <Form onSubmit={validation.handleSubmit}>
                                            <div className="mb-4">
                                                <div className={"mb-2"}>
                                                    <div className="form-check form-check-inline form-check-success">
                                                        <Input className="form-check-input"
                                                               type="radio"
                                                               name="whereCode"
                                                               id="forPhone"
                                                               value="phone"
                                                               onChange={validation.handleChange}
                                                               checked={validation.values.whereCode === 'phone'}/>
                                                        <Label className="form-check-label"
                                                               htmlFor="forPhone">{t('phone')}</Label>
                                                    </div>
                                                    <div className="form-check form-check-inline form-check-success">
                                                        <Input className="form-check-input"
                                                               type="radio"
                                                               name="whereCode"
                                                               id="forEmail"
                                                               value="email"
                                                               onChange={validation.handleChange}
                                                               checked={validation.values.whereCode === 'email'}/>
                                                        <Label className="form-check-label"
                                                               htmlFor="forEmail">{t('email')}</Label>
                                                    </div>
                                                </div>

                                                {
                                                    validation.values.whereCode === 'phone' ?
                                                        <PhoneNumberField value={validation.values.phone}
                                                                          handler={validation.handleChange}
                                                                          error={validation.errors.phone && validation.touched.phone ? validation.errors.phone : false}
                                                                          name={"phone"}
                                                                          handlerCountryCode={val => {
                                                                              validation.setFieldValue("countryCode", val);
                                                                              validation.setFieldValue("phone", '');
                                                                          }}
                                                                          placeholder={t('enter-phone')}
                                                        /> :
                                                        <FormGroup>
                                                            <Input
                                                                type="email"
                                                                id="email"
                                                                name="email"
                                                                className="form-control"
                                                                placeholder={t('enter-email')}
                                                                value={validation.values.email}
                                                                onChange={validation.handleChange}
                                                                invalid={!!validation.errors.email && validation.touched.email}
                                                            />
                                                            {validation.errors.email && validation.touched.email ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                }

                                            </div>

                                            <div className="mt-4">
                                                <button
                                                    className="btn btn-success w-100 text-capitalize d-flex align-items-center justify-content-center gap-1"
                                                    type="submit"
                                                    disabled={isFetching}>
                                                    <Spinner size={"sm"}
                                                             className={isFetching ? "" : "d-none"}></Spinner>
                                                    {t('send-reset-link')}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-4 text-center">
                                <p className="mb-0">{t('remember-password')}...{" "}
                                    <Link to={appPaths.AUTH_SIGNIN} className="fw-bold text-primary text-decoration-underline">
                                        {t('back')}
                                    </Link>
                                </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default PasswordReset;