import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export const AndvancedSelect = ({
                                    isClearable = true,
                                    isSearchable = true,
                                    name = "",
                                    options,
                                    isMultiSelect = false,
                                    isDisabled = false,
                                    isInvalid = false,
                                    placeholder = "",
                                    id,
                                    error = '',
                                    value,
                                    handleChange
                                }) => {

    return (
        <>
            <Select
                id={id}
                classNamePrefix="select"
                isClearable={isClearable}
                isSearchable={isSearchable}
                name={name}
                value={value}
                options={options}
                placeholder={placeholder}
                isMulti={isMultiSelect}
                isDisabled={isDisabled}
                onChange={handleChange}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        ...(isInvalid ?
                            {
                                borderColor: '#f17171',
                                outline: 'none',
                                boxShadow: "none",
                                '&:hover': {
                                    borderColor: '#f17171',
                                    outline: 'none',
                                }
                            } : {}),
                    }),
                    container: provided => ({
                        ...provided,
                        ...(isInvalid ?
                            {
                                borderColor: '#f17171',
                            } : {})
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: "1001"
                    })
                }}
            />
            {isInvalid ? (
                <div className={"invalid-feedback d-block"}>{error}</div>
            ) : null}
        </>
    );
};


export const SelectCreatableValues = ({
                                    id,
                                    name = "",
                                    isClearable = true,
                                    isDisabled = false,
                                    value,
                                    handleChange,
                                    isInvalid = false,
                                    placeholder = "",
                                    error = ''
                                }) => {

    return (
        <>
            <CreatableSelect
                isMulti
                id={id}
                name={name}
                isClearable={isClearable}
                isDisabled={isDisabled}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        ...(isInvalid ?
                            {
                                borderColor: '#f17171',
                                outline: 'none',
                                boxShadow: "none",
                                '&:hover': {
                                    borderColor: '#f17171',
                                    outline: 'none',
                                }
                            } : {}),
                    }),
                    container: provided => ({
                        ...provided,
                        ...(isInvalid ?
                            {
                                borderColor: '#f17171',
                            } : {})
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: "1001"
                    })
                }}
            />
            {isInvalid ? (
                <div className={"invalid-feedback d-block"}>{error}</div>
            ) : null}
        </>
    );
};

