import Swal from "sweetalert2";
import {t} from "i18next";
import {origin} from "../config";
import {serverPaths} from "../constants/appPaths";
import {getCookie} from "./myFunctions";
import {toast} from "react-toastify";

export const handleDeleteResume = (id, changeIsFetching) => {
    return Swal.fire({
        title: t('you-sure'),
        text: t('cannot-revert'),
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        showConfirmButton: true,
        reverseButtons: true,
        cancelButtonText: '<i class="ri-close-line align-bottom me-1"></i>' + t('cancel'),
        confirmButtonText: t('yes-delete'),
        customClass: {
            cancelButton: "btn btn-ghost-success",
            confirmButton: "btn btn-danger ms-2",
        }
    })
        .then(result => {
            if (result.isConfirmed) {
                changeIsFetching(true);
                return fetch(origin.concat(serverPaths.DELETE.JOB_USER_RESUME(id)), {
                    method: "DELETE",
                    headers: {
                        'Content-Type': 'application/json',
                        userId: getCookie("userId"),
                    }
                })
                    .then(resp => resp.json())
                    .then(jsonData => {
                        if (jsonData.status) {
                            toast.success(t('deleted'));

                        } else {
                            toast.error(t('server-error'));
                        }
                        return jsonData.status;
                    })
                    .catch(err => {
                        console.log(err);
                        toast.error(t('connection-error'));
                        return 'err';
                    })
                    .finally(() => changeIsFetching(false));
            }
        });
}