import React, {useEffect, useState} from 'react';
import {
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Row,
} from 'reactstrap';
import {Link} from 'react-router-dom';

//SimpleBar
import SimpleBar from "simplebar-react";
import {getCookie, timeBetween2Dates} from '../../helpers/myFunctions';
import {origin, url_path} from "../../config";
import bell from "../../assets/images/svg/bell.svg";
import {useTranslation} from "react-i18next";

const NotificationDropdown = () => {
    const {t} = useTranslation();

    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);

    const [notifications, setNotifications] = useState([]);
    const [notifsNumber, setNotifsNumber] = useState(0);

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(state => !state);
    };


    useEffect(() => {
        fetch(url_path + 'get-job-user-notifs?limit=4', {
            method: "GET",
            headers: {
                'content-type': 'application/json',
                userId: +getCookie("userId"),
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                setNotifications(resp['data'].sort((a, b) => b.id - a.id));
                setNotifsNumber(resp['number']);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const deleteNotif = (id) => {
        fetch(origin + '/delete-user-notifications?id=' + id, {
            method: "DELETE",
            headers: {
                'content-type': 'application/json',
                userId: +getCookie("userId"),
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                // if (resp.status) {
                setNotifications(state => {
                    let newState = [...state];
                    newState = newState.filter(val => val.id !== id);
                    return newState;
                });
                setNotifsNumber(state => state - 1);
                setIsNotificationDropdown(false);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown}
                      toggle={toggleNotificationDropdown}
                      className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button"
                                tag="button"
                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    {notifsNumber ? <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                        {notifsNumber} <span className="visually-hidden">unread messages</span>
                    </span> : ''}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg p-0" end={true}>
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white">{t('notifications')}</h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge badge-soft-light fs-13"> 4 New</span>
                                </div>
                            </Row>
                        </div>
                    </div>

                    {
                        notifications.length === 0 ?
                            <SimpleBar style={{height: "300px"}} className="pe-1 pt-5">
                                <div className="w-25 w-sm-50 pt-3 mx-auto">
                                    <img src={bell} className="img-fluid" alt="user-pic"/>
                                </div>
                                <div className="text-center pb-4 mt-2">
                                    <h6 className="fs-18 fw-semibold lh-base">
                                        Hey! You have no any notifications
                                    </h6>
                                </div>
                            </SimpleBar> :
                            <SimpleBar style={{maxHeight: "300px"}} className="pe-1">
                                {notifications.map((notif, index) => notif.type === 3 ? <div key={index}
                                                                                             className="text-reset notification-item d-block dropdown-item position-relative pe-2">
                                        <div className="d-flex">
                                            <div className="avatar-xs me-3">
                                    <span className="avatar-title bg-soft-danger text-danger rounded-circle fs-16">
                                        <i className="ri-close-line"></i>
                                    </span>
                                            </div>
                                            <div className="flex-1">
                                                <Link to={"/vacancies-left-resume?id=" + notif.parent_id}
                                                      className="stretched-link"
                                                      onClick={() => {
                                                          deleteNotif(notif.id);
                                                      }}>
                                                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">Rad qilindi!</h6>
                                                </Link>
                                                <div className="fs-13 text-muted">
                                                    <p className="mb-1">
                                                        Sizning
                                                        <span
                                                            className='text-primary fw-medium text-decoration-underline'> {notif.vacant_name}</span> vakansiyasigaga
                                                        qoldirgan
                                                        <span
                                                            className="text-secondary fw-medium text-decoration-underline"> {notif.resume_name}</span> rezyumingiz
                                                        rad qilindi
                                                    </p>
                                                </div>
                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                            <span><i
                                                className="mdi mdi-clock-outline"></i> {timeBetween2Dates(new Date(notif.created_time))}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div> :
                                    <div key={index}
                                         className="text-reset notification-item d-block dropdown-item position-relative active pe-2">
                                        <div className="d-flex">
                                            <div className="avatar-xs me-3">
                                        <span
                                            className="avatar-title bg-soft-success text-success rounded-circle fs-16">
                                            <i className="ri-calendar-check-line"></i>
                                        </span>
                                            </div>
                                            <div className="flex-1">
                                                <Link to={"/appointments?id=" + notif.data}
                                                      className="stretched-link"
                                                      onClick={() => {
                                                          deleteNotif(notif.id);
                                                      }}>
                                                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">Uchrashuv
                                                        belgilandi!</h6>
                                                </Link>
                                                <div className="fs-13 text-muted">
                                                    <p className="mb-1">
                                                        Sizning
                                                        <span
                                                            className='text-primary fw-medium text-decoration-underline'> {notif.vacant_name}</span> vakansiyasigaga
                                                        qoldirgan
                                                        <span
                                                            className="text-secondary fw-medium text-decoration-underline"> {notif.resume_name}</span> rezyumingiz
                                                        uchun uchrashuv belgilandi.
                                                    </p>
                                                </div>
                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                            <span><i
                                                className="mdi mdi-clock-outline"></i> {timeBetween2Dates(new Date(notif.created_time))}</span>
                                                </p>
                                            </div>
                                            {/* <div>
                                        <Button className="btn btn-ghost-danger btn-icon btn-sm"
                                            onClick={() => { deleteNotif(notif.id); }}>
                                            <i className="ri-delete-bin-line fs-15"></i>
                                        </Button>
                                    </div> */}
                                        </div>
                                    </div>)}
                                {notifsNumber > 3 && <div className="my-3 text-center">
                                    <Link className="btn btn-soft-success waves-effect waves-light"
                                          to={"/notifications"} onClick={() => {
                                        setIsNotificationDropdown(false);
                                    }}>
                                        Hammasini ko'rish <i className="ri-arrow-right-line align-middle"></i>
                                    </Link>
                                </div>}
                            </SimpleBar>
                    }
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;