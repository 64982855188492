import React from "react";
import {Navigate} from "react-router-dom";

import Notifications from "../pages/Notifications/UiCards";

//AuthenticationInner pages
import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn';
import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp';

//Job pages
import JobGrid from "../pages/JobList";
import JobOverview from "../pages/Overview";
import JobLanding from "../pages/Job";
import AboutUs from "../pages/AboutUs";

import Navbar from "../pages/Job/Navbar";
import Index from "../pages/Profile/CreateResume";
import ProposalsOffers from "../pages/ProposalsOffers";
import Appointments from "../pages/Appointments";
import PasswordReset from "../pages/AuthenticationInner/Login/PasswordReset";
import AuthResetSuccess from "../pages/AuthenticationInner/Login/AuthResetSuccess";
import CreateNewPassword from "../pages/AuthenticationInner/Login/CreateNewPassword";
import AllResumes from "../pages/Profile/AllResumes";
import SavedJobs from "../pages/SavedJobs";
import EditResume from "../pages/Profile/EditResume";
import {appPaths} from "../constants/appPaths";

const publicRoutes = [
    //AuthenticationInner pages
    {path: appPaths.AUTH_SIGNIN, component: <BasicSignIn/>},
    {path: appPaths.AUTH_SIGNUP, component: <BasicSignUp/>},
    {path: appPaths.AUTH_PASS_RESET, component: <PasswordReset/>},
    {path: appPaths.AUTH_CREATE_NEW_PASS, component: <CreateNewPassword/>},
    {path: appPaths.AUTH_PASS_RESET_SUCCESS, component: <AuthResetSuccess/>},

    {path: appPaths.DASHBOARD, component: <JobLanding/>},
    {path: appPaths.INDEX, component: <JobLanding/>},

    //User Profile
    {path: appPaths.ABOUT_US, component: <><Navbar/><AboutUs/></>},
    {
        path: "/",
        exact: true,
        component: <Navigate to={appPaths.DASHBOARD}/>,
    },
    {path: "*", component: <Navigate to={appPaths.DASHBOARD}/>},
];

const userProfileRoutes = [
    {path: appPaths.ALL_JOBS, component: <JobGrid/>},
    {path: appPaths.SAVED_JOBS, component: <SavedJobs/>},
    {path: appPaths.INTERVIEWS, component: <JobOverview/>},
    {path: appPaths.PROPOSALS_OFFERS, component: <ProposalsOffers/>},
    {path: appPaths.APPOINTMENTS, component: <Appointments/>},
    {path: appPaths.NOTIFICATIONS, component: <Notifications/>},
    {path: appPaths.ALL_JOBS_DETAILS(':id'), component: <JobOverview/>},
    {path: appPaths.JOBS_DETAILS(':id'), component: <JobOverview/>},
    {path: appPaths.CREATE_RESUME, component: <Index/>},
    {path: appPaths.ALL_RESUMES, component: <AllResumes/>},
    {path: appPaths.ALL_RESUMES_EDIT(":id"), component: <EditResume/>}
]

export {publicRoutes, userProfileRoutes};