import {Accordion, AccordionItem, Collapse} from "reactstrap";
import classnames from "classnames";
import {useState} from "react";

export const AccordionBorder = ({items, color = 'success', gap = 3, fontSize = 16, defaultOpenIndex = null}) => {

    const [activeIndex, setActiveIndex] = useState(defaultOpenIndex);

    const changeActiveIndex = (index) => {
        if (index === activeIndex) {
            setActiveIndex(null);

        } else {
            setActiveIndex(index);
        }
    }

    return (
        <>
            {
                items.map((item, index) => (
                    <Accordion
                        className={`custom-accordionwithicon custom-accordion-border accordion-border-box mb-${gap} accordion-${color}`}
                        key={index}
                        open={index.toString()}
                        toggle={() => changeActiveIndex(index)}
                    >
                        <AccordionItem>
                            <h2 className="accordion-header">
                                <button
                                    className={classnames("accordion-button fs-" + fontSize, {collapsed: index !== activeIndex})}
                                    type="button"
                                    onClick={e => changeActiveIndex(index)} style={{cursor: "pointer"}}>
                                    {item.header}
                                </button>
                            </h2>

                            <Collapse isOpen={index === activeIndex} className="accordion-collapse">
                                <div className="accordion-body">
                                    {item.content}
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                ))
            }
        </>
    );
}