// Import React FilePond
import {FilePond, registerPlugin} from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Import FilePond plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'; // Import file type validation plugin
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import {max_resume_photo_size} from "../config";

// Register the FilePond plugins
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType, // Register the file type validation plugin
    FilePondPluginFileValidateSize //FilePond File Size Validation plugin
);


const FilePondField = (props) => {

    return (
        <FilePond
            {...props}
        />
    );
}

export default FilePondField;