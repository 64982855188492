module.exports = {
    appName: "Hrplus",
    origin: "https://online.hrplus.uz",
    organization: {
        location_coords: [41.3240067, 69.2318321],
        address: "Toshkent shahar, Olmazor tumani, Moljal Chorsu",
        contact_phone: "+998 94 002-09-12",
        contact_email: "raxmatoveldor37@gmail.com",
        contact_telegram_link: "https://t.me/Jack_20010912",
        contact_youtube_link: "#",
        contact_instagram_link: "#",
    },
    max_resume_photo_size: 5, //in mb
    max_resume_work_files_size: 20,
    max_resume_edu_files_size: 20,
    related_jobs_number: 6,
};