import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Input} from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import image2 from "../../assets/images/users/avatar-2.jpg";
import image3 from "../../assets/images/users/avatar-3.jpg";
import image5 from "../../assets/images/users/avatar-5.jpg";

import "./Search.css";

const Index = ({
                   placeholder = "",
                   inputStyles = {},
                   changeHandler,
                   value,
                   searchResults,
                   handleSearch
               }) => {

    const [showOptions, setShowOptions] = useState(false);

    function handleFocus(e) {
        var inputLength = e.target.value.length;
        setShowOptions(inputLength > 0);
    }

    function handleKeyUp(e) {
        var inputLength = e.target.value.length;
        setShowOptions(inputLength > 0);
    }

    function handleOptionsClick() {
        changeHandler('');
        setShowOptions(false);
    }

    useEffect(() => {
        document.body.addEventListener("click", function (e) {
            if (e.target.getAttribute('id') !== "search-input") {
                setShowOptions(false);
            }
        });

    }, []);

    return (
        <div>
            <div className="position-relative">
                <Input type="text"
                       id="search-input"
                       className="form-control ps-5"
                       placeholder={placeholder}
                       style={inputStyles}
                       autoComplete={"off"}
                       value={value}
                       onChange={e => changeHandler(e.target.value)}
                       onKeyUp={handleKeyUp}
                       onFocus={handleFocus}
                />
                <span className=" ri-search-line search-icon text-muted"></span>
                <span
                    className={`mdi mdi-close-circle search-icon text-muted search-close-options${showOptions ? '' : ' d-none'}`}
                    onClick={handleOptionsClick}></span>
            </div>
            <div className={`dropdown-menu w-100 position-absolute${showOptions ? " show" : ''}`}>
                <SimpleBar style={{maxHeight: "320px"}}>
                    {searchResults.map((result, index) => <Link key={index} to={'#'}
                                                                className="dropdown-item notify-item"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    handleSearch(result);
                                                                }}
                    >
                        <i className="bx bx-search align-middle fs-18 text-muted me-2"></i>
                        <span>{result}</span>
                    </Link>)}
                </SimpleBar>
            </div>
        </div>
    );
};

export default Index;