import React, {useEffect, useState} from "react";
import {
    Card, CardBody, Col, Input, Row, Label,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {changeAllJobsFilters} from "../../store/job/action";
import {useTranslation} from "react-i18next";
import {CheckboxesListWithGrouping, CurrencySelect} from "../../Components/AllJobs";
import {_minMaxExperience, _minSalaries} from "../../constants/common";
import {CurrenciesEnum, EducationEnum} from "../../Components/constants/jobs";
import formatNumber from "format-number";
import {fetchZones} from "../../helpers/fetchers";
import i18n from "../../i18n";

const Filters = ({organizations}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {allJobsFilters, currenciesRates} = useSelector(state => state.Jobs);
    const [regions, setRegions] = useState([]);

    const handleChangeWorkTypes = e => {
        const workType = +e.target.value;

        if (e.target.checked) {
            dispatch(changeAllJobsFilters({
                workTypes: [...allJobsFilters.workTypes, workType]
            }));

        } else {
            dispatch(changeAllJobsFilters({
                workTypes: [...allJobsFilters.workTypes].filter(item => item !== workType)
            }));
        }
    }

    const handleChangeSalary = e => {
        const salary = +e.target.value;

        if (e.target.checked) {
            dispatch(changeAllJobsFilters({
                minSalary: salary
            }));
        }
    }

    const handleChangeExperience = e => {
        const experienceIndex = +e.target.value;

        if (e.target.checked) {
            dispatch(changeAllJobsFilters({
                experience: {
                    ...allJobsFilters.experience,
                    min: _minMaxExperience[experienceIndex].min,
                    max: _minMaxExperience[experienceIndex].max
                }
            }));
        }
    }

    useEffect(() => {
        fetchZones(2).then(data => {
            setRegions(data);
        });
    }, []);

    return (
        <React.Fragment>
            <Card className="mb-0">
                <CardBody className="bg-soft-light">
                    <Row className="g-3">
                        <Col xxl={12}>
                            <h5 className={"fw-bold"}>{t('organizations')}</h5>
                            <CheckboxesListWithGrouping
                                dataArr={organizations}
                                labelKey={'name'}
                                storeKey={'organizations'}
                                defaultExpended={false}
                            />
                        </Col>
                        <Col xxl={12}>
                            <h5 className={"fw-bold"}>{t('work-time-type')}</h5>
                            <div className="form-check form-check-primary mb-2">
                                <Input className="form-check-input"
                                       type="checkbox"
                                       value={1}
                                       id={"workType" + 1}
                                       checked={allJobsFilters.workTypes.includes(1)}
                                       onChange={handleChangeWorkTypes}
                                />
                                <Label className="form-check-label text-capitalize"
                                       for={"workType" + 1}>
                                    {t('part-time')}
                                </Label>
                            </div>

                            <div className="form-check form-check-primary mb-2">
                                <Input className="form-check-input"
                                       type="checkbox"
                                       value={2}
                                       id={"workType" + 2}
                                       checked={allJobsFilters.workTypes.includes(2)}
                                       onChange={handleChangeWorkTypes}
                                />
                                <Label className="form-check-label text-capitalize"
                                       for={"workType" + 2}>
                                    {t('full-time')}
                                </Label>
                            </div>

                            <div className="form-check form-check-primary mb-2">
                                <Input className="form-check-input"
                                       type="checkbox"
                                       value={3}
                                       id={"workType" + 3}
                                       checked={allJobsFilters.workTypes.includes(3)}
                                       onChange={handleChangeWorkTypes}
                                />
                                <Label className="form-check-label text-capitalize"
                                       for={"workType" + 3}>
                                    {t('freelancer')}
                                </Label>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className={"d-flex align-items-center justify-content-between mb-2"}>
                                <h5 className={"fw-bold mb-0"}>
                                    {t('minimum-salary')}
                                </h5>
                                <CurrencySelect/>
                            </div>
                            <div className="form-check form-check-primary mb-2">
                                <Input className="form-check-input"
                                       type="radio"
                                       value={0}
                                       id={"minSalary" + 0}
                                       name={"minSalary"}
                                       checked={allJobsFilters.minSalary === 0}
                                       onChange={handleChangeSalary}
                                />
                                <Label className="form-check-label"
                                       for={"minSalary" + 0}>
                                    {t('doesnt-matter')}
                                </Label>
                            </div>
                            {
                                _minSalaries.map((item, index) =>
                                    <div key={index}
                                         className="form-check form-check-primary mb-2">
                                        <Input className="form-check-input"
                                               type="radio"
                                               value={item}
                                               id={"minSalary" + item}
                                               name={"minSalary"}
                                               checked={allJobsFilters.minSalary === item}
                                               onChange={handleChangeSalary}
                                        />
                                        <Label className="form-check-label"
                                               for={"minSalary" + item}>
                                            {
                                                formatNumber({
                                                    integerSeparator: ' '
                                                })(CurrenciesEnum.SOUM === allJobsFilters.salaryCurrency ?
                                                    Math.trunc(item * currenciesRates.UZS / 100) * 100 :
                                                    CurrenciesEnum.EURO === allJobsFilters.salaryCurrency ?
                                                        Math.round(item * currenciesRates.EUR) : item)
                                            }
                                            {" "}
                                            {allJobsFilters.salaryCurrency === CurrenciesEnum.EURO ?
                                                <i className={"bx bx-euro align-middle"}></i> : allJobsFilters.salaryCurrency ===
                                                CurrenciesEnum.DOLLAR ?
                                                    <i className={"bx bx-dollar align-middle"}></i> : t(allJobsFilters.salaryCurrency).toLowerCase()}
                                        </Label>
                                    </div>)
                            }
                        </Col>

                        <Col xs={12}>
                            <h5 className={"fw-bold"}>
                                {t('region')}
                            </h5>
                            <CheckboxesListWithGrouping
                                dataArr={regions}
                                labelKey={'name_' + i18n.language}
                                storeKey={'regions'}
                                defaultExpended={false}
                            />
                        </Col>

                        <Col xs={12}>
                            <h5 className={"fw-bold"}>
                                {t('experience')}
                            </h5>
                            {
                                _minMaxExperience.map((item, index) =>
                                    <div key={index}
                                         className="form-check form-check-primary mb-2">
                                        <Input className="form-check-input"
                                               type="radio"
                                               value={index}
                                               id={"experience" + index}
                                               name={"experience"}
                                               checked={allJobsFilters.experience.min === item.min}
                                               onChange={handleChangeExperience}
                                        />
                                        <Label className="form-check-label"
                                               for={"experience" + index}>
                                            {
                                                item.min && item.max ?
                                                    t('from-to-years', {from: item.min, to: item.max}) :
                                                    item.min === 0 && item.max === 0 ?
                                                        t('no-experience') :
                                                        item.min && !item.max ?
                                                            t('over-years', {from: item.min}) :
                                                            !item.min && item.max ?
                                                                t('below-year') :
                                                                t('doesnt-matter')
                                            }
                                            {" "}
                                        </Label>
                                    </div>)
                            }
                        </Col>

                        <Col xs={12}>
                            <h5 className={"fw-bold"}>
                                {t('education')}
                            </h5>
                            {
                                Object.values(EducationEnum).map((v, index) =>
                                    <div key={index}
                                         className="form-check form-check-primary mb-2">
                                        <Input className="form-check-input"
                                               type="radio"
                                               value={v}
                                               id={"education" + index}
                                               name={"education"}
                                               checked={allJobsFilters.education === v}
                                               onChange={handleChangeSalary}
                                        />
                                        <Label className="form-check-label"
                                               for={"education" + index}>
                                            {t(v)}
                                        </Label>
                                    </div>)
                            }
                        </Col>

                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );

}

export default Filters;