import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {Button, Col, Collapse, FormFeedback, Input, Label, ListGroup, ListGroupItem, Row, Spinner} from "reactstrap";
import {AndvancedSelect} from "../../../../Components/Selects";
import {FieldDateLikeMMYYYY} from "../../../../Components/Fields";
import React, {useState} from "react";
import {resumeSchema} from "../../../../common/validationSchemas";
import {_workTyimeTypeKeys, editResumeSections} from "../../../../constants/common";
import {max_resume_work_files_size, origin} from "../../../../config";
import FilePondField from "../../../../Components/FilePondField";
import {serverPaths} from "../../../../constants/appPaths";
import {getCookie} from "../../../../helpers/myFunctions";
import {toast} from "react-toastify";

const WorkHistory = ({resume, changeResume, toggle}) => {
    const {t} = useTranslation();

    const [isSaving, setIsSaving] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            profession: resume.profession || '',
            workType: resume.work_type ? {
                value: resume.work_type.toString(),
                label: t(_workTyimeTypeKeys[resume.work_type.toString()])
            } : '',
            hasExperience: !!resume?.experience,
            experienceType: resume.experience_type || 'year',
            experience: resume.experience || '',
            workHistory: resume.work_history.length ?
                resume.work_history.map(item => ({
                    id: item.id,
                    companyName: item.org_name,
                    position: item.position,
                    startDate: item.date_enter,
                    endDate: item.date_leave,
                    reasonToQuit: item.reason_to_leave || '',
                    files: JSON.parse(item.files).map(file => ({
                        source: origin.concat('/', file.path),
                        name: file.name,
                        fileType: file.type
                    }))
                })) : [{
                    companyName: "",
                    position: "",
                    startDate: "",
                    endDate: "",
                    reasonToQuit: "",
                    files: []
                }]
        },
        validationSchema: resumeSchema.workHistory(Yup, t),
        onSubmit: async (values) => {
            const workHistory = [];

            for (const item of values.workHistory) {
                const files = await item.files.reduce(async (accum, file) => {
                    const acc = await accum;
                    if (typeof file.source === 'string') {
                        acc.push(file.source.replace(origin + '/', ''))

                    } else {
                        const base64 = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = function (loadEvent) {
                                resolve(loadEvent.target.result);
                            };

                            reader.readAsDataURL(file.file);
                        });

                        acc.push({
                            base64,
                            name: file.filename,
                            size: file.fileSize,
                            type: file.fileType,
                        });
                    }

                    return acc;
                }, Promise.resolve([]));

                workHistory.push({
                    ...item,
                    files
                });
            }

            const data2Send = {
                ...values,
                workHistory,
                workType: values.workType?.value || ''
            }

            setIsSaving(true);
            fetch(origin.concat(serverPaths.PUT.USER_RESUME(resume.id, editResumeSections.WORK_HISTORY)), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    userId: +getCookie("userId")
                },
                body: JSON.stringify(data2Send)
            })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp.status) {
                        toast.success(t('updated'));
                        changeResume({...resume, ...resp.data});
                        toggle();

                    } else {
                        toast.error(t('server-error'));
                    }
                })
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => setIsSaving(false));
        }
    });

    const deleteFields = (index) => {
        validation.setFieldValue('workHistory', [...validation.values.workHistory].filter((item, idx) => idx !== index));
    }

    const addFields = () => {
        validation.setFieldValue('workHistory', [...validation.values.workHistory, {
            companyName: "",
            position: "",
            startDate: "",
            endDate: "",
            reasonToQuit: "",
            files: []
        }]);
    }

    return (
        <form onSubmit={validation.handleSubmit}>
            <Row className={"g-2 g-sm-3"}>
                <Col xs={12}>
                    <Label htmlFor="profession" className="form-label">
                        {t('prof-name')}{" "}
                        <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        id="profession"
                        name="profession"
                        className="form-control"
                        placeholder={t('enter-prof-name')}
                        value={validation.values.profession}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.profession && validation.touched.profession)}
                    />
                    {validation.errors.profession && validation.touched.profession ? (
                        <FormFeedback type="invalid">{validation.errors.profession}</FormFeedback>
                    ) : null}
                </Col>
                <Col xs={12}>
                    <Label htmlFor="workType" className="form-label">
                        {t("work-time-type")}{" "}
                        <span className='text-danger'>*</span>
                    </Label>
                    <AndvancedSelect id={"workType"}
                                     name={"workType"}
                                     placeholder={t('select-work-time-type')}
                                     value={validation.values.workType}
                                     error={validation.errors.workType}
                                     isInvalid={Boolean(validation.errors.workType && validation.touched.workType)}
                                     handleChange={val => validation.setFieldValue("workType", val)}
                                     options={[
                                         {value: "0", label: t('doesnt-matter')},
                                         {value: "1", label: t('part-time')},
                                         {value: "2", label: t('full-time')},
                                         {value: "3", label: t('freelancer')},
                                     ]}
                    />
                    {validation.errors.workType && validation.touched.workType ? (
                        <FormFeedback
                            type="invalid">{validation.errors.workType}</FormFeedback>
                    ) : null}
                </Col>
                <Col lg={12}>
                    <Label className="form-label">
                        {t('do-you-have-exp')}
                    </Label>
                    <div className='d-flex gap-5'>
                        <div className="form-check form-radio-primary mb-0">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="experience"
                                id="experience_1"
                                checked={validation.values.hasExperience}
                                onChange={e => validation.setFieldValue("hasExperience", e.target.checked)}/>
                            <Label className="form-check-label" for="experience_1">
                                {t('yes')}
                            </Label>
                        </div>
                        <div className="form-check form-radio-primary">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="experience"
                                id="experience_0"
                                checked={!validation.values.hasExperience}
                                onChange={e => validation.setFieldValue("hasExperience", !e.target.checked)}
                            />
                            <Label className="form-check-label" for="experience_0">
                                {t('no')}
                            </Label>
                        </div>
                    </div>

                    <Collapse isOpen={validation.values.hasExperience}
                              className="accordion-collapse mt-2 mt-sm-3"
                              id="collapseOne">
                        <div className="accordion-body">
                            <Row className={"g-2 g-sm-3"}>
                                <Col lg={12}>
                                    <Label htmlFor="experience"
                                           className="form-label">
                                        {t('total-experience')}{" "}
                                        <span className='text-danger'>*</span>
                                    </Label>
                                    <div className={"d-flex gap-2 gap-sm-3"}>
                                        <div className={"flex-grow-1"}>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="experience"
                                                name="experience"
                                                placeholder={t('enter-experience')}
                                                value={validation.values.experience}
                                                onChange={validation.handleChange}
                                                invalid={Boolean(validation.errors.experience && validation.touched.experience)}
                                            />
                                            {validation.errors.experience && validation.touched.experience ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.experience}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div>
                                            <div className="btn-group">
                                                <Input type="radio"
                                                       className="btn-check"
                                                       id="exp-year"
                                                       name="experienceType"
                                                       checked={validation.values.experienceType === 'year'}
                                                       onChange={e => {
                                                           if (e.target.checked)
                                                               validation.setFieldValue('experienceType', 'year')
                                                       }}
                                                />
                                                <Label className="btn btn-outline-primary mb-0"
                                                       for="exp-year">{t('year')}</Label>

                                                <Input type="radio"
                                                       className="btn-check"
                                                       id="exp-month"
                                                       name="experienceType"
                                                       checked={validation.values.experienceType === 'month'}
                                                       onChange={e => {
                                                           if (e.target.checked)
                                                               validation.setFieldValue('experienceType', 'month')
                                                       }}
                                                />
                                                <Label className="btn btn-outline-primary mb-0"
                                                       for="exp-month">{t('month')}</Label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div
                                        className='d-flex justify-content-between align-items-center mb-2'>
                                        <h5 className={"fs-17 mb-0"}>{t('previous-workplaces')}</h5>
                                    </div>

                                    <ListGroup>
                                        {
                                            validation.values.workHistory.map((item, index) =>
                                                <ListGroupItem className={"px-0 border-start-0 border-end-0"}
                                                               key={index}>
                                                    <Row className={"g-2 g-sm-3"}>
                                                        <Col xs={12}>
                                                            <Label htmlFor={`workHistory.${index}.companyName`}
                                                                   className="form-label">
                                                                {t('org-name')}
                                                                <span className={"text-danger"}>{" "}*</span>
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id={`workHistory.${index}.companyName`}
                                                                name={`workHistory.${index}.companyName`}
                                                                placeholder={t('enter-org-name')}
                                                                value={validation.values.workHistory[index]?.companyName}
                                                                onChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.companyName`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.companyName`, true);
                                                                }}
                                                                invalid={Boolean(validation.errors.workHistory?.[index]?.companyName && validation.touched.workHistory?.[index]?.companyName)}
                                                            />
                                                            {validation.errors.workHistory?.[index]?.companyName && validation.touched.workHistory?.[index]?.companyName ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.workHistory[index].companyName}</FormFeedback>
                                                            ) : null}
                                                        </Col>

                                                        <Col xs={12}>
                                                            <Label htmlFor={`workHistory.${index}.position`}
                                                                   className="form-label">
                                                                {t('position')}
                                                                <span className={"text-danger"}>{" "}*</span>
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id={`workHistory.${index}.position`}
                                                                name={`workHistory.${index}.position`}
                                                                placeholder={t('enter-position')}
                                                                value={validation.values.workHistory[index]?.position}
                                                                onChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.position`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.position`, true);
                                                                }}
                                                                invalid={Boolean(validation.errors.workHistory?.[index]?.position && validation.touched.workHistory?.[index]?.position)}
                                                            />
                                                            {validation.errors.workHistory?.[index]?.position && validation.touched.workHistory?.[index]?.position ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.workHistory[index].position}</FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Label htmlFor={`workHistory.${index}.startDate`}
                                                                   className="form-label">
                                                                {t('work-start-date')}
                                                                <span className={"text-danger"}>{" "}*</span>
                                                            </Label>
                                                            <FieldDateLikeMMYYYY
                                                                id={`workHistory.${index}.startDate`}
                                                                name={`workHistory.${index}.startDate`}
                                                                placeholder={t('month-year-placeholder')}
                                                                value={validation.values.workHistory[index]?.startDate}
                                                                handleChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.startDate`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.startDate`, true);
                                                                }}
                                                                isInvalid={Boolean(validation.errors.workHistory?.[index]?.startDate && validation.touched.workHistory?.[index]?.startDate)}
                                                                error={validation.errors.workHistory?.[index]?.startDate}
                                                            />

                                                        </Col>

                                                        <Col xs={6}>
                                                            <Label htmlFor={`workHistory.${index}.endDate`}
                                                                   className="form-label">
                                                                {t('work-end-date')}
                                                                <span className={"text-danger"}>{" "}*</span>
                                                            </Label>
                                                            <FieldDateLikeMMYYYY
                                                                id={`workHistory.${index}.endDate`}
                                                                name={`workHistory.${index}.endDate`}
                                                                placeholder={t('month-year-placeholder')}
                                                                value={validation.values.workHistory[index]?.endDate}
                                                                handleChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.endDate`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.endDate`, true);
                                                                }}
                                                                isInvalid={Boolean(validation.errors.workHistory?.[index]?.endDate && validation.touched.workHistory?.[index]?.endDate)}
                                                                error={validation.errors.workHistory?.[index]?.endDate}
                                                            />
                                                        </Col>

                                                        <Col xs={12}>
                                                            <Label htmlFor={`workHistory.${index}.reasonToQuit`}
                                                                   className="form-label">
                                                                {t('reason-to-quit')}
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id={`workHistory.${index}.reasonToQuit`}
                                                                name={`workHistory.${index}.reasonToQuit`}
                                                                placeholder={t('enter-reason')}
                                                                value={validation.values.workHistory[index]?.reasonToQuit}
                                                                onChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.reasonToQuit`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.reasonToQuit`, true);
                                                                }}
                                                                invalid={Boolean(validation.errors.workHistory?.[index]?.reasonToQuit && validation.touched.workHistory?.[index]?.reasonToQuit)}
                                                            />
                                                            {validation.errors.workHistory?.[index]?.reasonToQuit && validation.touched.workHistory?.[index]?.reasonToQuit ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.workHistory[index].reasonToQuit}</FormFeedback>
                                                            ) : null}
                                                        </Col>

                                                        <Col xs={12}>
                                                            <Label htmlFor={`workHistory.${index}.files`}
                                                                   className="form-label">{t('documents')}
                                                            </Label>
                                                            <FilePondField
                                                                name={`workHistory.${index}.files`}
                                                                files={item.files}
                                                                onupdatefiles={val => validation.setFieldValue(`workHistory.${index}.files`, val)}
                                                                allowMultiple={true}
                                                                acceptedFileTypes={["image/*", "application/doc",
                                                                    "application/docx", "application/msword", "application/pdf"]}
                                                                fileValidateTypeDetectType={(source, type) =>
                                                                    new Promise((resolve, reject) => {
                                                                        resolve(type);
                                                                    })
                                                                }
                                                                className="filepond filepond-input-multiple"
                                                                labelIdle={t('drop-browse-file')}

                                                                // Set maximum file size to 10MB
                                                                maxFileSize={max_resume_work_files_size + "MB"}
                                                                labelMaxFileSizeExceeded={t('file-size-exceeded')} // Error message when file exceeds size
                                                                labelMaxFileSize={t('maximum-file-size', {n: max_resume_work_files_size})} // Show allowed file size limit
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Col xs={12}>
                                                        <Button
                                                            type={"button"}
                                                            className="btn btn-icon btn-sm btn-danger"
                                                            onClick={() => {
                                                                deleteFields(index);
                                                            }}
                                                        >
                                                            <i className="ri-delete-bin-2-line fs-15"></i>
                                                        </Button>
                                                    </Col>
                                                </ListGroupItem>
                                            )}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </Col>
            </Row>
            <div className={"mt-3"}>
                <button type={"submit"}
                        className={"btn btn-success d-inline-flex align-items-center gap-2 me-2"}
                        disabled={isSaving}>
                    {isSaving && <Spinner type={"grow"} size={"sm"}/>} {t('save')}
                </button>
                {
                    validation.values.hasExperience &&
                    <button type={"button"}
                            className={"btn btn-ghost-primary"}
                            onClick={e => addFields()}
                    >
                        <i className={"ri-add-line align-bottom"}></i> {t('add')}
                    </button>
                }
            </div>
        </form>

    );
};

export default WorkHistory