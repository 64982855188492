import * as Yup from "yup";

export const resumeSchema = {
    personalInfo: (Yup, t) => Yup.object().shape({
        fullName: Yup.string().required(t('field-required')),
        gender: Yup.object().required(t("select-required")),
        birthday: Yup.string().length(10, t('value-invalid')),
        maritalStatus: Yup.object().required(t("select-required")),
        nationality: Yup.object().required(t('field-required')),
        citizenship: Yup.object().required(t('field-required')),
        email: Yup.string().email(t('value-invalid'))
    }),
    workHistory: (Yup, t) => Yup.object().shape({
        profession: Yup.string().required(t('field-required')),
        workType: Yup.object().required(t('field-required')),
        hasExperience: Yup.boolean(),
        experience: Yup.string().when('hasExperience', {
            is: true,
            then: scheme => scheme.required(t('field-required'))
        }),
        workHistory: Yup.array().when("hasExperience", {
            is: true,
            then: schema => schema.of(
                Yup.object().shape({
                    companyName: Yup.string().required(t('field-required')),
                    position: Yup.string().required(t('field-required')),
                    startDate: Yup.string().required(t('field-required'))
                        .min(6, t('value-invalid')),
                    endDate: Yup.string().required(t('field-required'))
                        .min(6, t('value-invalid')),
                    reasonToQuit: Yup.string(),
                    files: Yup.array()
                })
            )
        }),
    }),
    education: (Yup, t) => Yup.object().shape({
        isFinishedUniversity: Yup.boolean(),
        education: Yup.array().when("isFinishedUniversity", {
            is: true, then: schema => schema.of(Yup.object().shape({
                universityName: Yup.string().required(t('field-required')),
                speciality: Yup.string().required(t('field-required')),
                startYear: Yup.object().required(t('field-required')),
                endYear: Yup.object().required(t('field-required')),
                level: Yup.object().required(t('field-required')),
                files: Yup.array()
            }))
        }),
    })
};
