import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [iscurrentState, setIscurrentState] = useState('allJobs');
    const [isAllJobs, setIsAllJobs] = useState(false);
    const [isSavedJobs, setIsSavedJobs] = useState(false);
    const [isProposals, setIsProposals] = useState(false);
    const [isInterviews, setIsInterviews] = useState(false);
    const [isCreateResume, setIsCreateResume] = useState(false);
    const [isAllResumes, setIsAllResumes] = useState(false);

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'allJobs') {
            setIsAllJobs(false);
        }

    }, [
        history,
        iscurrentState,
        isAllJobs,
    ]);

    const menuItems = [
        {
            id: "allJobs",
            label: "all-jobs",
            icon: " bx bx-briefcase",
            link: "/all-jobs",
            stateVariables: isAllJobs,
            click: function (e) {
                e.preventDefault();
                setIsAllJobs(!isAllJobs);
                setIscurrentState('allJobs');
                updateIconSidebar(e);
            },
        },
        {
            id: "savedJobs",
            label: "saved-jobs",
            icon: "bx bx-heart",
            link: "/saved-jobs",
            stateVariables: isSavedJobs,
            click: function (e) {
                e.preventDefault();
                setIsSavedJobs(state => !state);
                setIscurrentState('savedJobs');
                updateIconSidebar(e);
            },
        },
        {
            id: "proposals",
            label: "proposals-offers",
            icon: " bx bx-file",
            link: "/proposals-offers",
            stateVariables: isProposals,
            click: function (e) {
                e.preventDefault();
                setIsSavedJobs(state => !state);
                setIscurrentState('proposals');
                updateIconSidebar(e);
            },
        },
        {
            id: "interviews",
            label: "interviews",
            icon: "bx bx-laptop",
            link: "/interviews",
            stateVariables: isInterviews,
            click: function (e) {
                e.preventDefault();
                setIsInterviews(state => !state);
                setIscurrentState('interviews');
                updateIconSidebar(e);
            },
        },
        {
            id: "createResume",
            label: "create-resume",
            icon: "bx bx-edit-alt",
            link: "/create-resume",
            stateVariables: isCreateResume,
            click: function (e) {
                e.preventDefault();
                setIsCreateResume(state => !state);
                setIscurrentState('createResume');
                updateIconSidebar(e);
            },
        },
        {
            id: "allResumes",
            label: "all-resumes",
            icon: "bx bx-edit-alt",
            link: "/all-resumes",
            stateVariables: isAllResumes,
            click: function (e) {
                e.preventDefault();
                setIsAllResumes(state => !state);
                setIscurrentState('isAllResumes');
                updateIconSidebar(e);
            }
        }
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;