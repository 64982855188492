import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    Card,
    Col,
    Container,
    Form,
    Input,
    Row
} from "reactstrap";

import JobProfile2 from "../../assets/images/job-profile2.png";
import {useTranslation} from "react-i18next";
import {_workTyimeTypeKeys} from "../../constants/common";
import {appPaths} from "../../constants/appPaths";
import {fetchMostSearchedKeys} from "../../helpers/fetchers";
import {appName} from "../../config";
import {getCookie} from "../../helpers/myFunctions";
import NavigateLoginModal from "../../Components/NavigateLoginModal";

const Home = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [workType, setWorkType] = useState('');
    const [commonSeaches, setCommonSeaches] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        fetchMostSearchedKeys(5)
            .then(jsonData => {
                // console.log(jsonData)
                setCommonSeaches(jsonData);
            })
            .catch(err => console.log(err));
    }, []);

    const handlerSearch = (e) => {
        setSearch(e.target.value);
    }
    const handlerWorkType = (e) => {
        setWorkType(e.target.value);
    }

    const handlerSubmit = (e) => {
        e.preventDefault();

        if (getCookie('userId')) {
            navigate(appPaths.ALL_JOBS + '?search=' + search + '&workType=' + workType);

        } else {
            setShowModal(true);
        }
    }

    return (
        <React.Fragment>
            <section className="section job-hero-section bg-light pb-0" id="hero">
                <Container>
                    <Row className="justify-content-between align-items-start">
                        <Col lg={6} className="pt-lg-5 mt-lg-5">
                            <div>
                                <h1 className="display-6 fw-medium text-capitalize mb-3 lh-base">
                                    {t('home-header')}
                                </h1>
                                <p className="lead text-muted lh-base mb-4">
                                    {t("home-description")}
                                </p>

                                <Form
                                    action="#"
                                    className="job-panel-filter"
                                    onSubmit={handlerSubmit}
                                >
                                    <Row className="g-md-0 g-2">
                                        <Col className="col-md-4">
                                            <div>
                                                <Input
                                                    type="search"
                                                    id="job-title"
                                                    className="form-control filter-input-box"
                                                    placeholder={t('search-jobs')}
                                                    value={search}
                                                    onChange={handlerSearch}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-md-4">
                                            <select
                                                className="form-control"
                                                value={workType}
                                                onChange={handlerWorkType}
                                            >
                                                <option value="">{t('select-work-time-type')}</option>
                                                <option value="0">{t(_workTyimeTypeKeys['0'])}</option>
                                                <option value="1">{t(_workTyimeTypeKeys['1'])}</option>
                                                <option value="2">{t(_workTyimeTypeKeys['2'])}</option>
                                                <option value="3">{t(_workTyimeTypeKeys['3'])}</option>
                                            </select>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="h-100">
                                                <button
                                                    className="btn btn-primary submit-btn w-100 h-100"
                                                    type="submit"
                                                >
                                                    <i className="ri-search-2-line align-bottom me-1"></i>{" "}
                                                    {t('find-job')}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>

                                <ul className="treding-keywords list-inline mb-0 mt-3 fs-13">
                                    <li className="list-inline-item text-danger fw-semibold">
                                        <i className="mdi mdi-tag-multiple-outline align-middle"></i>{" "}
                                        {t('most-searched-key-words')}:
                                    </li>
                                    {
                                        commonSeaches.map((item, index) =>
                                            <li className="list-inline-item" key={index}>
                                                <Link to={appPaths.ALL_JOBS + '?search=' + item.search}>
                                                    {item.search}
                                                </Link>
                                            </li>)
                                    }
                                </ul>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="position-relative home-img text-center mt-5 mt-lg-0">
                                <Card className="card-bg-fill p-3 rounded shadow-lg inquiry-box">
                                    <div className="d-flex align-items-center">
                                        <div className="avatar-sm flex-shrink-0 me-3">
                                            <div className="avatar-title bg-soft-warning text-warning rounded fs-18">
                                                <i className="ri-mail-send-line"></i>
                                            </div>
                                        </div>
                                        <h5 className="fs-15 lh-base mb-0">
                                            {t("job-request-from-app", {appName: appName})}
                                        </h5>
                                    </div>
                                </Card>
                                <img src={JobProfile2} alt="" className="user-img"/>

                                <div className="circle-effect">
                                    <div className="circle"></div>
                                    <div className="circle2"></div>
                                    <div className="circle3"></div>
                                    <div className="circle4"></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <NavigateLoginModal show={showModal} toggle={toggleModal} t={t}/>
            </section>
        </React.Fragment>
    );
};

export default Home;
