import {Card, CardBody} from "reactstrap";
import {origin} from "../config";
import {Link} from "react-router-dom";
import {HideViewComponent, SaveJob} from "./AllJobs";
import moment from "moment/moment";
import React from "react";
import {appPaths} from "../constants/appPaths";

const JobCard = ({item, t, handleLeftResume}) => {
    return (
        <Card className={"mb-3"}>
            <CardBody className={"p-3"}>
                <div className={"d-flex align-items-center gap-2 mb-3"}>
                    <div className="avatar-sm">
                        <div className="avatar-title bg-light rounded">
                            <img
                                src={origin + '/' + item.image_path}
                                alt=""
                                style={{height: 37, width: 37}}
                            />
                        </div>
                    </div>
                    <div className={"flex-grow-1"}>
                        <Link to={appPaths.ALL_JOBS_DETAILS(item.id)}>
                            <h5 className={"mb-1"}>{item.title}</h5>
                        </Link>
                        <p className={"text-muted mb-0"}>{item.company_name}</p>
                    </div>
                    <div className={"d-inline-flex gap-2"}>
                        <HideViewComponent id={item.id}
                                           orgId={item.org_id}
                                           t={t}/>
                        <SaveJob id={item.id}
                                 saved={!!item.saved_time}
                                 t={t}/>
                    </div>
                </div>
                <div className="d-flex gap-4 mb-3">
                    <div>
                        <i className="ri-map-pin-2-line text-primary me-1 align-bottom"></i>{" "}
                        {item.address}
                    </div>
                    <div>
                        <i className="ri-time-line text-primary me-1 align-bottom"></i>{" "}
                        {moment(item.created_time).format('DD.MM.YYYY, HH:mm:ss')}
                    </div>
                </div>
                <p className="text-muted">{item.description}</p>
                {
                    JSON.parse(item.requirements).length > 0 &&
                    <div>
                                                                    <span
                                                                        className={"me-2"}>#{t('requirements')}:</span>
                        <span className="d-inline-flex flex-wrap gap-2">
                                                            {
                                                                JSON.parse(item.requirements).map((subItem, key) => (
                                                                    <span
                                                                        key={key}
                                                                        className="badge badge-soft-danger fs-12">{subItem}</span>
                                                                ))
                                                            }
                                                        </span>
                    </div>
                }
                {
                    JSON.parse(item.tags).length > 0 &&
                    <div className={"mt-1"}>
                        <span className={"me-2"}>#{t('tags')}:</span>
                        <span className="d-inline-flex flex-wrap gap-2">
                                                            {
                                                                JSON.parse(item.tags).map((subItem, key) => (
                                                                    <span
                                                                        key={key}
                                                                        className="badge badge-soft-secondary fs-12">{subItem}</span>
                                                                ))
                                                            }
                                                        </span>
                    </div>
                }
                <div className="mt-4 hstack gap-2">
                    <Link to="#!"
                          className="btn btn-soft-primary w-100 text-nowrap"
                          onClick={(e) => {
                              handleLeftResume(e, item['id'], item['org_id'])
                          }}>
                        {t('leaving-resume')}
                    </Link>
                    <Link
                        to={appPaths.ALL_JOBS_DETAILS(item.id)}
                        className="btn btn-soft-success w-100"
                    >
                        {t('in-datail')}
                    </Link>
                </div>
            </CardBody>
        </Card>
    );
}

export default JobCard;