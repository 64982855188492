import {CurrenciesEnum} from "../Components/constants/jobs";

export const formatExperience = (experience, t) => {
    const [from, to] = (experience || '').split(',').map(Number);

    if (from === 0 && to === 0) {
        return t('doesnt-matter');

    } else if (from === 0) {
        return t('to-year', {to});

    } else if (to === 0) {
        return t('from-year', {from});

    } else {
        return t('from-to-year', {from, to});
    }
}

export const formatAge = (minAge, maxAge, t) => {
    if (!minAge && !maxAge) {
        return t('doesnt-matter');

    } else if (!minAge && maxAge) {
        return t('to-number', {to: maxAge});

    } else if (minAge && !maxAge) {
        return t('from-number', {from: minAge});

    } else {
        return t('from-to-number', {from: minAge, to: maxAge});
    }
}

export const formatSalary = (minSalary, maxSalary, cashType, t) => {
    const cash = t(
        cashType === 0 ? CurrenciesEnum.SOUM :
            cashType === 1 ? CurrenciesEnum.DOLLAR :
                CurrenciesEnum.EURO
    ).toLowerCase();

    if (minSalary && maxSalary) {
        return t("from-to-number", {
            from: minSalary.toLocaleString() + ' ' + cash,
            to: maxSalary.toLocaleString() + " " + cash
        });

    } else if (!minSalary && maxSalary) {
        return t('to-number', {to: maxSalary.toLocaleString() + ' ' + cash});

    } else if (minSalary && !maxSalary) {
        return t('from-number', {from: minSalary.toLocaleString() + ' ' + cash});

    } else {
        return "-";
    }
}