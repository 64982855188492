import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Scrollspy from "react-scrollspy";
import {
    Collapse,
    Container,
    NavbarToggler,
    NavLink,
} from "reactstrap";
import logodark from "../../assets/images/hrplus.png";
import logolight from "../../assets/images/hrplus.png";
import LightDark from "../../Components/Common/LightDark";
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import {useDispatch, useSelector} from "react-redux";

import {
    changeLayoutMode, setUser,
} from "../../store/actions";
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import {origin} from "../../config";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {getCookie} from "../../helpers/myFunctions";
import {serverPaths} from "../../constants/appPaths";

const Navbar = () => {
    const {t} = useTranslation();

    const [isOpenMenu, setisOpenMenu] = useState(false);
    const [navClass, setnavClass] = useState("");

    const toggle = () => setisOpenMenu(!isOpenMenu);

    const dispatch = useDispatch();
    const {user} = useSelector(state => state.Common);

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    useEffect(() => {
        fetch(origin.concat(serverPaths.GET.JOB_USER_DATA), {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-type": "application/json",
                "userId": +getCookie("userId"),
            }
        }).then(async resp => {
            if (resp.status === 200) {
                const jsonData = await resp.json();
                dispatch(setUser(jsonData.data));
            }
        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
        });
    }, []);

    const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass(" is-sticky");
        } else {
            setnavClass("");
        }
    };
    const {
        layoutModeType,
    } = useSelector(state => ({
        layoutModeType: state.Layout.layoutModeType,
    }));

    useEffect(() => {
        if (
            layoutModeType
        ) {
            dispatch(changeLayoutMode(layoutModeType));
        }
    }, [
        layoutModeType,
        dispatch]);

    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    return (
        <React.Fragment>
            <nav
                className={
                    "navbar navbar-expand-lg navbar-landing fixed-top job-navbar py-0" +
                    navClass
                }
                id="navbar"
            >
                <Container fluid className="app-container container">
                    <Link className="navbar-brand" to="/index">
                        <img src={logodark} className="card-logo card-logo-dark" alt="logo dark" height="50"/>
                        <img src={logolight} className="card-logo card-logo-light" alt="logo light" height="50"/>
                    </Link>
                    <NavbarToggler
                        onClick={toggle}
                        className="navbar-toggler py-0 fs-20 text-body"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse className="navbar-collapse" id="navbarSupportedContent" isOpen={isOpenMenu}>
                        <Scrollspy
                            offset={0}
                            items={[
                                "hero",
                                "process",
                                "categories",
                                "findJob",
                                // "candidates",
                                "blog",
                            ]}
                            currentClassName="active"
                            className="navbar-nav mx-auto mt-2 mt-lg-0 text-uppercase"
                            id="navbar-example"
                        >
                            <li className="nav-item">
                                <NavLink className="fs-16" href="#hero" onClick={() => {
                                    if (isOpenMenu) toggle();
                                }}>
                                    {t('home')}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="fs-16" href="#process" onClick={() => {
                                    if (isOpenMenu) toggle();
                                }}>
                                    {t('process')}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="fs-16" href="#categories" onClick={() => {
                                    if (isOpenMenu) toggle();
                                }}>
                                    {t('sections')}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="fs-16" href="#findJob" onClick={() => {
                                    if (isOpenMenu) toggle();
                                }}>
                                    {t('vacancies')}
                                </NavLink>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <NavLink className="fs-16" href="#blog" onClick={() => {*/}
                            {/*        if (isOpenMenu) toggle();*/}
                            {/*    }}>*/}
                            {/*        {t('blog')}*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                        </Scrollspy>

                        <div className="me-2 mb-n1 ms-n1 ms-g-0">
                            <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />
                        </div>
                        <div className="me-3 ms-n1 ms-g-0">
                            <LanguageDropdown/>
                        </div>
                        {
                            Object.keys(user).length > 0 ?
                                <div className="ms-sm-3 header-item">
                                    <Link className="btn p-0 p-lg-2" to={'/all-jobs'}>
                    <span className="d-flex align-items-center">
                      <img className="rounded-circle header-profile-user border" src={user['image_path'] || avatar1}
                           alt="Header Avatar"/>
                      <span className="text-start ms-xl-2">
                        <span
                            className="d-none d-xl-inline-block ms-1 fw-medium text-dark"
                            style={{maxWidth: 150}}
                        >{user['full_name']}</span>
                          {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
                      </span>
                    </span>
                                    </Link>
                                </div> :
                                <div>
                                    <Link to="/auth-signin" className="btn btn-soft-primary text-nowrap">
                                        <i className="ri-user-3-line align-bottom me-1"></i> {t('login/register')}
                                    </Link>
                                </div>
                        }
                    </Collapse>
                </Container>
            </nav>
        </React.Fragment>
    );
};

export default Navbar;
