import {ListGroup, ListGroupItem, Table} from "reactstrap";
import {Link} from "react-router-dom";
import {RespStatus} from "./components";
import {useTranslation} from "react-i18next";
import {appPaths} from "../../constants/appPaths";
import moment from "moment";

const UserProposals = ({items, isArchive}) => {
    const {t} = useTranslation();

    return (
        <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
                <thead>
                <tr className="text-capitalize">
                    <th scope="col">{t('id')}</th>
                    <th scope="col">{t('status')}</th>
                    <th scope="col">{t("vacancy")}</th>
                    <th scope="col">{t('resume-name')}</th>
                    <th scope="col">{t('created-time')}</th>
                    {isArchive && <th scope="col">{t('reason')}</th>}
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) =>
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>
                            <RespStatus resp={item.resp_status} t={t}/>
                        </td>
                        <td>
                            <Link className={"text-decoration-underline link-primary fs-14"}
                                  to={appPaths.JOBS_DETAILS(item.vacant_id)}>
                                {item.vacant_title}
                            </Link>
                        </td>
                        <td>
                            {item.resume_name}
                        </td>
                        <td>
                            {moment(item.created_time).format('DD.MM.YYYY, HH:mm')}
                        </td>
                        {isArchive && <td className={"text-danger"}>
                            <span className={"d-block"}>{t('vacancy-closed')}</span>
                            <span className={"border-top border-1 border-danger border-top-dashed"}>{item.close_date + ', 23:59'}</span>
                        </td>}
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default UserProposals;