import React from "react";
import {useTranslation} from "react-i18next";

const Additionals = ({additionalInfo, resumeName, handleEdit}) => {
    const {t} = useTranslation();

    return (
        <section className={"mt-2"}>
            <div className={"d-flex align-items-center justify-content-between gap-2 mb-1"}>
                <h4 className={"text-secondary mb-1"}>
                    <i className={"ri-information-line align-bottom"}></i> {t('additioanl-info')}
                </h4>
                <button className={"btn px-0 link-primary text-nowrap"} onClick={handleEdit}>
                    <i className={"ri-pencil-line align-bottom"}></i> {t('edit')}
                </button>
            </div>
            {additionalInfo && <p>{additionalInfo}</p>}
            <div>
                <span className={"fw-semibold me-1"}>{t('resume-name')}:</span>
                <span>{resumeName}</span>
            </div>
        </section>
    )
}

export default Additionals;