export const USER_FULL_NAME = "USER_FULL_NAME";
export const NAVBAR_ACTIVE = "NAVBAR_ACTIVE";

export const USER = "USER";
export const ALL_JOBS_FILTERS = "ALL_JOBS_FILTERS";

export const CURRENCIES_RATES = "CURRENCIES_RATES";

export const NUMBER_OF_JOBS_PER_FILTER = "NUMBER_OF_JOBS_PER_FILTER";

