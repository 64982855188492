import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";

//import images
import logoDark from "../../../assets/images/logos/hrplus.png";
import {useTranslation} from "react-i18next";

const AuthSuccess = () => {
    document.title = "Success Reset Pass | Hrplus Job";

    const {t} = useTranslation();

    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content d-flex flex-column align-items-center justify-content-center">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4 text-center">
                                            <div className="text-center mb-1 text-white-50">
                                                <Link to="/dashboard" className="d-inline-block auth-logo">
                                                    <img src={logoDark} alt="" height="55"/>
                                                </Link>
                                            </div>
                                            <div className="avatar-lg mx-auto mt-3">
                                                <div
                                                    className="avatar-title bg-light text-success display-3 rounded-circle">
                                                    <i className="ri-checkbox-circle-fill"></i>
                                                </div>
                                            </div>
                                            <div className="mt-4 pt-2">
                                                <h5>{t('reset-link-sent')}</h5>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment>
    );
};

export default AuthSuccess;