import {
    Button,
    Col,
    Collapse,
    FormFeedback,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";
import React from "react";
import {AndvancedSelect} from "../../../Components/Selects";
import {useFormik} from "formik";
import * as Yup from "yup";
import {_languages} from "../../../constants/common";
import {FieldDateLikeMMYYYY} from "../../../Components/Fields";
import {readFilesAsBase64} from "../../../helpers/myFunctions";

const WorkingHistory = ({t, toggleTab, activeTab, handleValues}) => {
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            profession: '',
            workType: '',
            hasExperience: false,
            experienceType: 'year',
            experience: '',
            languages: [],
            additionalInfo: "",
            workHistory: [{
                companyName: "",
                position: "",
                startDate: "",
                endDate: "",
                reasonToQuit: "",
                files: []
            }]
        },
        validationSchema: Yup.object().shape({
                profession: Yup.string().required(t('field-required')),
                workType: Yup.object().required(t('field-required')),
                hasExperience: Yup.boolean(),
                experience: Yup.string().when('hasExperience', {
                    is: true,
                    then: scheme => scheme.required(t('field-required'))
                }),
                workHistory: Yup.array().when("hasExperience", {
                    is: true,
                    then: schema => schema.of(
                        Yup.object().shape({
                            companyName: Yup.string().required(t('field-required')),
                            position: Yup.string().required(t('field-required')),
                            startDate: Yup.string().required(t('field-required'))
                                .min(6, t('value-invalid')),
                            endDate: Yup.string().required(t('field-required'))
                                .min(6, t('value-invalid')),
                            reasonToQuit: Yup.string(),
                            files: Yup.array()
                        })
                    )
                }),
            }
        ),
        onSubmit: (values) => {
            toggleTab(activeTab + 1, 50);
            handleValues({
                ...values,
                workType: values.workType?.value || '',
                languages: values.languages.map(item => item.value),
            });
        }
    });

    const deleteFields = (index) => {
        validation.setFieldValue('workHistory', [...validation.values.workHistory].filter((item, idx) => idx !== index));
    }

    const addFields = () => {
        validation.setFieldValue('workHistory', [...validation.values.workHistory, {
            companyName: "",
            position: "",
            startDate: "",
            endDate: "",
            reasonToQuit: "",
            files: []
        }]);
    }

    const handleWorkFiles = async (e, fieldName) => {
        const filesArr = await readFilesAsBase64(e);
        if (filesArr.length)
            await validation.setFieldValue(fieldName, filesArr);
    }

    return (
        <form onSubmit={validation.handleSubmit}>
            <h5 className={"mb-3"}>#{t('working-info')}</h5>
            <Row className={"g-3"}>
                <Col lg={8}>
                    <Label htmlFor="profession" className="form-label">
                        {t('prof-name')}{" "}
                        <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        id="profession"
                        name="profession"
                        className="form-control"
                        placeholder={t('enter-prof-name')}
                        value={validation.values.profession}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.profession && validation.touched.profession)}
                    />
                    {validation.errors.profession && validation.touched.profession ? (
                        <FormFeedback type="invalid">{validation.errors.profession}</FormFeedback>
                    ) : null}
                </Col>
                <Col lg={4}>
                    <Label htmlFor="workType" className="form-label">
                        {t("work-time-type")}{" "}
                        <span className='text-danger'>*</span>
                    </Label>
                    <AndvancedSelect id={"workType"}
                                     name={"workType"}
                                     placeholder={t('select-work-time-type')}
                                     value={validation.values.workType}
                                     error={validation.errors.workType}
                                     isInvalid={Boolean(validation.errors.workType && validation.touched.workType)}
                                     handleChange={val => validation.setFieldValue("workType", val)}
                                     options={[
                                         {value: "0", label: t('doesnt-matter')},
                                         {value: "1", label: t('part-time')},
                                         {value: "2", label: t('full-time')},
                                         {value: "3", label: t('freelancer')},
                                     ]}
                    />
                    {validation.errors.workType && validation.touched.workType ? (
                        <FormFeedback
                            type="invalid">{validation.errors.workType}</FormFeedback>
                    ) : null}
                </Col>
                <Col lg={12}>
                    <Label className="form-label">
                        {t('do-you-have-exp')}
                    </Label>
                    <div className='d-flex gap-5'>
                        <div className="form-check form-radio-primary mb-0">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="experience"
                                id="experience_1"
                                checked={validation.values.hasExperience}
                                onChange={e => validation.setFieldValue("hasExperience", e.target.checked)}/>
                            <Label className="form-check-label" for="experience_1">
                                {t('yes')}
                            </Label>
                        </div>
                        <div className="form-check form-radio-primary">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="experience"
                                id="experience_0"
                                checked={!validation.values.hasExperience}
                                onChange={e => validation.setFieldValue("hasExperience", !e.target.checked)}
                            />
                            <Label className="form-check-label" for="experience_0">
                                {t('no')}
                            </Label>
                        </div>
                    </div>

                    <Collapse isOpen={validation.values.hasExperience}
                              className="accordion-collapse mt-2 mt-sm-3"
                              id="collapseOne">
                        <div className="accordion-body">
                            <Row className={"g-2 g-sm-3"}>
                                <Col lg={8}>
                                    <Label htmlFor="experience"
                                           className="form-label">
                                        {t('total-experience')}{" "}
                                        <span className='text-danger'>*</span>
                                    </Label>
                                    <div className={"d-flex gap-2 gap-sm-3"}>
                                        <div className={"flex-grow-1"}>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="experience"
                                                name="experience"
                                                placeholder={t('enter-experience')}
                                                value={validation.values.experience}
                                                onChange={validation.handleChange}
                                                invalid={Boolean(validation.errors.experience && validation.touched.experience)}
                                            />
                                            {validation.errors.experience && validation.touched.experience ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.experience}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div>
                                            <div className="btn-group">
                                                <Input type="radio"
                                                       className="btn-check"
                                                       id="exp-year"
                                                       name="experienceType"
                                                       checked={validation.values.experienceType === 'year'}
                                                       onChange={e => {
                                                           if (e.target.checked)
                                                               validation.setFieldValue('experienceType', 'year')
                                                       }}
                                                />
                                                <Label className="btn btn-outline-primary mb-0"
                                                       for="exp-year">{t('year')}</Label>

                                                <Input type="radio"
                                                       className="btn-check"
                                                       id="exp-month"
                                                       name="experienceType"
                                                       checked={validation.values.experienceType === 'month'}
                                                       onChange={e => {
                                                           if (e.target.checked)
                                                               validation.setFieldValue('experienceType', 'month')
                                                       }}
                                                />
                                                <Label className="btn btn-outline-primary mb-0"
                                                       for="exp-month">{t('month')}</Label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div
                                        className='d-flex justify-content-between align-items-center mb-2'>
                                        <h5>{t('previous-workplaces')}</h5>
                                        <button type='button'
                                                className="btn btn-info"
                                                onClick={addFields}>
                                            <i className="ri-add-line align-bottom"></i> {t('add')}
                                        </button>
                                    </div>

                                    <ListGroup>
                                        {
                                            validation.values.workHistory.map((item, index) =>
                                                <ListGroupItem key={index}>
                                                    <Row className={"g-2 g-sm-3"}>
                                                        <Col lg={4}>
                                                            <Label htmlFor={`workHistory.${index}.companyName`}
                                                                   className="form-label">
                                                                {t('org-name')}
                                                                <span className={"text-danger"}>{" "}*</span>
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id={`workHistory.${index}.companyName`}
                                                                name={`workHistory.${index}.companyName`}
                                                                placeholder={t('enter-org-name')}
                                                                value={validation.values.workHistory[index]?.companyName}
                                                                onChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.companyName`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.companyName`, true);
                                                                }}
                                                                invalid={Boolean(validation.errors.workHistory?.[index]?.companyName && validation.touched.workHistory?.[index]?.companyName)}
                                                            />
                                                            {validation.errors.workHistory?.[index]?.companyName && validation.touched.workHistory?.[index]?.companyName ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.workHistory[index].companyName}</FormFeedback>
                                                            ) : null}
                                                        </Col>

                                                        <Col lg={4}>
                                                            <Label htmlFor={`workHistory.${index}.position`}
                                                                   className="form-label">
                                                                {t('position')}
                                                                <span className={"text-danger"}>{" "}*</span>
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id={`workHistory.${index}.position`}
                                                                name={`workHistory.${index}.position`}
                                                                placeholder={t('enter-position')}
                                                                value={validation.values.workHistory[index]?.position}
                                                                onChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.position`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.position`, true);
                                                                }}
                                                                invalid={Boolean(validation.errors.workHistory?.[index]?.position && validation.touched.workHistory?.[index]?.position)}
                                                            />
                                                            {validation.errors.workHistory?.[index]?.position && validation.touched.workHistory?.[index]?.position ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.workHistory[index].position}</FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                        <Col lg={2}>
                                                            <Label htmlFor={`workHistory.${index}.startDate`}
                                                                   className="form-label">
                                                                {t('work-start-date')}
                                                                <span className={"text-danger"}>{" "}*</span>
                                                            </Label>
                                                            <FieldDateLikeMMYYYY
                                                                id={`workHistory.${index}.startDate`}
                                                                name={`workHistory.${index}.startDate`}
                                                                placeholder={t('month-year-placeholder')}
                                                                value={validation.values.workHistory[index]?.startDate}
                                                                handleChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.startDate`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.startDate`, true);
                                                                }}
                                                                isInvalid={Boolean(validation.errors.workHistory?.[index]?.startDate && validation.touched.workHistory?.[index]?.startDate)}
                                                                error={validation.errors.workHistory?.[index]?.startDate}
                                                            />

                                                        </Col>

                                                        <Col lg={2}>
                                                            <Label htmlFor={`workHistory.${index}.endDate`}
                                                                   className="form-label">
                                                                {t('work-end-date')}
                                                                <span className={"text-danger"}>{" "}*</span>
                                                            </Label>
                                                            <FieldDateLikeMMYYYY
                                                                id={`workHistory.${index}.endDate`}
                                                                name={`workHistory.${index}.endDate`}
                                                                placeholder={t('month-year-placeholder')}
                                                                value={validation.values.workHistory[index]?.endDate}
                                                                handleChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.endDate`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.endDate`, true);
                                                                }}
                                                                isInvalid={Boolean(validation.errors.workHistory?.[index]?.endDate && validation.touched.workHistory?.[index]?.endDate)}
                                                                error={validation.errors.workHistory?.[index]?.endDate}
                                                            />
                                                        </Col>

                                                        <Col lg={8}>
                                                            <Label htmlFor={`workHistory.${index}.reasonToQuit`}
                                                                   className="form-label">
                                                                {t('reason-to-quit')}
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id={`workHistory.${index}.reasonToQuit`}
                                                                name={`workHistory.${index}.reasonToQuit`}
                                                                placeholder={t('enter-reason')}
                                                                value={validation.values.workHistory[index]?.reasonToQuit}
                                                                onChange={(e) => {
                                                                    validation.setFieldValue(`workHistory.${index}.reasonToQuit`, e.target.value);
                                                                    validation.setFieldTouched(`workHistory.${index}.reasonToQuit`, true);
                                                                }}
                                                                invalid={Boolean(validation.errors.workHistory?.[index]?.reasonToQuit && validation.touched.workHistory?.[index]?.reasonToQuit)}
                                                            />
                                                            {validation.errors.workHistory?.[index]?.reasonToQuit && validation.touched.workHistory?.[index]?.reasonToQuit ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.workHistory[index].reasonToQuit}</FormFeedback>
                                                            ) : null}
                                                        </Col>

                                                        <Col lg={4}>
                                                            <div className="d-flex gap-3">
                                                                <div className='w-100'>
                                                                    <Label htmlFor={`workHistory.${index}.files`}
                                                                           className="form-label">{t('documents')}</Label>
                                                                    <Input
                                                                        type="file"
                                                                        className="form-control"
                                                                        id={`workHistory.${index}.files`}
                                                                        name={`workHistory.${index}.files`}
                                                                        accept=".jpeg,.jpg,.png,.doc,.docx,application/msword,.pdf"
                                                                        multiple
                                                                        onChange={(e) => {
                                                                            handleWorkFiles(e, `workHistory.${index}.files`)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='align-self-end'>
                                                                    <Button
                                                                        type={"button"}
                                                                        className="btn btn-icon btn-sm btn-danger mb-1"
                                                                        onClick={() => {
                                                                            deleteFields(index);
                                                                        }}>
                                                                        <i className="ri-delete-bin-2-line fs-15"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                            )}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </Col>

                <Col lg={12}>
                    <Label htmlFor="languages" className="form-label">
                        {t('languages')}
                    </Label>
                    <AndvancedSelect
                        id={"languages"}
                        name='languages'
                        value={validation.values.languages}
                        isMultiSelect={true}
                        handleChange={val => validation.setFieldValue("languages", val)}
                        placeholder={t('select-languages')}
                        options={Object.entries(_languages).map(([k, v]) => ({value: k, label: v}))}
                        isInvalid={Boolean(validation.errors.languages && validation.touched.languages)}
                        error={validation.errors.languages}
                    />
                </Col>

                <Col lg={12}>
                    <Label htmlFor="additionalInfo" className="form-label">{t('additioanl-info')}</Label>
                    <textarea
                        className="form-control"
                        id="additionalInfo"
                        name="additionalInfo"
                        value={validation.values.additionalInfo}
                        onChange={validation.handleChange}
                        rows="3"
                        placeholder={t('example') + ': ' + t('resume-additional-info')}></textarea>
                </Col>
            </Row>
            <div className="d-flex align-items-start gap-3 mt-4">
                <button
                    type="button"
                    className="btn btn-link text-decoration-none btn-label previestab"
                    onClick={() => {
                        toggleTab(activeTab - 1, 0);
                    }}
                >
                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                    {t('back')}
                </button>
                <button
                    type="submit"
                    className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                    {t('continue')}
                </button>
            </div>
        </form>);
}

export default WorkingHistory;