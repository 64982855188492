import {Badge} from "reactstrap";
import {ProposalsRespEnum} from "../../Components/constants/jobs";

export const RespStatus = ({resp, t}) => {

    return (
        <Badge
            color={
                resp === ProposalsRespEnum.NOT_VIEWED ? "info" :
                    resp === ProposalsRespEnum.VIEWED ? "secondary" :
                        resp === ProposalsRespEnum.ACCEPTED ? "success" :
                            "danger"
            }
            style={{
                fontSize:12
            }}
        >
            {t(resp)}
        </Badge>
    );
}