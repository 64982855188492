import {Button} from "reactstrap";

export const ButtonText = ({
                               text,
                               type = "button",
                               color = 'primary',
                               classes,
                               handler
                           }) => {
    return (
        <button
            className={`btn p-0 text-${color} ` + classes}
            type={type}
            onClick={handler}
        >
            {text}
        </button>
    );
}