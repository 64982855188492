import {USER} from "./actionType";

const INIT_STATE = {
    user: {}
};

const Common = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER:
            return {
                ...state,
                user: action.payload
            }

        default :
            return {...state};
    }
}

export default Common;