import React from 'react';

//import Scss
import './assets/scss/themes.scss';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
//imoprt Route
import Route from './Routes';

import {ToastContainer} from 'react-toastify';
import {useSelector} from "react-redux";


function App() {
    const layoutModeType = useSelector(state => state.Layout.layoutModeType);

    return (
        <React.Fragment>
            <ToastContainer autoClose={2500} theme={layoutModeType}/>
            <Route/>
        </React.Fragment>
    );
}

export default App;
