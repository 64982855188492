import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
} from 'reactstrap';

//import images
import logoDark from "../assets/images/hrplus.png";
import logoLight from "../assets/images/hrplus.png";

//import Components
import LanguageDropdown from '../Components/Common/LanguageDropdown';
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import LightDark from '../Components/Common/LightDark';

import { setUser} from '../store/actions';
import { useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {origin} from "../config";
import {toast} from "react-toastify";
import {getCookie} from "../helpers/myFunctions";
import useWindowSize from "../hooks/windowSize";
import {HeaderNavigations} from "../Components/Navigations";
import {serverPaths} from "../constants/appPaths";

const Header = ({onChangeLayoutMode, layoutModeType, headerClass}) => {
    const windowSize = useWindowSize();

    const [search, setSearch] = useState(false);
    const toogleSearch = () => {
        setSearch(!search);
    };

    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(origin.concat(serverPaths.GET.JOB_USER_DATA), {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-type": "application/json",
                "userId": +getCookie("userId"),
            }
        })
            .then(async resp => {
                if (resp.status === 200) {
                    const jsonData = await resp.json();
                    dispatch(setUser(jsonData.data));
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            });
    }, []);


    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass + ' shadow-lg position-absolute no-print-section'}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <img src={logoDark} alt="" height="50"/>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <img src={logoLight} alt="" height="50"/>
                                </Link>
                            </div>

                            <HeaderNavigations/>
                        </div>

                        <div className="d-flex align-items-center">
                            {/*<SearchOption/>*/}
                            <Dropdown isOpen={search} toggle={toogleSearch}
                                      className="d-md-none topbar-head-dropdown header-item">
                                <DropdownToggle type="button" tag="button"
                                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className="bx bx-search fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..."
                                                       aria-label="Recipient's username"/>
                                                <button className="btn btn-primary" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown>

                            {/* LanguageDropdown */}
                            <LanguageDropdown/>
                            {/* FullScreenDropdown */}
                            {windowSize.width > 570 && <FullScreenDropdown/>}

                            {/* Dark/Light Mode set */}
                            <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />

                            {/* NotificationDropdown */}
                            <NotificationDropdown/>

                            {/* ProfileDropdown */}
                            <ProfileDropdown/>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;