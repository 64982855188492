import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {max_resume_edu_files_size, origin} from "../../../../config";
import {serverPaths} from "../../../../constants/appPaths";
import {editResumeSections} from "../../../../constants/common";
import {getCookie} from "../../../../helpers/myFunctions";
import {toast} from "react-toastify";
import {Button, Col, Collapse, Input, Label, ListGroup, ListGroupItem, Row, Spinner} from "reactstrap";
import {AndvancedSelect} from "../../../../Components/Selects";
import {EducationEnum} from "../../../../Components/constants/jobs";
import {resumeSchema} from "../../../../common/validationSchemas";
import FilePondField from "../../../../Components/FilePondField";

const currYear = new Date().getFullYear();

const FormEducation = ({resume, changeResume, toggle}) => {
    const {t} = useTranslation();

    const [isSaving, setIsSaving] = useState(false);
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            isFinishedUniversity: !!resume.education?.length,
            education: resume.education?.length > 0 ?
                resume.education.map(item => ({
                    id: item.id,
                    universityName: item.uni_name,
                    speciality: item.speciality,
                    startYear: item.year_enter ? {
                        value: item.year_enter.toString(),
                        label: item.year_enter.toString()
                    } : "",
                    endYear: item.year_leave ? {
                        value: item.year_leave.toString(),
                        label: item.year_leave.toString()
                    } : "",
                    level: item.level ? {
                        value: 'edu-' + item.level,
                        label: t('edu-' + item.level)
                    } : "",
                    files: JSON.parse(item.files).map(file => ({
                        source: origin.concat('/', file.path),
                        name: file.name,
                        fileType: file.type
                    }))
                })) : [{
                    universityName: "",
                    speciality: "",
                    startYear: "",
                    endYear: "",
                    level: "",
                    files: []
                }]
        },
        validationSchema: resumeSchema.education(Yup, t),
        onSubmit: async (values) => {
            const education = [];

            for (const item of values.education) {
                const files = await item.files.reduce(async (accum, file) => {
                    const acc = await accum;
                    if (typeof file.source === 'string') {
                        acc.push(file.source.replace(origin + '/', ''))

                    } else {
                        const base64 = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = function (loadEvent) {
                                resolve(loadEvent.target.result);
                            };

                            reader.readAsDataURL(file.file);
                        });

                        acc.push({
                            base64,
                            name: file.filename,
                            size: file.fileSize,
                            type: file.fileType,
                        });
                    }

                    return acc;
                }, Promise.resolve([]));

                education.push({
                    ...item,
                    level: item.level?.value?.split('-')?.pop() || "",
                    startYear: item.startYear?.value || "",
                    endYear: item.endYear?.value || "",
                    files
                });
            }

            setIsSaving(true);
            fetch(origin.concat(serverPaths.PUT.USER_RESUME(resume.id, editResumeSections.EDUCATION)), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    userId: +getCookie("userId")
                },
                body: JSON.stringify({
                    ...values,
                    education
                })
            })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp.status) {
                        toast.success(t('updated'));
                        changeResume({...resume, ...resp.data});
                        toggle();

                    } else {
                        toast.error(t('server-error'));
                    }
                })
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => setIsSaving(false));
        }
    });

    const deleteFields = (index) => {
        const {education} = validation.values;
        education.splice(index, 1); // Remove the item at the specified index
        validation.setFieldValue('education', education);
    };


    const addFields = () => {
        validation.setFieldValue('education', [...validation.values.education, {
            universityName: "",
            speciality: "",
            startYear: "",
            endYear: "",
            level: "",
            files: []
        }]);
    }


    return (
        <form onSubmit={validation.handleSubmit}>
            <Row className={"g-2 g-sm-3"}>
                <Col lg={12}>
                    <Label htmlFor={"isFinishedUniversity_1"} className={"form-label"}>
                        {t('finished-uni')}
                    </Label>
                    <div className='d-flex gap-5 mb-2'>
                        <div className="form-check form-radio-primary mb-0">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="isFinishedUniversity"
                                id="isFinishedUniversity_1"
                                checked={validation.values.isFinishedUniversity}
                                onChange={e => validation.setFieldValue("isFinishedUniversity", e.target.checked)}/>
                            <Label className="form-check-label" for="isFinishedUniversity_1">
                                {t('yes')}
                            </Label>
                        </div>
                        <div className="form-check form-radio-primary">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="isFinishedUniversity"
                                id="isFinishedUniversity_0"
                                checked={!validation.values.isFinishedUniversity}
                                onChange={e => validation.setFieldValue("isFinishedUniversity", !e.target.checked)}
                            />
                            <Label className="form-check-label" for="isFinishedUniversity_0">
                                {t('no')}
                            </Label>
                        </div>
                    </div>
                    <Collapse isOpen={validation.values.isFinishedUniversity}>
                        <ListGroup>
                            {validation.values.education.map((item, index) =>
                                <ListGroupItem className={"px-0 border-start-0 border-end-0"} key={index}>
                                    <Row className={"g-2 g-sm-3"}>
                                        <Col xs={12}>
                                            <Label htmlFor={`education.${index}.universityName`}
                                                   className="form-label">
                                                {t('university-name')} {""}
                                                <span className={"text-danger"}>*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id={`education.${index}.universityName`}
                                                name={`education.${index}.universityName`}
                                                placeholder={t('enter-university-name')}
                                                value={validation.values.education?.[index]?.universityName}
                                                invalid={Boolean(validation.errors.education?.[index]?.universityName && validation.touched.education?.[index]?.universityName)}
                                                onChange={e => validation.setFieldValue(`education.${index}.universityName`, e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Label htmlFor={`education.${index}.speciality`}
                                                   className="form-label">
                                                {t('speciality')} {""}
                                                <span className={"text-danger"}>*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id={`education.${index}.speciality`}
                                                name={`education.${index}.speciality`}
                                                placeholder={t('enter-speciality')}
                                                value={validation.values.education?.[index]?.speciality}
                                                invalid={Boolean(validation.errors.education?.[index]?.speciality && validation.touched.education?.[index]?.speciality)}
                                                onChange={e => validation.setFieldValue(`education.${index}.speciality`, e.target.value)}
                                            />
                                        </Col>

                                        <Col xs={12}>
                                            <Label htmlFor={"level"}>
                                                <span>{t('edu-level')}</span>{" "}
                                                <span className={"text-danger"}>*</span>
                                            </Label>
                                            <AndvancedSelect
                                                id={`education.${index}.level`}
                                                name={`education.${index}.level`}
                                                value={validation.values.education[index]?.level}
                                                handleChange={val => validation.setFieldValue(`education.${index}.level`, val)}
                                                placeholder={t('select-edu-level')}
                                                options={Object.values(EducationEnum).slice(3).map(v => ({
                                                    value: v, label: t(v),
                                                }))}
                                                isInvalid={Boolean(validation.errors.education?.[index]?.level && validation.touched.education?.[index]?.level)}
                                                error={validation.errors.education?.[index]?.level}
                                            />
                                        </Col>

                                        <Col xs={6}>
                                            <label htmlFor={`education.${index}.startYear`}
                                                   className="form-label">
                                                {t('edu-start-year')}
                                                <span className={"text-danger"}>{" "}*</span>
                                            </label>
                                            <AndvancedSelect
                                                id={`education.${index}.startYear`}
                                                name={`education.${index}.startYear`}
                                                value={validation.values.education?.[index]?.startYear}
                                                options={Array(50).fill(0).map((v, i) => ({
                                                    value: currYear - i, label: currYear - i
                                                }))}
                                                placeholder={t("select-year")}
                                                handleChange={val => validation.setFieldValue(`education.${index}.startYear`, val)}
                                                isInvalid={Boolean(validation.errors.education?.[index]?.startYear && validation.touched.education?.[index]?.startYear)}
                                                error={validation.errors.education?.[index]?.startYear}
                                            />
                                        </Col>

                                        <Col xs={6}>
                                            <label htmlFor={`education.${index}.endYear`}
                                                   className="form-label">
                                                {t('edu-end-year')}
                                                <span className={"text-danger"}>{" "}*</span>
                                            </label>
                                            <AndvancedSelect
                                                id={`education.${index}.endYear`}
                                                name={`education.${index}.endYear`}
                                                value={validation.values.education?.[index]?.endYear}
                                                options={Array(56).fill(0).map((v, i) => ({
                                                    value: currYear + 10 - i, label: currYear + 10 - i
                                                }))}
                                                placeholder={t("select-year")}
                                                handleChange={val => validation.setFieldValue(`education.${index}.endYear`, val)}
                                                isInvalid={Boolean(validation.errors.education?.[index]?.endYear && validation.touched.education?.[index]?.endYear)}
                                                error={validation.errors.education?.[index]?.endYear}
                                            />
                                        </Col>

                                        <Col xs={12}>
                                            <Label htmlFor={`education.${index}.files`} className="form-label">
                                                {t('documents')}
                                            </Label>
                                            <FilePondField
                                                name={`education.${index}.files`}
                                                files={item.files}
                                                onupdatefiles={val => validation.setFieldValue(`education.${index}.files`, val)}
                                                allowMultiple={true}
                                                acceptedFileTypes={["image/*", "application/doc",
                                                    "application/docx", "application/msword", "application/pdf"]}
                                                fileValidateTypeDetectType={(source, type) =>
                                                    new Promise((resolve, reject) => {
                                                        resolve(type);
                                                    })
                                                }
                                                className="filepond filepond-input-multiple"
                                                labelIdle={t('drop-browse-file')}

                                                // Set maximum file size to 10MB
                                                maxFileSize={max_resume_edu_files_size + "MB"}
                                                labelMaxFileSizeExceeded={t('file-size-exceeded')} // Error message when file exceeds size
                                                labelMaxFileSize={t('maximum-file-size', {n: max_resume_edu_files_size})} // Show allowed file size limit
                                            />

                                        </Col>
                                    </Row>
                                    <Button
                                        type={"button"}
                                        className="btn btn-icon btn-sm btn-danger"
                                        onClick={() => {
                                            deleteFields(index);
                                        }}
                                    >
                                        <i className="ri-delete-bin-2-line fs-15"></i>
                                    </Button>
                                </ListGroupItem>)}
                        </ListGroup>
                    </Collapse>
                </Col>
            </Row>
            <div className={"mt-3"}>
                <button type={"submit"}
                        className={"btn btn-success d-inline-flex align-items-center gap-2 me-2"}
                        disabled={isSaving}>
                    {isSaving && <Spinner type={"grow"} size={"sm"}/>} {t('save')}
                </button>
                {
                    validation.values.isFinishedUniversity &&
                    <button type={"button"}
                            className={"btn btn-ghost-primary"}
                            onClick={e => addFields()}
                    >
                        <i className={"ri-add-line align-bottom"}></i> {t('add')}
                    </button>
                }
            </div>
        </form>
    );
}

export default FormEducation;