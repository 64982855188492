import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Card, CardBody, Col, Container, Row, FormFeedback, Input, Spinner, Toast, Label} from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";
import "./Custom.css";

//import images 
import logoLight from "../../../assets/images/logos/hrplus.png";

//formik
import {useFormik} from 'formik';
import * as Yup from 'yup';
import "cleave.js/dist/addons/cleave-phone.in";
import {toast} from 'react-toastify';
import {mins2Str, setDataToCookie} from '../../../helpers/myFunctions';
import {origin} from "../../../config";
import PhoneNumberField from "../../../Components/PhoneNumberField";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import SmsCodeField from "../../../Components/SmsCodeField";
import {appPaths, serverPaths} from "../../../constants/appPaths";

const SignUpForm = ({toggleSMSCode, changeId}) => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            fullName: "",
            login: "",
            password: "",
            phone: "",
            email: "",
            whereCode: 'phone',
            countryCode: ""
        },
        validationSchema: Yup.object().shape({
            fullName: Yup
                .string()
                .required(t('field-required')),
            login: Yup
                .string()
                .min(6, t('at-least-n-symbol', {n: 6}))
                .required(t('field-required'))
                .test('unique-login', t('login-taken'), async function (value) {
                    if (!value) return true;
                    try {
                        const response = await fetch(origin.concat(serverPaths.POST.CHECK_LOGIN_EXISTS), {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json",
                                "login": "HRplus+",
                                "password": "Ctc0oTYayc77z"
                            },
                            body: JSON.stringify({
                                login: value
                            })
                        }).then(resp => resp.json());
                        // console.log(response, value);
                        return !response['status'];
                    } catch (error) {
                        console.error(error);
                        return false;
                    }
                }),
            password: Yup
                .string()
                .min(6, t('at-least-n-symbol', {n: 6}))
                .matches(RegExp('(.*[0-9].*)'), t('at-least-number'))
                .required(t('field-required')),
            phone: Yup
                .string()
                .when('whereCode', {
                    is: 'phone',
                    then: schema => schema
                        .required(t('field-required')),
                    otherwise: schema => schema.notRequired()
                }),
            email: Yup
                .string()
                .when('whereCode', {
                    is: 'email',
                    then: schema => schema.email(t('value-invalid')).required(t('field-required')),
                    otherwise: schema => schema.email().notRequired()
                })
        }),
        onSubmit: async (values) => {
            setLoading(true);
            const response = await fetch(origin.concat(serverPaths.POST.SEND_SMS_CODE), {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "login": "HRplus+",
                    "password": "Ctc0oTYayc77z"
                },
                body: JSON.stringify({
                    ...values,
                    lang: i18n.language,
                })
            })
                .then(resp => resp.json())
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });

            // console.log(response);
            if (response['phone_exist'] || response['email_exist']) {
                if (response['phone_exist']) {
                    toast.warning(t('phone-exists'));
                }
                if (response['email_exist']) {
                    toast.warning(t('email-exists'));
                }

            } else if (response['status']) {
                toggleSMSCode(true);
                changeId(response['id']);

            } else {
                toast.error(t('server-error'));
            }
        }
    });

    return (
        <div className="p-2 mt-2">
            <form onSubmit={validation.handleSubmit} className="needs-validation" noValidate>
                <div className="mb-2">
                    <label htmlFor="fullName" className="form-label">
                        {t('fullname')} <span className="text-danger">*</span>
                    </label>
                    <Input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        value={validation.values.fullName}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.fullName && validation.touched.fullName)}
                    />
                    {validation.errors.fullName && validation.touched.fullName ? (
                        <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                    ) : null}
                </div>
                <div className="mb-2">
                    <label htmlFor="login" className="form-label">{t('login')} <span
                        className="text-danger">*</span></label>
                    <Input
                        type="text"
                        className="form-control"
                        id="login"
                        name="login"
                        value={validation.values.login}
                        onChange={validation.handleChange}
                        invalid={Boolean(validation.errors.login && validation.touched.login)}
                    />
                    {validation.errors.login && validation.touched.login ? (
                        <FormFeedback type="invalid">{validation.errors.login}</FormFeedback>
                    ) : null}
                </div>
                <div className="mb-2">
                    <label className="form-label" htmlFor="password-input">{t('password')} <span
                        className="text-danger">*</span></label>
                    <Input
                        type="password"
                        className="form-control pe-5"
                        id="password-input"
                        name="password"
                        value={validation.values.password}
                        onChange={validation.handleChange}
                        invalid={!!validation.errors.password && validation.touched.password}
                    />
                    {validation.errors.password && validation.touched.password ? (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                    ) : null}
                </div>

                <div className="mb-2">
                    <PhoneNumberField value={validation.values.phone}
                                      handler={validation.handleChange}
                                      error={validation.errors.phone && validation.touched.phone ? validation.errors.phone : false}
                                      name={"phone"}
                                      label={t('phone')}
                                      handlerCountryCode={val => {
                                          validation.setFieldValue("countryCode", val);
                                          validation.setFieldValue("phone", '');
                                      }}
                    />
                </div>
                <div className={"mb-2"}>
                    <label className={"form-label"} htmlFor="email">
                        {t('email')}
                    </label>
                    <Input
                        name="email"
                        type="email"
                        className="form-control pe-5"
                        id="email"
                        value={validation.values.email}
                        onChange={validation.handleChange}
                        invalid={!!validation.errors.email && validation.touched.email}
                    />
                    {validation.errors.email && validation.touched.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                </div>
                <div className={"mb-2"}>
                    <Label className="w-100" htmlFor="forPhone">{t("where-code-sent")}</Label>
                    <div className="form-check form-check-inline form-check-success">
                        <Input className="form-check-input"
                               type="radio"
                               name="whereCode"
                               id="forPhone"
                               value="phone"
                               onChange={validation.handleChange}
                               checked={validation.values.whereCode === 'phone'}/>
                        <Label className="form-check-label" htmlFor="forPhone">{t('phone')}</Label>
                    </div>
                    <div className="form-check form-check-inline form-check-success">
                        <Input className="form-check-input"
                               type="radio"
                               name="whereCode"
                               id="forEmail"
                               value="email"
                               onChange={validation.handleChange}
                               checked={validation.values.whereCode === 'email'}/>
                        <Label className="form-check-label" htmlFor="forEmail">{t('email')}</Label>
                    </div>
                </div>
                <div className="mt-4">
                    <button
                        className="btn btn-success w-100 d-inline-flex align-items-center gap-2 justify-content-center"
                        type="submit"
                        disabled={loading}
                    >
                        {loading && <Spinner style={{height: 18, width: 18}}/>} {t('register')}
                    </button>
                </div>
            </form>
        </div>
    );

}

const ConfirmSMSCode = ({
                            id
                        }) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState('1:59');
    const [timerFinished, setTimerFinished] = useState(false);

    const intervalTimerRef = useRef(null);

    useEffect(() => {
        let minutes = 119;
        intervalTimerRef.current = setInterval(() => {
            minutes--;
            setTimer(mins2Str(minutes));

            if (minutes === 0) {
                clearInterval(intervalTimerRef.current);
                setTimerFinished(true);
                deleteSMSCode();
            }
        }, 1000);

        return () => {
            clearInterval(intervalTimerRef.current);
        }

    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: ""
        },
        validationSchema: Yup.object().shape({
            code: Yup
                .string()
                .min(4, t('field-required'))
                .required(t('field-required')),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            const response = await fetch(origin.concat(serverPaths.POST.CONFIRM_SMS_CODE), {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "login": "HRplus+",
                    "password": "Ctc0oTYayc77z"
                },
                body: JSON.stringify({
                    code: +values['code'],
                    id: id
                })
            })
                .then(resp => resp.json())
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });

            // console.log("response in confirm sms code", response, values['code']);
            if (!response['is_code_correct']) {
                toast.error(t('code-incorrect'));

            } else if (!response['status']) {
                toast.error(t('server-error'))

            } else {
                setDataToCookie('userId', id, 30);

                window.history.replaceState(null, null, appPaths.ALL_JOBS);
                navigate(appPaths.ALL_JOBS);
            }
        }
    });

    const deleteSMSCode = async () => {
        await fetch(origin.concat(serverPaths.DELETE.SMS_CODE(id)), {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                "login": "HRplus+",
                "password": "Ctc0oTYayc77z"
            }
        })
            .then(resp => resp.json())
            .catch(err => {
                console.log(err);
            })
    }
    const sendSMSCode = async () => {
        setLoading(true);
        const response = await fetch(origin.concat(serverPaths.GET.SEND_SMS_CODE_AGAIN(id)), {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "login": "HRplus+",
                "password": "Ctc0oTYayc77z"
            }
        })
            .then(resp => resp.json())
            .catch(err => {
                console.log(err);
            });

        // console.log(response);
        if (response['status']) {
            toast.success(t('code-sent'));

            if (intervalTimerRef.current)
                clearInterval(intervalTimerRef.current);
            setLoading(false);
            setTimer('1:59');
            let minutes = 119;
            intervalTimerRef.current = setInterval(() => {
                minutes--;
                setTimer(mins2Str(minutes));

                if (minutes === 0) {
                    clearInterval(intervalTimerRef.current);
                    setTimerFinished(true);
                    deleteSMSCode();
                }
            }, 1000);

            setTimerFinished(false);

        } else {
            toast.error(t('server-error'));
        }
    }

    return (
        <form onSubmit={validation.handleSubmit} className="needs-validation" noValidate>
            <div className={"mt-4"}>
                <h5 className={"mb-3"}>
                    {t('confirm-code')}
                </h5>
                <SmsCodeField
                    error={validation.errors.code && validation.touched.code ? validation.errors.code : false}
                    handler={val => validation.setFieldValue('code', val)}
                />
            </div>

            {
                timerFinished ?
                    <button className='btn btn-link p-0 fs-15' onClick={sendSMSCode}>{t('send-code-again')}</button> :
                    <div className='text-danger fs-15 fw-medium'>
                        <i className="ri-time-line align-bottom"></i> {timer}
                    </div>
            }
            <div className="mt-4">
                <button
                    className="btn btn-success w-100 d-inline-flex align-items-center gap-2 justify-content-center"
                    type="submit"
                    disabled={loading}
                >
                    {loading && <Spinner style={{height: 18, width: 18}}/>} {t('code-confirm')}
                </button>
            </div>
        </form>
    );

}

const BasicSignUp = () => {
    document.title = "Hrplus | Sign up";

    const {t} = useTranslation();
    // STATES
    const [isSMSCode, setIsSMSCode] = useState(false);
    const [id, setId] = useState(0);

    const toggleSMSCode = (val) => {
        setIsSMSCode(val);
    }

    const changeId = (id) => {
        setId(id);
    }

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="d-flex align-items-center justify-content-center auth-page-content">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                {
                                    isSMSCode ?
                                        <Card className="mt-2">
                                            <CardBody className="p-2 p-sm-3">
                                                <div className={"text-center"}>
                                                    <Link to="/" className="d-inline-block auth-logo">
                                                        <img src={logoLight} alt="" height="55"/>
                                                    </Link>
                                                </div>
                                                <ConfirmSMSCode id={id}/>
                                            </CardBody>
                                        </Card> :
                                        <React.Fragment>
                                            <Card className="mt-2">
                                                <CardBody className="p-2 p-sm-3">
                                                    <div className={"text-center"}>
                                                        <Link to="/" className="d-inline-block auth-logo">
                                                            <img src={logoLight} alt="" height="55"/>
                                                        </Link>
                                                        <h5 className="text-primary my-2">{t('create-account')}</h5>
                                                        <p className="text-muted">
                                                            {t('create-account-info')}
                                                        </p>
                                                    </div>
                                                    <SignUpForm toggleSMSCode={toggleSMSCode} changeId={changeId}/>
                                                </CardBody>
                                            </Card>
                                            <div className="mt-3 text-center">
                                                <p className="mb-0">{t('already-sign-up')}
                                                    <Link to={appPaths.AUTH_SIGNIN}
                                                          className="fw-semibold text-primary text-decoration-underline ms-2">
                                                        {t('sign-in')}
                                                    </Link>
                                                </p>
                                            </div>
                                        </React.Fragment>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default BasicSignUp;