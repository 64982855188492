import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import img from "../../assets/images/demos/men.jpg";

const Connect2 = () => {
    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    useEffect(() => {
        toTop();
    }, []);

    return (
        <React.Fragment>
            <section className="section bg-light" id="wallet">
                <Container>
                    <Row className="justify-content-center pt-5">
                        <Col lg={5}>
                            <div className="text-center mb-5">
                                <img src={img} alt="" className="img-fluid" />
                            </div>
                        </Col>
                        <Col lg={7} className='ps-1'>
                            <div className="text-start mb-5">
                                <p>
                                    Biz biznes, HR avtomatlashtirish, tarqatish va ishlab chiqarish uchun bulutli yechimlarni
                                    yaratishda 10 yildan ortiq tajribaga ega ishlab chiquvchilar, biznes tahlillari va integratsiya mutaxassislari jamoasimiz.
                                </p>
                                <p>
                                    Kompaniyaning asosiy mahsuloti Verifix HRM tizimi dunyoning turli mintaqalarida:
                                    O‘zbekistondan Kanadagacha bo‘lgan yirik va o‘rta biznes vakillari tomonidan talabga ega.
                                    Bizning mijozlarimiz orasida xalqaro va mahalliy chakana savdo tarmoqlari, banklar,
                                    aloqa operatorlari, oziq-ovqat va ichimliklar ishlab chiqaruvchilari,
                                    qishloq xo'jaligi klasterlari, farmatsevtika kompaniyalari va boshqalar kiradi.
                                </p>
                                <p>
                                    Bizning SaaS yechimimiz bir qator kompaniyalarda muvaffaqiyatli ishlayotgani va
                                    o'zining ishonchliligi va samaradorligini namoyish etishga muvaffaq bo'lganidan,
                                    HR boshqaruvini oson ta'minlaganidan, xodimlarning vaqtini tejashga va ish haqini
                                    optimallashtirishga yordam berganidan faxrlanamiz. Tajribali ishlab chiquvchilardan
                                    iborat shaxsiy xodimlarimiz, biznes-tahlil va integratsiya jamoasi bizga mijozlar muammolarini
                                    hal qilishda, HRM tizimini ularning har birining o'ziga xos xususiyatlari
                                    va vazifalariga moslashtirishda imkon qadar moslashuvchan bo'lish imkonini beradi.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Connect2;