import React from 'react';
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import {useTranslation} from "react-i18next";

const YandexMaps = ({coords, zoom = 15, height = 200}) => {
    const {i18n} = useTranslation();

    return (
        <YMaps query={{
            lang: i18n.language === 'en' ? "en_US": "ru_RU",
            mode:"release"
        }}>
            <div style={{width: "100%", height: height + "px"}}>
                <Map
                    defaultState={{
                        center: coords, // Moscow coordinates
                        zoom: zoom,
                        type: "yandex#map"
                    }}
                    width="100%"
                    height="100%"
                    options={ {
                        mapAutoFocus:true,
                        nativeFullscreen:true
                    }}
                >
                    <Placemark geometry={coords}/>
                </Map>
            </div>
        </YMaps>
    );
};

export default YandexMaps;
