import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    CardBody, CardFooter,
    Col, Offcanvas, OffcanvasBody, OffcanvasHeader,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
import Filters from "./Filters";
import ModalLeftResume from "./ModalLeftResume";
import {useDispatch, useSelector} from "react-redux";
import {changeIsLoaded} from "../../store/layouts/action";
import { origin} from "../../config";
import {getCookie} from "../../helpers/myFunctions";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import GlobalSearch from "../../Components/GlobalSearch";
import {changeAllJobsFilters, changeCurrenciesRates} from "../../store/job/action";
import { SearchByState, SearchForDuration} from "../../Components/AllJobs";
import {fetchCurrenciesRates} from "../../helpers/fetchers";
import {SeparatedPagination} from "../../Components/Paginations";
import {SkeletonJob} from "../../Components/Skeletons";
import {NoResultsFound} from "../../Components/NoResultsFound";
import useWindowSize from "../../hooks/windowSize";
import JobCard from "../../Components/JobCard";
import {serverPaths} from "../../constants/appPaths";

const JobGrid = () => {
    document.title = "Hrplus | Vacancies";

    // HOOKS
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const windowSize = useWindowSize();

    const params = new URLSearchParams(location.search);

    // STATES
    const {allJobsFilters, numberOfJobsPerPage} = useSelector(state => state.Jobs);
    const [vacancies, setVacancies] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vacantID, setVacantID] = useState(null);
    const [orgID, setOrgID] = useState(null);

    const [searchResults, setSearchResults] = useState([]);
    const [searchText, setSearchText] = useState("");

    const [activePage, setActivePage] = useState(1);

    const [isJobsLoaded, setIsJobLoaded] = useState(true);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    const toggleFilters = () => {
        setIsFiltersOpen(!isFiltersOpen);
    }

    const handleActivePageChange = (num) => {
        setActivePage(num);
    }

    const toggleModal = () => {
        setIsModalOpen(state => !state);
    }

    const handleLeftResume = (event, id, org_id) => {
        event.preventDefault();
        setOrgID(org_id);
        setVacantID(id);

        setIsModalOpen(true);
    }

    useEffect(() => {
        updatePagesUrlFilters();

        fetch(origin.concat(serverPaths.GET.ALL_ORGANIZATIONS), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "userId": getCookie("userId")
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                setOrganizations(resp);
            })
            .catch(e => {
                console.log(e);
                toast.error(t('connection-error'));
            })
            .finally(() => {
                dispatch(changeIsLoaded(true));
            });

        fetchCurrenciesRates().then(rates => dispatch(changeCurrenciesRates(rates)));

    }, []);

    useEffect(() => {
        setIsJobLoaded(false);
        fetch(origin.concat(serverPaths.GET.JOBS_FOR_USER,parseFiltersToPageUrl()), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "userId": getCookie("userId")
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                setVacancies(resp);
            })
            .catch(e => {
                console.log(e);
                toast.error(t('connection-error'));
            })
            .finally(() => {
                setIsJobLoaded(true);
            });

    }, [allJobsFilters]);

    useEffect(() => {
        if (searchText) {
            fetch(origin.concat(serverPaths.GET.RECENT_SEARCHES_LIKE), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "userId": getCookie("userId")
                },
                body: JSON.stringify({
                    text: searchText
                })
            })
                .then(resp => resp.json())
                .then(resp => {
                    setSearchResults(Array.from(new Set(resp.map(item => item.search))));
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [searchText]);

    const handlerSearchChange = (val) => {
        setSearchText(val);
    }

    const handlerSearchClick = (val) => {
        handlerSearchChange(val);
        searchVacancies();
    }

    const searchVacancies = () => {
        dispatch(changeAllJobsFilters({
            search: searchText,
        }));
    }

    const updatePagesUrlFilters = () => {
        const data2Update = {};
        if (params.get('search')) {
            setSearchText(params.get('search'));
            data2Update.search = params.get('search');
        }

        if (params.get('workTypes')) {
            data2Update.workTypes = params.get('workTypes').split(',').map(Number);
        }

        if (params.get('organizations')) {
            data2Update.organizations = params.get('organizations').split(',').map(Number);
        }

        if (params.get('forDuration')) {
            data2Update.forDuration = params.get('forDuration');
        }

        if (params.get('byState')) {
            data2Update.byState = params.get('byState');
        }

        if (params.get('minSalary')) {
            data2Update.minSalary = +params.get('minSalary');
        }

        if (params.get('salaryCurrency')) {
            data2Update.salaryCurrency = params.get('salaryCurrency');
        }

        if (params.get('regions')) {
            data2Update.regions = params.get('regions').split(',').map(Number);
        }

        if (params.get('experience')) {
            const exp = params.get('experience').split(',');
            data2Update.experience = {
                min: exp[0] ? +exp[0] : '',
                max: exp[1] ? +exp[1] : ''
            };
        }

        if (params.get('education')) {
            data2Update.education = params.get('education');
        }

        dispatch(changeAllJobsFilters(data2Update));
    }

    const parseFiltersToPageUrl = () => {
        let urlSearch = '?';
        if (allJobsFilters.search) {
            urlSearch += 'search=' + allJobsFilters.search + '&';
        }

        if (allJobsFilters.workTypes.length > 0) {
            urlSearch += 'workTypes=' + allJobsFilters.workTypes.join(',') + '&';
        }

        if (allJobsFilters.organizations.length > 0) {
            urlSearch += 'organizations=' + allJobsFilters.organizations.join(',') + '&';
        }

        if (allJobsFilters.regions.length > 0) {
            urlSearch += 'regions=' + allJobsFilters.regions.join(',') + '&';
        }

        urlSearch += 'forDuration=' + allJobsFilters.forDuration + '&';
        urlSearch += 'byState=' + allJobsFilters.byState + '&';
        urlSearch += 'minSalary=' + allJobsFilters.minSalary + '&';
        urlSearch += 'salaryCurrency=' + allJobsFilters.salaryCurrency + '&';
        urlSearch += 'experience=' + allJobsFilters.experience.min + ',' + allJobsFilters.experience.max + '&';
        urlSearch += 'education=' + allJobsFilters.education;

        params.set('query', urlSearch);
        navigate(location.pathname + urlSearch, {replace: true}); // Update URL without reloading

        return urlSearch;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row className={"g-3"}>
                        <Col xs={12}>
                            <Card className={"mb-0"}>
                                <CardBody className={"p-2 p-sm-3"}>
                                    <Row>
                                        {
                                            windowSize.width > 992 &&
                                            <Col lg={4}>
                                                <div className={"d-flex align-items-center gap-2"}>
                                                    <SearchForDuration/>
                                                    <SearchByState/>
                                                </div>
                                            </Col>
                                        }
                                        <Col lg={windowSize.width > 992 ? 8 : 12}>
                                            <div className={"d-flex gap-2"}>
                                                <div className={"flex-grow-1 position-relative"}>
                                                    <GlobalSearch
                                                        placeholder={t("search-jobs")}
                                                        changeHandler={handlerSearchChange}
                                                        value={searchText}
                                                        searchResults={searchResults}
                                                        handleSearch={handlerSearchClick}
                                                    />
                                                </div>
                                                <Button color={"secondary"} onClick={searchVacancies}>
                                                    {t('search')}
                                                </Button>
                                                <Button className={"btn btn-success"} id={"save-search-btn"}>
                                                    <i className={"ri-add-fill align-bottom"}></i>
                                                    <span
                                                        className={"d-none d-sm-inline-block"}>{t('save-search')}</span>
                                                </Button>
                                                <UncontrolledTooltip target={"save-search-btn"}
                                                                     placement={"top"}>{t('save-search-info')}</UncontrolledTooltip>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className={"p-2 p-sm-3"}>
                                    <div className={"d-flex gap-2 justify-content-between align-items-center"}>
                                        <div>
                                            <h5 className={"mb-0 d-inline-flex me-2"}>{t('result')}:</h5>
                                            <Badge color={"primary"}>{vacancies.length}</Badge>
                                        </div>
                                        {
                                            windowSize.width <= 992 &&
                                            <button className={"btn btn-primary"} onClick={toggleFilters}>
                                                <i className={"bx bx-filter-alt"}></i>
                                            </button>
                                        }
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        {
                            windowSize.width > 992 ?
                                <Col lg={4}>
                                    <Filters organizations={organizations}/>
                                </Col> :
                                <Offcanvas isOpen={isFiltersOpen} toggle={toggleFilters} direction={"end"}>
                                    <OffcanvasHeader className={"border-bottom py-3 justify-content-between"}
                                                     toggle={toggleFilters}>
                                        {t('all-filters')}
                                    </OffcanvasHeader>
                                    <OffcanvasBody className={"p-0"}>
                                        <div className={"d-flex align-items-center gap-2 p-3 pb-0"}>
                                            <SearchForDuration/>
                                            <SearchByState/>
                                        </div>
                                        <Filters organizations={organizations}/>
                                    </OffcanvasBody>
                                </Offcanvas>
                        }
                        <Col lg={8}>
                            <Row id="job-list" className={"g-0"}>
                                {
                                    !isJobsLoaded ?
                                        <Col xs={12}>
                                            <Card className={'mb-3'}>
                                                <CardBody>
                                                    <SkeletonJob/>
                                                </CardBody>
                                            </Card>

                                            <Card className={'mb-3'}>
                                                <CardBody>
                                                    <SkeletonJob/>
                                                </CardBody>
                                            </Card>

                                            <Card className={'mb-3'}>
                                                <CardBody>
                                                    <SkeletonJob/>
                                                </CardBody>
                                            </Card>
                                        </Col> :
                                        vacancies.length > 0 ?
                                            vacancies
                                                .slice((activePage - 1) * numberOfJobsPerPage, activePage * numberOfJobsPerPage)
                                                .map((item, key) => (
                                                    <Col lg={12} key={key}>
                                                        <JobCard t={t}
                                                                 item={item}
                                                                 handleLeftResume={handleLeftResume}
                                                        />
                                                    </Col>
                                                )) :
                                            <Col xs={12}>
                                                <NoResultsFound/>
                                            </Col>
                                }
                            </Row>

                            {
                                isJobsLoaded && vacancies.length > numberOfJobsPerPage &&
                                <SeparatedPagination itemsPerPage={numberOfJobsPerPage}
                                                     itemsNumber={vacancies.length}
                                                     pageNeighbours={2}
                                                     activePage={activePage}
                                                     onPageChange={handleActivePageChange}
                                />
                            }
                        </Col>
                    </Row>
                </div>
            </div>
            <ModalLeftResume isModalOpen={isModalOpen} toggleModal={toggleModal} org_id={orgID} vacant_id={vacantID}/>
        </React.Fragment>
    );
};

export default JobGrid;

