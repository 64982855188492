import ContentLoader from "react-content-loader";
import {useSelector} from "react-redux";

export const SkeletonJob = () => {
    const {layoutModeType} = useSelector(state => state.Layout);

    return (
        <ContentLoader viewBox="0 0 380 88"
                       backgroundColor={layoutModeType === 'dark' ? '#444' : '#f0f0f0'}
                       foregroundColor={layoutModeType === 'dark' ? '#666' : '#ecebeb'}>
            <rect x="0" y="0" rx="5" ry="4" width="30" height="30"/>
            <rect x="40" y="0" rx="2" ry="2" width="60%" height="13"/>
            <rect x="360" y="0" rx="2" ry="2" width="20" height="13"/>
            <rect x="40" y="20" rx="2" ry="2" width="60%" height="9"/>
            <rect x="0" y="40" rx="2" ry="2" width="100%" height="8"/>
            <rect x="0" y="55" rx="2" ry="2" width="100%" height="8"/>
            <rect x="0" y="70" rx="2" ry="2" width="49%" height="17"/>
            <rect x="51%" y="70" rx="2" ry="2" width="49%" height="17"/>
        </ContentLoader>
    );
}