import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

// Import Content
import { format2ISO, timeBetween2Dates } from '../../helpers/myFunctions';
import { useDispatch } from 'react-redux';
import { setNavbarActive } from '../../store/actions';
import { toast } from 'react-toastify';

const UiCards = () => {
    document.title = "Hrplus | Notifications";

    const dispatch = useDispatch();

    const [notifications, setNotifications] = useState([]);
    const [notifsNumber, setNotifsNumber] = useState(0);

    useEffect(() => {
        fetch('https://online.hrplus.uz/get-job-user-notifs?withAppoint=1&user_id=' + localStorage.getItem('USER_ID'), {
            method: "GET",
            headers: {
                "login": "HRplus+",
                "password": "Ctc0oTYayc77z"
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                console.log(resp);
                setNotifsNumber(resp['number']);
                setNotifications(resp['data'].sort((a, b) => b.id - a.id));
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const deleteNotif = (id) => {
        fetch('https://online.hrplus.uz/delete-user-notifications?id=' + id, {
            method: "DELETE",
            headers: {
                "login": "HRplus+",
                "password": "Ctc0oTYayc77z"
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp.status) {
                    setNotifications(state => {
                        let newState = [...state];
                        newState = newState.filter(val => val.id !== id);
                        return newState;
                    });
                    toast.success('Muvaffaqiyatli o\'chirildi.');

                } else {
                    toast.error('Server Error! Iltimos qayta urinib ko\'ring.');
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <React.Fragment>
            <div className="page-content px-0 px-lg-5 bg-light">
                <Container fluid>
                    <div className="justify-content-between d-flex align-items-center mt-2 mb-3">
                        <h5 className="mb-0 pb-1 text-decoration-underline">Bildirishnomalar</h5>
                        <h5 className="mb-0 pb-1 text-decoration-underline">Jami soni: {notifsNumber}</h5>
                    </div>
                    <Row>
                        {notifications.map((notif, index) => notif.type === 3 ? <Col key={index} lg={6} xxl={4}>
                            <Card>
                                <CardHeader>
                                    <button type="button" className="btn-close float-end fs-11" aria-label="Close"
                                        onClick={() => { deleteNotif(notif.id) }}></button>
                                    <h6 className="card-title mb-0">{"Rezyum rad qilindi"}</h6>
                                </CardHeader>
                                <CardBody>
                                    <div className="d-flex align-items-center mb-1">
                                        {notif.logo && <div className="flex-shrink-0">
                                            <img src={"https://online.hrplus.uz/" + notif.logo} alt=""
                                                className="avatar-sm rounded-circle border" />
                                        </div>}
                                        <div className="flex-grow-1 ms-3">
                                            <p className="card-text mb-0"><span className="fw-medium">{notif.company_name}</span></p>
                                            <p className="mb-0 fw-normal">{notif.vacant_name}</p>
                                        </div>
                                    </div>
                                    <p className='mt-3 mb-1'>Siz qoldirgan rezyum:&nbsp; <span className='text-dark fw-medium fs-16'><i>{notif.resume_name}</i></span></p>
                                    <p className='mb-0'>Rezume qoldirilgan vaqt:&nbsp; <span className='text-dark fw-medium'>
                                        {format2ISO(new Date(notif.created_time))} &nbsp; {new Date(notif.created_time).toLocaleTimeString('uz', { minute: "2-digit", hour: "2-digit", hour12: false })}
                                    </span></p>
                                </CardBody>
                                <div className="card-footer">
                                    <p className="text-muted mb-0">{timeBetween2Dates(new Date(notif.created_time))}</p>
                                </div>
                            </Card>
                        </Col> : <Col key={index} lg={6} xxl={4}>
                            <Card>
                                <CardHeader>
                                    <button type="button" className="btn-close float-end fs-11" aria-label="Close"
                                        onClick={() => { deleteNotif(notif.id) }}></button>
                                    <h6 className="card-title mb-0">Uchrashuv taklif qilindi</h6>
                                </CardHeader>
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        {notif.logo && <div className="flex-shrink-0">
                                            <img src={"https://online.hrplus.uz/" + notif.logo} alt=""
                                                className="avatar-sm rounded-circle border" />
                                        </div>}
                                        <div className="flex-grow-1 ms-3">
                                            <p className="card-text mb-0"><span className="fw-medium">{notif.company_name}</span></p>
                                            <p className="mb-0 fw-normal">{notif.vacant_name}</p>
                                        </div>
                                    </div>
                                    <p className='mt-3 mb-1'>Sana:&nbsp; <span className='text-dark fw-medium'>{notif.appoint_data.date}</span></p>
                                    <p className='mb-1'>Vaqt:&nbsp; <span className='text-dark fw-medium'>{notif.appoint_data.time}</span></p>
                                    <p className='mb-1 text-nowrap overflow-hidden' style={{ textOverflow: "ellipsis" }}>Joy:&nbsp; <span className='text-dark fw-medium'>{notif.appoint_data.place}</span></p>
                                    {/* <p className='mb-1'>Q'shimcha:&nbsp; <span className='text-dark fw-medium'>{notif.appoint_data.information}</span></p> */}

                                    <p className='mt-2 mb-1'>Siz qoldirgan rezyum:&nbsp; <span className='text-dark fw-medium fs-16'><i>{notif.resume_name}</i></span></p>
                                    <p className='mb-0'>Rezume qoldirilgan vaqt:&nbsp; <span className='text-dark fw-medium'>
                                        {format2ISO(new Date(notif.created_time))} &nbsp; {new Date(notif.created_time).toLocaleTimeString('uz', { minute: "2-digit", hour: "2-digit", hour12: false })}
                                    </span></p>
                                </CardBody>
                                <div className="card-footer">
                                    <Link to={"/appointments?id=" + notif.data} className="link-success float-end"
                                        onClick={() => { dispatch(setNavbarActive(4)); }}>Batafsil <i className="ri-arrow-right-s-line align-middle ms-1 lh-1"></i></Link>
                                    <p className="text-muted mb-0">{timeBetween2Dates(new Date(notif.created_time))}</p>
                                </div>
                            </Card>
                        </Col>)}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UiCards;
