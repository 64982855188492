import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Link} from "react-router-dom";
import {appPaths} from "../constants/appPaths";

const NavigateLoginModal = ({show, toggle, t}) => {
    return (
        <Modal isOpen={show} toggle={toggle} className={"modal-dialog-centered"} modalClassName={"zoomIn"}>
            <ModalBody>
                <h5>
                    {t('autorizate')}
                </h5>
                <h5 className={"fs-16 mb-0"}>
                    <Link className={"text-decoration-underline"} to={appPaths.AUTH_SIGNIN}><i>{t('login/register')}</i></Link>
                </h5>
            </ModalBody>
        </Modal>
    );
}

export default NavigateLoginModal;