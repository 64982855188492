import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

import translationRU from "./locales/ru.json";
import translationEN from "./locales/en.json";
import translationUZ from "./locales/oz.json";
import translationOZ from "./locales/uz.json";


// the translations
const resources = {
    oz: {
        translation: translationOZ,
    },
    ru: {
        translation: translationRU,
    },
    uz: {
        translation: translationUZ,
    },
    en: {
        translation: translationEN,
    }
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
    localStorage.setItem("I18N_LANGUAGE", "oz");
}

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem("I18N_LANGUAGE") || "oz",
        fallbackLng: "oz", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
