import React, {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import {Input, FormFeedback, FormGroup} from 'reactstrap';
import Flag from 'react-world-flags';

// Define country options with specific phone formats
const countryOptions = [
    {value: '+998', label: 'UZ (+998)', flag: 'UZ', mask: '(99) 999-99-99'},
    {value: '+7', label: 'RU (+7)', flag: 'RU', mask: '999 999 99 99'},
    {value: '+993', label: 'TM (+993)', flag: 'TM', mask: '99 999 99'},
    {value: '+992', label: 'TJ (+992)', flag: 'TJ', mask: '99 999 99'},
    {value: '+1', label: 'US (+1)', flag: 'US', mask: '(999) 999-9999'},
    {value: '+44', label: 'UK (+44)', flag: 'GB', mask: '9999 999999'},
    {value: '+49', label: 'DE (+49)', flag: 'DE', mask: '999 9999999'},
    {value: '+86', label: 'CN (+86)', flag: 'CN', mask: '999 9999 9999'},
    {value: '+93', label: 'AF (+93)', flag: 'AF', mask: '99 999 999'}
];

const PhoneNumberField = ({error, value, handler, placeholder = "", label = false, name, handlerCountryCode}) => {
    const [selectedCountry, setSelectedCountry] = useState(countryOptions[0].value);
    const [mask, setMask] = useState(countryOptions[0].mask);

    // Handle country selection change
    const handleCountryChange = (e) => {
        const selectedValue = e.target.value;
        const selectedOption = countryOptions.find(option => option.value === selectedValue);
        setSelectedCountry(selectedValue);
        setMask(selectedOption.mask);
    };

    useEffect(() => {
        handlerCountryCode(selectedCountry);
    }, [selectedCountry]);

    return (
        <div>
            {label && <label className="form-label" htmlFor="phone">
                {label}
            </label>}
            <div className="input-group">
                <span className="input-group-text px-2">
                    <Flag
                        code={countryOptions.find(option => option.value === selectedCountry)?.flag}
                        style={{width: 20, height: 16}}
                    />
                </span>

                <Input
                    type="select"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    className="form-control p-2"
                    style={{maxWidth: "110px"}} // Adjusted width for country select
                >
                    {countryOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Input>

                <InputMask
                    id={"phone_number"}
                    name={name}
                    mask={mask}
                    className={error ? "form-control is-invalid" : "form-control"}
                    placeholder={placeholder}
                    value={value}
                    onChange={handler}
                />

                {error ? (
                    <FormFeedback type="invalid">{error}</FormFeedback>
                ) : null}
            </div>
        </div>
    );
};

export default PhoneNumberField;
