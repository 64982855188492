import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {origin} from "../../config";
import {getCookie} from "../../helpers/myFunctions";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Card, CardBody, Col, Row} from "reactstrap";
import {SkeletonJob} from "../../Components/Skeletons";
import JobCard from "../../Components/JobCard";
import {NoResultsFound} from "../../Components/NoResultsFound";
import {useLocation} from "react-router-dom";
import ModalLeftResume from "../JobList/ModalLeftResume";
import {changeIsLoaded} from "../../store/layouts/action";
import {SeparatedPagination} from "../../Components/Paginations";
import {serverPaths} from "../../constants/appPaths";

const Index = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const location = useLocation();

    const {numberOfJobsPerPage} = useSelector(state => state.Jobs);

    const [vacancies, setVacancies] = useState([]);
    const [isJobsLoaded, setIsJobsLoaded] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [orgId, setOrgId] = useState(null);
    const [vacantId, setVacantId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        dispatch(changeIsLoaded(true));
        fetch(origin.concat(serverPaths.GET.SAVED_JOBS), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                userid: +getCookie("userId")
            }
        })
            .then(resp => resp.json())
            .then(resp => {
                console.log(resp);//
                setVacancies(resp);
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            })
            .finally(() => {
                setIsJobsLoaded(true);
            });
    }, []);


    const handleLeftResume = (event, id, org_id) => {
        event.preventDefault();
        setOrgId(org_id);
        setVacantId(id);

        setIsModalOpen(true);
    }

    const toggleModal = () => {
        setIsModalOpen(state => !state);
    }

    const handleActivePageChange = (num) => {
        setActivePage(num);
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Row className={"g-0"}>
                    {
                        !isJobsLoaded ?
                            <Col xs={12}>
                                {
                                    Array(2).fill(0).map((item, index) =>
                                        <Card className={'mb-3'} key={index}>
                                            <CardBody>
                                                <SkeletonJob/>
                                            </CardBody>
                                        </Card>
                                    )
                                }
                            </Col> :
                            vacancies.length > 0 ?
                                vacancies
                                    .slice((activePage - 1) * numberOfJobsPerPage, activePage * numberOfJobsPerPage)
                                    .map((item, key) => (
                                        <Col lg={12} key={key}>
                                            <JobCard t={t}
                                                     item={item}
                                                     location={location}
                                                     handleLeftResume={handleLeftResume}
                                            />
                                        </Col>
                                    )) :
                                <Col xs={12}>
                                    <NoResultsFound/>
                                </Col>
                    }
                    {
                        isJobsLoaded && vacancies.length > numberOfJobsPerPage &&
                        <SeparatedPagination itemsPerPage={numberOfJobsPerPage}
                                             itemsNumber={vacancies.length}
                                             pageNeighbours={2}
                                             activePage={activePage}
                                             onPageChange={handleActivePageChange}
                        />
                    }
                </Row>
                <ModalLeftResume isModalOpen={isModalOpen} toggleModal={toggleModal} org_id={orgId}
                                 vacant_id={vacantId}/>
            </div>
        </div>
    );
}

export default Index;