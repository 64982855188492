import {
    USER_FULL_NAME,
    NAVBAR_ACTIVE,
    USER, ALL_JOBS_FILTERS, CURRENCIES_RATES, NUMBER_OF_JOBS_PER_FILTER
} from "./actionType";
import {ByStateEnum, CurrenciesEnum, EducationEnum, ForDurationEnum} from "../../Components/constants/jobs";

const INIT_STATE = {
    fullName: "",
    active: window.location.pathname === '/vacancies' ? 1 :
        window.location.pathname === '/vacancies-left-resume' ? 2 :
            window.location.pathname === '/create-resume' ? 3 :
                window.location.pathname === '/appointments' ? 4 : 0,
    user: {},
    allJobsFilters: {
        search: '',
        workTypes: [],
        organizations: [],
        forDuration: ForDurationEnum.FOR_ALL_TIME,
        byState: ByStateEnum.DATE,
        minSalary: 0,
        salaryCurrency: CurrenciesEnum.SOUM,
        regions: [],
        experience: {
            min: '',
            max: ''
        },
        education: EducationEnum.DOEST_MATTER
    },
    currenciesRates: {},
    numOfJobsPerFilter: {
        workTypes: {
            '1': 0,
            '2': 0,
            '3': 0,
        },
        organizations: {},
        experience: {
            'all': 0,
            '0,0': 0,
            '0,1': 0,
            '1,3': 0,
            '3,6': 0,
            '6,': 0,
        },
        regions: {},
        minSalary: {
            "200": 0,
            "500": 0,
            "700": 0,
            "1000": 0,
            "1500": 0,
            "2000": 0
        },
        education: {
            "doesnt-matter": 0,
            "not-required": 0,
            "edu-middle": 0,
            "edu-bachelor": 0,
            "edu-master": 0,
            "edu-phd": 0,
        }
    },
    numberOfJobsPerPage: 10
};

const Jobs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_FULL_NAME: {
            return {
                ...state,
                fullName: action.payload
            };
        }
        case NAVBAR_ACTIVE: {
            return {
                ...state,
                active: action.payload
            };
        }

        case USER:
            return {
                ...state,
                user: action.payload
            }

        case ALL_JOBS_FILTERS:
            return {
                ...state,
                allJobsFilters: {
                    ...state.allJobsFilters,
                    ...action.payload
                }
            }

        case CURRENCIES_RATES:
            return {
                ...state,
                currenciesRates: action.payload
            }

        case  NUMBER_OF_JOBS_PER_FILTER:
            return {
                ...state,
                numOfJobsPerFilter: {
                    ...state.numOfJobsPerFilter,
                    ...action.payload
                }
            }

        default:
            return {...state};
    }
};

export default Jobs;
