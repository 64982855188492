export const mins2Str = (minutes) => {
    return String(Math.floor(minutes / 60)).padStart(2, '0') + ':' + String(minutes % 60).padStart(2, '0');
}
export const format2ISO = (date = new Date()) => {
    return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
}
export const timeBetween2Dates = (date, date2 = new Date()) => {
    let minutes = Math.round(Math.abs(date.getTime() - date2.getTime()) / (60 * 1000));

    let text = '';
    // if (minutes <= 2)
    //     text = 'Just now';
    // else if (minutes < 60)
    //     text = minutes + ' minutes ago';
    // else if (minutes < 2 * 60)
    //     text = 'an hour ago';
    // else if (minutes < 24 * 60)
    //     text = Math.trunc(minutes / 60) + ' hours ago';
    // else if (minutes < 2 * 24 * 60)
    //     text = 'a day ago';
    // else if (minutes < 30 * 24 * 60)
    //     text = Math.trunc(minutes / (24 * 60)) + ' days ago';
    // else if (minutes < 2 * 30 * 24 * 60)
    //     text = 'a month ago';
    // else if (minutes < 365 * 24 * 60)
    //     text = Math.trunc(minutes / (30 * 24 * 60)) + ' months ago';
    // else if (minutes < 2 * 365 * 24 * 60)
    //     text = 'a year ago';
    // else
    //     text = Math.trunc(minutes / (365 * 24 * 60)) + ' years ago';

    if (minutes <= 2)
        text = 'hozirgina';
    else if (minutes < 60)
        text = minutes + ' minut oldin';
    else if (minutes < 24 * 60)
        text = Math.trunc(minutes / 60) + ' soat oldin';
    else if (minutes < 30 * 24 * 60)
        text = Math.trunc(minutes / (24 * 60)) + ' kun oldin';
    else if (minutes < 365 * 24 * 60)
        text = Math.trunc(minutes / (30 * 24 * 60)) + ' oy oldin';
    else
        text = Math.trunc(minutes / (365 * 24 * 60)) + ' yil oldin';
    return text;
}

export function setDataToCookie(name, value, days) {
    document.cookie = `${name}=${value}; path=/; max-age=${days * 24 * 60 * 60}; SameSite=none`;
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

export function deleteCookie(name) {
    document.cookie = name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Lax";
}

export async function readFilesAsBase64(e) {
    const files = e.target.files;
    const filesArr = [];

    if (files.length) {
        for (const file of files) {
            const fileReader = new FileReader();

            const base64 = await new Promise((resolve, reject) => {
                fileReader.onload = function (loadEvent) {
                    resolve(loadEvent.target.result);
                }

                fileReader.onerror = function (err) {
                    reject(err);
                }
                fileReader.readAsDataURL(file);
            });

            filesArr.push({
                base64: base64, name: file.name, size: file.size, type: file.type
            });
        }
    }

    return filesArr;
}

export const copyToClipboard = (text) => {
    return navigator.clipboard.writeText(text);
}

export function fileSizeFormatter(bytes, decimalPoint = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + ' ' + sizes[i];
}