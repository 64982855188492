import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import moment from "moment/moment";
import {_momentLanguages} from "../constants/common";
import "moment/locale/ru";
import "moment/locale/uz";
import "moment/locale/uz-latn";

export const MomentLongMonthTime = ({date = new Date()}) => {
    const {i18n} = useTranslation();

    useEffect(() => {
        moment.locale(_momentLanguages[i18n.language]);

        const handleLanguageChange = lang => {
            moment.locale(_momentLanguages[lang]);
        }

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        }
    }, []);

    return (
        <>
            {moment(date).format('LLL')}
        </>
    );
}