import flagus from "../assets/images/flags/us.svg";
import flaguzb from "../assets/images/flags/uz.svg";
import flagrussia from "../assets/images/flags/russia.svg";


const languages = {
    oz: {
        label: "O'zbekcha",
        flag: flaguzb,
    },
    ru: {
        label: "Русский",
        flag: flagrussia,
    },
    uz: {
        label: "Ўзбекча",
        flag: flaguzb,
    },
    en: {
        label: "English",
        flag: flagus,
    }
}

export default languages
