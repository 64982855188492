import {Col, Row, Spinner} from "reactstrap";
import {AndvancedSelect} from "../../../../Components/Selects";
import {_languages, editResumeSections} from "../../../../constants/common";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {origin} from "../../../../config";
import {serverPaths} from "../../../../constants/appPaths";
import {getCookie} from "../../../../helpers/myFunctions";
import {toast} from "react-toastify";

const inisitializeValues = (languages) => ({
    languages: languages ? JSON.parse(languages).map((language) => ({
        value: language,
        label: _languages[language],
    })) : []
});

const FormLanguages = ({resumeId, languages, toggle, changeResume}) => {
    const {t} = useTranslation();

    const [isSaving, setIsSaving] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: inisitializeValues(languages),
        // validationSchema: Yup.object().shape({
        //     language: Yup.array().min(1, t('field-required'))
        // }),
        onSubmit: (values) => {
            setIsSaving(true);
            fetch(origin.concat(serverPaths.PUT.USER_RESUME(resumeId, editResumeSections.LANGUAGES)), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    userId: +getCookie("userId")
                },
                body: JSON.stringify({
                    ...values,
                    languages: values.languages.map(item => item.value)
                })
            })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp.status) {
                        toast.success(t('updated'));
                        changeResume(resp.data);
                        toggle();

                    } else {
                        toast.error(t('server-error'));
                    }
                })
                .catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                })
                .finally(() => setIsSaving(false));
        }
    });

    const handleReset = () => {
        validation.handleReset();
        inisitializeValues(languages);
    }

    return (
        <form onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Row className={"g-4"}>
                <Col xs={12}>
                    <AndvancedSelect
                        id={"languages"}
                        name='languages'
                        value={validation.values.languages}
                        isMultiSelect={true}
                        handleChange={val => validation.setFieldValue("languages", val)}
                        placeholder={t('select-languages')}
                        options={Object.entries(_languages).map(([k, v]) => ({value: k, label: v}))}
                        isInvalid={Boolean(validation.errors.languages && validation.touched.languages)}
                        error={validation.errors.languages}
                    />
                </Col>
                <Col xs={12}>
                    <button type={"submit"}
                            className={"btn btn-success d-inline-flex align-items-center gap-2 me-2"}
                            disabled={isSaving}>
                        {isSaving && <Spinner type={"grow"} size={"sm"}/>} {t('save')}
                    </button>
                    <button type={"button"}
                            className={"btn btn-ghost-primary"}
                            onClick={handleReset}
                    >
                        {t('update')}
                    </button>
                </Col>
            </Row>
        </form>
    );
}

export default FormLanguages;