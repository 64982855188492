const categories = [
    {
        id: 1,
        icon: "ri-pencil-ruler-2-line fs-1",
        lable: "IT & Software",
        jobs: "1543 Jobs",
    },
    {
        id: 2,
        icon: "ri-airplay-line fs-1",
        lable: "Construction / Facilities",
        jobs: "3241 Jobs",
    },
    {
        id: 3,
        icon: "ri-bank-line fs-1",
        lable: "Government",
        jobs: "876 Jobs",
    },
    {
        id: 4,
        icon: "ri-focus-2-line fs-1",
        lable: "Marketing & Advertising",
        jobs: "465 Jobs",
    },
    {
        id: 5,
        icon: "ri-pencil-ruler-line fs-1",
        lable: "Education & training",
        jobs: "105 Jobs",
    },
    {
        id: 6,
        icon: "ri-line-chart-line fs-1",
        lable: "Digital Marketing",
        jobs: "377 Jobs",
    },
    {
        id: 7,
        icon: "ri-briefcase-2-line fs-1",
        lable: "Catering & Tourism",
        jobs: "85 Jobs",
    },
];
export {  categories };
