import React, {useEffect, useState} from "react";
import {
    Card, CardBody,
    Container, Nav, NavItem, NavLink, TabContent, TabPane,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {changeIsLoaded} from "../../store/layouts/action";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import {AccordionBorder} from "../../Components/Accordions";
import {serverPaths} from "../../constants/appPaths";
import {origin} from "../../config";
import {getCookie} from "../../helpers/myFunctions";
import {toast} from "react-toastify";
import UserProposals from "./UserProposals";

const JobGrid = () => {
    document.title = "Hrplus | Proposals & Offers";

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [activeTab, setActiveTab] = useState("1");

    const [clientOffers, setClientOffers] = useState([]);
    const [userOffers, setUserOffers] = useState([]);
    const [userOffersArchived, setUserOffersArchived] = useState([]);
    const [interviews, setInterviews] = useState([]);
    const [interviewsArchived, setInterviewsArchived] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [appointmentsArchived, setAppointmentsArchived] = useState([]);

    useEffect(() => {
        fetch(origin.concat(serverPaths.GET.PROPOSALS_OFFERS), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                userId: +getCookie("userId")
            }
        })
            .then(resp => resp.json())
            .then(jsonData => {
                //console.log("jsonData", jsonData);

                if (jsonData) {
                    const [activeInter, archiveInter] = jsonData.interviews.reduce((accum, item) => {
                        if (item.is_active) {
                            accum[0].push(item);

                        } else {
                            accum[1].push(item);
                        }
                        return accum;
                    }, [[], []]);

                    const [activeUserOffers, archiveUserOffers] = jsonData.user_offers.reduce((accum, item) => {
                        if (item.is_active) {
                            accum[0].push(item);

                        } else {
                            accum[1].push(item);
                        }
                        return accum;
                    }, [[], []]);

                    const [activeAppoint, archiveAppoint] = jsonData.appointments.reduce((accum, item) => {
                        if (item.is_active) {
                            accum[0].push(item);

                        } else {
                            accum[1].push(item);
                        }
                        return accum;
                    }, [[], []]);

                    setClientOffers(jsonData.client_offers);

                    setInterviews(activeInter);
                    setInterviewsArchived(archiveInter);

                    setUserOffers(activeUserOffers);
                    setUserOffersArchived(archiveUserOffers);

                    setAppointments(activeAppoint);
                    setAppointmentsArchived(archiveAppoint);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            })
            .finally(() => dispatch(changeIsLoaded(true)));
    }, []);

    const toggleCustom = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <div className="page-content">
            <Container fluid={true}>
                <Card className="mb-0">
                    <CardBody>
                        <Nav tabs className="nav nav-tabs nav-tabs-custom nav-primary mb-3">
                            <NavItem className={"px-2"}>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                        toggleCustom("1");
                                    }}
                                >
                                    {t('active')}
                                </NavLink>
                            </NavItem>
                            <NavItem className={"px-2"}>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    {t('archived')}
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" id="active">
                                <AccordionBorder
                                    color={"secondary"}
                                    gap={3}
                                    defaultOpenIndex={3}
                                    items={[
                                        {
                                            header:
                                                <span>{t('offers-from-employers') + ' (' + clientOffers.length + ')'}</span>,
                                            content: ""
                                        },
                                        {
                                            header:
                                                <span>{t('invitations-to-interview') + ' (' + interviews.length + ')'}</span>,
                                            content: ""
                                        },
                                        {
                                            header:
                                                <span>{t('invitations-to-appointment') + ' (' + interviews.length + ')'}</span>,
                                            content: ""
                                        },
                                        {
                                            header:
                                                <span>{t("resume-lefts") + ' (' + userOffers.length + ')'}</span>,
                                            content: <UserProposals items={userOffers}/>
                                        }
                                    ]}/>
                            </TabPane>
                            <TabPane tabId="2" id="archived">
                                <AccordionBorder
                                    color={"secondary"}
                                    gap={3}
                                    defaultOpenIndex={2}
                                    items={[
                                        {
                                            header:
                                                <span>{t('invitations-to-interview-arch') + ' (' + interviews.length + ')'}</span>,
                                            content: ""
                                        },
                                        {
                                            header:
                                                <span>{t('invitations-to-appointment-arch') + ' (' + interviews.length + ')'}</span>,
                                            content: ""
                                        },
                                        {
                                            header:
                                                <span>{t("resume-lefts-arch") + ' (' + userOffers.length + ')'}</span>,
                                            content: <UserProposals items={userOffersArchived} isArchive={true}/>
                                        }
                                    ]}/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default JobGrid;
