import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import Header from "./Header";
import JobDescription from "./JobDescription";
import RelatedJobs from "./RelatedJobs";
import {getCookie} from "../../helpers/myFunctions";
import {origin} from "../../config";
import {serverPaths} from "../../constants/appPaths";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {changeIsLoaded} from "../../store/layouts/action";
import {useDispatch} from "react-redux";
import RightSection from "./RightSection";

const JobOverview = () => {
    document.title = "Hrplus | Vacancy Details";

    const {id} = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [jobData, setJobData] = useState({});
    const [organizationData, setOrganizationData] = useState({});
    const [relatedJobs, setRelatedJobs] = useState([]);

    useEffect(() => {
        dispatch(changeIsLoaded(false));
        fetchAllData();
    }, []);

    const fetchAllData = async () => {
        try {
            const jsonData = await fetch(origin.concat(serverPaths.GET.JOB_DETAILS(id)), {
                headers: {
                    method: "GET",
                    "Content-Type": "application/json",
                    userId: getCookie("userId")
                }
            }).then(resp => resp.json());
            console.log(jsonData)
            setJobData(jsonData.job);
            setRelatedJobs(jsonData.related_jobs);

            const jsonOrganization = await fetch(origin.concat(serverPaths.GET.ORGANIZATION_DATA(jsonData.job.org_id)), {
                headers: {
                    method: "GET",
                    "Content-Type": "application/json",
                    userId: getCookie("userId")
                }
            }).then(resp => resp.json());

            setOrganizationData(jsonOrganization);

        } catch (err) {
            console.log(err);
            toast.error(t('connection-error'));
        }

        dispatch(changeIsLoaded(true));
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Header data={jobData} t={t}/>
                <div style={{marginTop:"-2rem"}}>
                    <Row className={"g-3"}>
                        <Col xl={9}>
                            <JobDescription data={jobData} t={t}/>
                        </Col>

                        <Col xl={3}>
                            <RightSection organization={organizationData} job={jobData} t={t}/>
                        </Col>

                        {
                            relatedJobs.length > 0 &&
                            <Col xs={12}>
                                <RelatedJobs items={relatedJobs} t={t}/>
                            </Col>
                        }
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default JobOverview;
