import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";

import ModalLeftResume from "../JobList/ModalLeftResume";
import JobCard from "../../Components/JobCard";
import {related_jobs_number} from "../../config";
import {appPaths} from "../../constants/appPaths";

const RelatedJobs = ({items, t}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vacantID, setVacantID] = useState(null);
    const [orgID, setOrgID] = useState(null);

    const toggleModal = () => {
        setIsModalOpen(state => !state);
    }

    const handleLeftResume = (event, id, org_id) => {
        event.preventDefault();
        setOrgID(org_id);
        setVacantID(id);

        setIsModalOpen(true);
    }

    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <div className="flex-grow-1">
                    <h5 className="mb-0">{t('related-jobs')}</h5>
                </div>
                {
                    items.length > related_jobs_number &&
                    <div className="flex-shrink-0">
                        <Link to={appPaths.ALL_RELATED_JOBS} className="btn link-secondary">
                            {t('view-all')}{" "}
                            <i className="ri-arrow-right-line ms-1 align-bottom"></i>
                        </Link>
                    </div>
                }
            </div>
            <Row className="g-3">
                {items.map((item, key) => (
                    <Col xl={6} key={key}>
                        <JobCard t={t}
                                 item={item}
                                 handleLeftResume={handleLeftResume}
                        />
                    </Col>
                ))}
            </Row>
            <ModalLeftResume isModalOpen={isModalOpen} toggleModal={toggleModal} org_id={orgID} vacant_id={vacantID}/>
        </>
    );
};

export default RelatedJobs;
