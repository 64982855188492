import Cleave from "cleave.js/react";

export const FieldDateLikeMMYYYY = ({
                                        placeholder,
                                        name,
                                        id,
                                        value,
                                        handleChange,
                                        isInvalid,
                                        error
                                    }) => {
    return (
        <>
            <Cleave
                options={{
                    date: true,
                    delimiter: '/',
                    datePattern: ['m', 'Y'],
                    prefix: '', // Optional: You can add a prefix if needed
                }}
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
            />
            {
                isInvalid &&
                <div className={"invalid-feedback d-block"}>{error}</div>
            }
        </>
    );
}

export const FieldDateLikeDDMMYYYY = ({
                                          placeholder,
                                          name,
                                          id,
                                          value,
                                          handleChange,
                                          isInvalid,
                                          error,
                                          separator = '/'
                                      }) => {
    return (
        <>
            <Cleave
                options={{
                    date: true,
                    delimiter: separator,
                    datePattern: ['d', 'm', 'Y'],
                    prefix: '', // Optional: You can add a prefix if needed
                }}
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
            />
            {
                isInvalid &&
                <div className={"invalid-feedback d-block"}>{error}</div>
            }
        </>
    );
}