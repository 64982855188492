import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {max_resume_photo_size, origin} from "../../../../config";
import {serverPaths} from "../../../../constants/appPaths";
import {getCookie} from "../../../../helpers/myFunctions";
import {toast} from "react-toastify";
import {Progress} from "reactstrap";
import {editResumeSections} from "../../../../constants/common";
import FilePondField from "../../../../Components/FilePondField";

const FormChangePhoto = ({resume, changeResume, toggle}) => {
    const {t} = useTranslation();

    const [files, setFiles] = useState([]);
    const [showButton, setShowButton] = useState(false);
    const [fileError, setFileError] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (files.length > 0) {
            if (fileError.main !== t('file-size-exceeded')) {
                setTimeout(() => {
                    setShowButton(true);
                }, 500);
            }
        } else {
            setShowButton(false);
            setFileError({});
        }
    }, [files]);

    const handleUpload = () => {
        const formData = new FormData();
        files.forEach(fileItem => {
            formData.append('file', fileItem.file);  // Append the file to FormData
        });

        axios.put(origin.concat(serverPaths.PUT.USER_RESUME(resume.id, editResumeSections.PHOTO)), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                userId: +getCookie("userId")
            },
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentage); // Update progress
            }
        })
            .then(response => {
                const {data, status} = response.data;
                if (status) {
                    toast.success(t('updated'));
                    setFiles(state => []);
                    changeResume({...resume, ...data});
                    toggle();

                } else {
                    toast.error(t('server-error'));
                }
            })
            .catch(error => {
                console.error('Error uploading file:', error);
            });
    };


    return (
        <>
            <h6 className={"mb-3"}>{t('max-size-photo', {n: max_resume_photo_size})} </h6>
            <FilePondField
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                fileValidateTypeDetectType={(source, type) =>
                    new Promise((resolve, reject) => {
                        resolve(type);
                    })
                }
                labelFileTypeNotAllowed={t('only-image-accepted')}  // Custom error message
                name="files"                       // Set input name for filepond
                className="filepond filepond-input-multiple"
                labelIdle={t('drop-browse-file')}

                // Set maximum file size to 10MB
                maxFileSize={max_resume_photo_size + "MB"}
                labelMaxFileSizeExceeded={t('file-size-exceeded')} // Error message when file exceeds size
                labelMaxFileSize={t('maximum-file-size', {n: max_resume_photo_size})} // Show allowed file size limit
                onerror={(error, file, status) => {
                    setFileError(error);
                }}
            />

            {
                showButton &&
                <div>
                    <Progress striped={true}
                              value={uploadProgress}
                              style={{
                                  height: "15px"
                              }}>
                        {uploadProgress}%
                    </Progress>
                    <button className={"btn btn-success mt-3"} onClick={handleUpload}>
                        {t('upload')}
                    </button>
                </div>
            }
        </>
    );
}

export default FormChangePhoto;
