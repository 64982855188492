import React from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, CardHeader, Table} from "reactstrap";
import {origin} from "../../config";
import defaultOrganization from "../../assets/images/companies/default-company.png";
import Rating from "react-rating";
import YandexMaps from "../../Components/YandexMaps";

const RightSection = ({organization, job, t}) => {
    console.log(organization);

    return (
        <React.Fragment>
            <Card className="card mb-0">
                <CardBody className="card-body">
                    <div className="avatar-sm mx-auto">
                        <div className="avatar-title bg-soft-warning rounded p-2">
                            <img
                                src={job.is_logo_visible && job.image_path ? origin.concat('/' + job.image_path) : defaultOrganization}
                                alt=""
                                className="w-100 h-100"/>
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to="#!">
                            <h5 className="mt-3">{organization.company_name}</h5>
                        </Link>
                        {/*<p className="text-muted">IT Department</p>*/}
                    </div>

                    <div className="table-responsive">
                        <Table className="table mb-0">
                            <tbody>
                            <tr>
                                <td className="fw-medium">{t('employees-number')}</td>
                                <td>{organization.emps_number}</td>
                            </tr>
                            <tr>
                                <td className="fw-medium">{t('founded-year')}</td>
                                <td>{organization.since}</td>
                            </tr>
                            <tr>
                                <td className="fw-medium">{t('rating')}</td>
                                <td>
                                    <Rating
                                        stop={5}
                                        emptySymbol="mdi mdi-star-outline text-muted fa-1x fs-5"
                                        fullSymbol="mdi mdi-star text-warning fs-5"
                                        initialRating={organization.rating % 5 || 5}
                                        readonly
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-medium">{t('social-media')}</td>
                                <td>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <Link to="#!">
                                                <i className="ri-whatsapp-line"></i>
                                            </Link>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link to="#!">
                                                <i className="ri-facebook-line"></i>
                                            </Link>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link to="#!">
                                                <i className="ri-twitter-line"></i>
                                            </Link>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link to="#!">
                                                <i className="ri-youtube-line"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>

                            {
                                organization.locations?.length &&
                                <tr>
                                    <td colSpan={2}>
                                        <YandexMaps
                                            coords={[organization.locations[0].lat, organization.locations[0].lon]}
                                            zoom={15}
                                            height={170}/>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>

            {/*<Card className="card">*/}
            {/*    <CardHeader className="card-header">*/}
            {/*        <h5 className="mb-0">Contact Us</h5>*/}
            {/*    </CardHeader>*/}
            {/*    <CardBody className="card-body">*/}
            {/*        <form>*/}
            {/*            <div className="mb-3">*/}
            {/*                <label htmlFor="nameInput" className="form-label">*/}
            {/*                    Name*/}
            {/*                </label>*/}
            {/*                <input*/}
            {/*                    type="text"*/}
            {/*                    className="form-control"*/}
            {/*                    id="nameInput"*/}
            {/*                    placeholder="Enter your name"*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div className="mb-3">*/}
            {/*                <label htmlFor="emailInput" className="form-label">*/}
            {/*                    Email*/}
            {/*                </label>*/}
            {/*                <input*/}
            {/*                    type="text"*/}
            {/*                    className="form-control"*/}
            {/*                    id="emailInput"*/}
            {/*                    placeholder="Enter your email"*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div className="mb-3">*/}
            {/*                <label htmlFor="messageInput" className="form-label">*/}
            {/*                    Message*/}
            {/*                </label>*/}
            {/*                <textarea*/}
            {/*                    className="form-control"*/}
            {/*                    id="messageInput"*/}
            {/*                    rows="3"*/}
            {/*                    placeholder="Message"*/}
            {/*                ></textarea>*/}
            {/*            </div>*/}
            {/*            <div className="text-end">*/}
            {/*                <button type="submit" className="btn btn-primary">*/}
            {/*                    Send Message*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </form>*/}
            {/*    </CardBody>*/}
            {/*</Card>*/}
        </React.Fragment>
    );
};

export default RightSection;
