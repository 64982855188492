import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {changeIsLoaded} from "../../../store/layouts/action";
import {appPaths, serverPaths} from "../../../constants/appPaths";
import {getCookie} from "../../../helpers/myFunctions";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {origin} from "../../../config";
import {
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody,
    Col,
    Container,
    Offcanvas, OffcanvasBody,
    OffcanvasHeader,
    Row
} from "reactstrap";

import defaultUser from "../../../assets/images/users/user-dummy-img.jpg";
import moment from "moment";
import WorkHistory from "./WorkHistory";
import Education from "./Education";
import {_workTyimeTypeKeys, editResumeSections} from "../../../constants/common";
import Languages from "./Languages";
import Hobbies from "./Hobbies";
import Additionals from "./Additionals";
import {handleDeleteResume} from "../../../helpers/resume";
import useWindowSize from "../../../hooks/windowSize";
import FormChangePhoto from "./forms/FormChangePhoto";
import FormPersonalInfo from "./forms/FormPersonalInfo";
import FormWorkHistory from "./forms/FormWorkHistory";
import FormEducation from "./forms/FormEducation";
import FormLanguages from "./forms/FormLanguages";
import FormHobbies from "./forms/FormHobbies";
import FormAdditionals from "./forms/FormAdditionals";

const Index = () => {
    const {id} = useParams();

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const windowSize = useWindowSize();

    const [resume, setResume] = useState({});
    const [isPrinting, setIsPrinting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [editingItem, setEditingItem] = useState('');

    const toggleOpen = () => {
        setIsOpen(state => !state);
    }

    useEffect(() => {
        fetch(origin.concat(serverPaths.GET.JOB_USER_RESUMES, '?id=' + id), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "userId": getCookie("userId")
            }
        })
            .then(resp => resp.json())
            .then(resp => resp.pop())
            .then(jsonData => {
                console.log(jsonData)
                setResume(jsonData);
            })
            .catch(err => {
                console.log(err);
                toast.error(t('connection-error'));
            })

        dispatch(changeIsLoaded(true));
    }, []);

    useEffect(() => {
        const handleAfterPrint = () => {
            setIsPrinting(false);
        };

        window.addEventListener('afterprint', handleAfterPrint);
        return () => {
            window.removeEventListener('afterprint', handleAfterPrint);
        };
    }, []);

    const printResume = () => {
        setIsPrinting(true);
        setTimeout(window.print);
    }

    const deleteResume = async () => {
        const status = await handleDeleteResume(id, setIsDeleting);

        if (status !== 'err' && status) {
            navigate(appPaths.ALL_RESUMES);
        }
    }

    const handleEdit = (item) => {
        setEditingItem(item);
        setIsOpen(true);
    }

    const changeResume = (data) => {
        setResume(state => ({
            ...state,
            ...data
        }));
    }

    return (
        <div className={"page-content"}>
            <Container fluid>
                <Breadcrumb className={"no-print-section"} listClassName='p-3 py-1'>
                    <BreadcrumbItem>
                        <Link to={appPaths.ALL_RESUMES}> {t('all-resumes')} </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active> {t('edit-resume')} </BreadcrumbItem>
                </Breadcrumb>
                <Row className={"g-3"}>
                    <Col lg={isPrinting ? 12 : 9}>
                        <Card className={"mb-0"}>
                            <CardBody>
                                <div className={"d-flex flex-wrap justify-content-between"}>
                                    <div className={"vstack gap-1"}>
                                        <h2 className={"mb-1"}>{resume.full_name}</h2>
                                        <h4 className={"mb-3"}>
                                            <span className={"me-3"}>{resume.profession}</span>
                                            <span
                                                className={"badge badge-soft-success rounded-3 fs-15"}>{t(_workTyimeTypeKeys[resume.work_type])}</span>
                                        </h4>

                                        <div>
                                            <span className={"fw-bold me-2"}>{t('citizenship')}:</span>
                                            <span>{resume.citizenship || '-'}</span>
                                        </div>

                                        <div>
                                            <span className={"fw-bold me-2"}>{t('nationality')}:</span>
                                            <span>{resume.nationality || '-'}</span>
                                        </div>

                                        <div>
                                            <span className={"fw-bold me-2"}>{t('gender')}:</span>
                                            <span>{resume.gender ? t(resume.gender) : '-'}</span>
                                        </div>

                                        <div>
                                            <span className={"fw-bold me-2"}>{t('birthday')}:</span>
                                            <span>{resume.birthday || '-'}</span>
                                        </div>

                                        <div>
                                            <span className={"fw-bold me-2"}>{t('marital-status')}:</span>
                                            <span>{t(resume.martial_status)}</span>
                                        </div>

                                        <div>
                                            <span className={"fw-bold me-2"}>{t('phone')}:</span>
                                            <span>{resume.phone ? resume.phone_code + ' ' + resume.phone : '-'}</span>
                                        </div>
                                        <div>
                                            <span className={"fw-bold me-2"}>{t('email')}:</span>
                                            <span>{resume.email || '-'}</span>
                                        </div>

                                        <div>
                                            <span className={"fw-bold me-2"}>{t('address')}:</span>
                                            <span>{resume.address || '-'}</span>
                                        </div>

                                        <div>
                                            <button className={"btn px-0 link-primary"} onClick={e => {
                                                handleEdit(editResumeSections.PERSONAL);
                                            }}>
                                                <i className={"ri-pencil-line align-bottom"}></i> {t('edit')}
                                            </button>
                                        </div>
                                    </div>

                                    <div>
                                        <img
                                            className={"object-cover " + (windowSize.width > 900 ? "avatar-xxl" : "avatar-lg")}
                                            src={resume.image_path ? origin.concat('/', resume.image_path) : defaultUser}
                                            alt={""}
                                        />
                                        <button className={"btn link-primary d-block text-center w-100 px-0"}
                                                onClick={e => handleEdit(editResumeSections.PHOTO)}
                                        >
                                            <i className={"ri-refresh-line align-bottom"}></i> {t('change-photo')}
                                        </button>
                                    </div>
                                </div>

                                <WorkHistory resume={resume}
                                             handleEdit={() => {
                                                 handleEdit(editResumeSections.WORK_HISTORY);
                                             }}/>
                                <Education items={resume.education}
                                           handleEdit={() => {
                                               handleEdit(editResumeSections.EDUCATION);
                                           }}/>
                                <Languages items={resume.languages ? JSON.parse(resume.languages) : []}
                                           handleEdit={() => {
                                               handleEdit(editResumeSections.LANGUAGES);
                                           }}/>
                                <Hobbies items={resume.hobbies ? JSON.parse(resume.hobbies) : []}
                                         handleEdit={() => {
                                             handleEdit(editResumeSections.HOBBIES);
                                         }}/>
                                <Additionals additionalInfo={resume.additional_info}
                                             resumeName={resume.name}
                                             handleEdit={() => {
                                                 handleEdit(editResumeSections.ADDITIONALS);
                                             }}/>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={isPrinting ? 12 : 3}>
                        <Card>
                            <CardBody>
                                <div className={"text-end"}>
                                    <div className={'btn-group'}>
                                        <button className={"btn btn-outline-dark btn-icon"}>
                                            <i className={"ri-download-line fs-15"}></i>
                                        </button>
                                        <button className={"btn btn-outline-dark btn-icon"} onClick={printResume}>
                                            <i className={"ri-printer-line fs-15"}></i>
                                        </button>
                                        <button className={"btn btn-outline-dark btn-icon"} onClick={deleteResume}>
                                            <i className={"las la-trash fs-17"}></i>
                                        </button>
                                    </div>

                                    <p className={"mt-3 mb-1"}>
                                        <span className={"fw-semibold me-1"}>{t('created-time')}:</span>
                                        {moment(resume.created_time).format('DD.MM.YYYY, HH:mm')}
                                    </p>

                                    {
                                        resume.updated_time && <p className={"mb-1"}>
                                            <span className={"fw-semibold me-1"}>{t('last-updated-time')}:</span>
                                            {moment(resume.updated_time).format('DD.MM.YYYY, HH:mm')}
                                        </p>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Offcanvas isOpen={isOpen} toggle={toggleOpen} direction={"end"} size={"lg"} style={{width: "450px"}}>
                    <OffcanvasHeader className={"border-bottom"} toggle={toggleOpen}>
                        {
                            editResumeSections.PHOTO === editingItem ? t('change-photo') :
                                t('edit').concat(' | ').concat(
                                    editResumeSections.PERSONAL === editingItem ? t('personal-info') :
                                        editResumeSections.WORK_HISTORY === editingItem ? t('working-info') :
                                            editResumeSections.EDUCATION === editingItem ? t('education-info') :
                                                editResumeSections.LANGUAGES === editingItem ? t('languages') :
                                                    editResumeSections.HOBBIES === editingItem ? t('hobbies') : t('additioanl-info')
                                )
                        }
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        {editingItem === editResumeSections.PHOTO &&
                            <FormChangePhoto resume={resume} changeResume={setResume} toggle={toggleOpen}/>}

                        {editingItem === editResumeSections.PERSONAL &&
                            <FormPersonalInfo resume={resume} changeResume={setResume} toggle={toggleOpen}/>}

                        {editingItem === editResumeSections.WORK_HISTORY &&
                            <FormWorkHistory resume={resume} changeResume={setResume} toggle={toggleOpen}/>}

                        {editingItem === editResumeSections.EDUCATION &&
                            <FormEducation resume={resume} changeResume={setResume} toggle={toggleOpen}/>}

                        {editingItem === editResumeSections.LANGUAGES &&
                            <FormLanguages resumeId={resume.id}
                                           languages={resume.languages}
                                           changeResume={changeResume}
                                           toggle={toggleOpen}/>}

                        {editingItem === editResumeSections.HOBBIES &&
                            <FormHobbies resumeId={resume.id}
                                         hobbies={resume.hobbies}
                                         changeResume={changeResume}
                                         toggle={toggleOpen}/>}

                        {editingItem === editResumeSections.ADDITIONALS &&
                            <FormAdditionals resumeId={resume.id}
                                             resumeName={resume.name}
                                             additionalInfo={resume.additional_info}
                                             changeResume={changeResume}
                                             toggle={toggleOpen}/>}
                    </OffcanvasBody>
                </Offcanvas>
            </Container>
        </div>
    )
}

export default Index;

